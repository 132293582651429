// @mui material components
import Grid from "@mui/material/Grid";

import PropTypes from "prop-types";
import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect, useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
// Direct React component imports

import { DataManagementService } from "Api-Services/dataManagementService";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

const useStylesSwiper = makeStyles((theme) => ({
  swiperStyle: {
    "& .swiper": {
      // width: "100%",
      paddingTop: "5px !important",
      paddingBottom: "5px",
    },
    "& .swiper-slide": {
      backgroundPosition: "center",
      backgroundSize: "cover",
      // width: "300px",
      // maxHeight: "300px",
    },
    "& .swiper-slide img": {
      display: "block",
      width: "201px",
    },
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  //   baallyJobsPlatformData
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, px: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function HomeAgencyDescription() {
  const navigateComp = useNavigate();

  const [currentData, updateContext] = useCurrentUser();

  const { websitePagesContent, agencyWebsitePagesContent, agencyData, myAgencies } = currentData;

  const [selectedAgencyTabIndex, setSelectedAgencyTabIndex] = React.useState(0);
  const [forceRerender, setForceRerender] = React.useState(false);
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });

  const [aboutUsPage, setAboutUsPage] = React.useState(null);
  const [agencyAboutUsPage, setAgencyAboutUsPage] = React.useState(null);
  
  useEffect(() => {
    // console.log("HomeAgencyDescription ------------------------------------------")
  });

  useEffect(() => {
    // console.log("HomeAgencyDescription")
    // console.log("websitePagesContent",websitePagesContent)
    if (websitePagesContent) {
      if (websitePagesContent.pages) {
        if (websitePagesContent.pages.aboutUsPage) {
          if (websitePagesContent.pages.aboutUsPage.sections) {
            // aboutUsPageD=websitePagesContent.pages.aboutUsPage.sections
            setAboutUsPage(websitePagesContent.pages.aboutUsPage.sections);
          }
        }
      }
    }
    // console.log("websitePagesContent", agencyWebsitePagesContent);
    if (agencyWebsitePagesContent) {
      if (agencyWebsitePagesContent.pages) {
        if (agencyWebsitePagesContent.pages.aboutUsPage) {
          if (agencyWebsitePagesContent.pages.aboutUsPage.sections) {
            // aboutUsPageD=websitePagesContent.pages.aboutUsPage.sections
            setAgencyAboutUsPage(agencyWebsitePagesContent.pages.aboutUsPage.sections);
          }
        }
      }
    }
  }, [websitePagesContent, agencyWebsitePagesContent, agencyData]);

  const truncateString = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          let btn = lableName ? (
            <Button
              variant="text"
              onClick={() => {
                setMoreText({ content: str, title: lableName });
                setOpenedDialog("moreText");
                setForceRerender(!forceRerender);
              }}
            >
              {"View more"}
            </Button>
          ) : null;
          let txt = str.slice(0, num) + "...";
          res = (
            <>
              {parse(txt)} {btn}
            </>
          );
        } else {
          res = <>{parse(res)}</>;
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };

  function AgencyChooseTab() {
    return (
      <>
        {agencyData && (
          <Container sx={{ px: 0, mx: 0 }}>
            <MKBox
            // sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
              <Tabs
                value={selectedAgencyTabIndex}
                onChange={(e, v) => {
                  setSelectedAgencyTabIndex(v);
                }}
                aria-label="basic tabs example"
              >
                <Tab label="Job.et" />
                <Tab label={agencyData.fullName} />
              </Tabs>
            </MKBox>
          </Container>
        )}
      </>
    );
  }

  return (
    <>
      <MKBox component="section">
        <Container>
          <Grid container  >
            <Grid item xs={12} md={12}>
              <AgencyChooseTab />{" "}
            </Grid>
            <Grid item xs={12} md={12}>
            {aboutUsPage && selectedAgencyTabIndex == 0 && (
              <>
                {aboutUsPage.companyDescription && (
                  <DefaultInfoCard
                    title={truncateString(aboutUsPage.companyDescription.title, 120)}
                    description={parse(aboutUsPage.companyDescription.description)}
                  />
                )}
              </>
            )}

            {agencyAboutUsPage && selectedAgencyTabIndex != 0 && (
              <>
                {agencyAboutUsPage.companyDescription && (
                  <DefaultInfoCard
                    title={truncateString(agencyAboutUsPage.companyDescription.title, 120)}
                    description={parse(agencyAboutUsPage.companyDescription.description)}
                  />
                )}
              </>
            )}
          </Grid>
          </Grid>
        </Container>
      </MKBox>

      {/* </Card> */}

      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{parse(moreText.content)}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default HomeAgencyDescription;
