// @mui material components 
import Grid from "@mui/material/Grid"; 

import PropTypes from "prop-types"; 
import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography"; 
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect, useRef } from "react";
import { HttpService } from "Api-Services/httpService";
 
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Avatar,
  Box,
  Button, 
  CardHeader, 
  Container, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, 
  Skeleton, 
  Typography,
} from "@mui/material";
import { Card, TextField } from "@mui/material"; 
import { 
  ArrowForwardIosSharp,  
} from "@mui/icons-material"; 
import { isMobile } from "react-device-detect";
import { red } from "@mui/material/colors";

import { makeStyles } from "@mui/styles";
import { Navigation, Pagination } from "swiper";
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

// import "./swiperStyles.css";

// import 'swiper/css/pagination';
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { DataManagementService } from "Api-Services/dataManagementService";

const useStylesSwiper = makeStyles((theme) => ({
  swiperStyle: {
    "& .swiper": {
      // width: "100%",
      paddingTop: "5px !important",
      paddingBottom: "5px",
    },
    "& .swiper-slide": {
      backgroundPosition: "center",
      backgroundSize: "cover",
      // width: "300px",
      // maxHeight: "300px",
    },
    "& .swiper-slide img": {
      display: "block",
      width: "201px",
    },
  }, 
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0.5px solid ${theme.palette.divider}`,
  borderBottom: "0.5px darkgreen solid",
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, px: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
 
function HomeJoinedAgenciesShortcutView() { 

  const swiperStyles = useStylesSwiper(); 

  const navigateComp = useNavigate(); 

  const [currentData, updateContext] = useCurrentUser();

  const {
    employmentTypes, 
    employmentSalaryDealTypes,
    myJobSeekerInformation,
    currentUser, 
    agencyData,myAgencies
  } = currentData;  
 
  const ref = useRef(null);
  const [forceRerender, setForceRerender] = React.useState(false); 
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [jobOverview, setJobOverview] = React.useState({}); 
  
  
  
  const viewDetails = (id,data) => { 
    DataManagementService.setLocalstorageDataSecured(data, "agencyDetail");
    let path = "/pages/agencies/agency?id="+id //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
    navigateComp(path);
  };
 

  const truncateString = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          let btn = lableName ? (
            <Button
              variant="text"
              onClick={() => {
                setMoreText({ content: str, title: lableName });
                setOpenedDialog("moreText");
                setForceRerender(!forceRerender);
              }}
            >
              {"View more"}
            </Button>
          ) : null;
          let txt = str.slice(0, num) + "...";
          res = (
            <>
              {parse(txt)} {btn}
            </>
          );
        } else {
          res = <>{parse(res)}</>;
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };

   
 
  return (<>
          {(myAgencies ? (myAgencies.length > 0 ? true : false) : false) && (
            <Container>
              <MKBox component="section" p={window.location.href.indexOf("jobs") > 0 ? 3 : 0}>
                <>
                  <> 
                    <Box sx={{ width: "100%" }}> 
                      
                <div ref={ref}>
                    <div style={{ marginBottom: "10px" }}>
                      <Grid
                        // sx={{ marginBottom: "-39px" }}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <MKTypography variant={"h4"}>Shortcut</MKTypography>
                        </Grid>
                        <Grid item>
                          <Button component={Link} variant="text" onClick={()=>{viewDetails()}} to={"/pages/jobs"}>
                            Switch
                          </Button>
                        </Grid>
                      </Grid>

                      <div className={swiperStyles.swiperPaginationNavigationStyle}>
                        <Swiper
                          slidesPerView={isMobile ? 2 : 3}
                          spaceBetween={10}
                          // loop={true}
                          // pagination={{
                          //   clickable: true,
                          // }}
                          navigation={true}
                          modules={[Pagination, Navigation]}
                          className="mySwiper"
                          breakpoints={{
                            640: {
                              slidesPerView: 3,
                              spaceBetween: 20,
                            },
                            768: {
                              slidesPerView: 3,
                              spaceBetween: 25,
                            },
                            1024: {
                              slidesPerView: 3,
                              spaceBetween: 30,
                            },
                          }}
                        >
                          {myAgencies.map((jobVacancy) => {  
                              return (
                                <SwiperSlide style={{ width: "auto" }}>
                                  {/* <Grid item xs={12} md={6} lg={4}> */}

                                  <MKBox display="flex" flexDirection="column">
                                    <Card  
                                            
                                            onClick={()=>{viewDetails(jobVacancy.id,jobVacancy)}}
                                            
                                            >
                                      <CardHeader sx={{p:0,pr:1}}
                                        avatar={
                                          <Avatar sx={{ bgcolor: red[500] }} src={jobVacancy.logo} aria-label="recipe" />
                                        }
                                        // action={
                                        //   <IconButton
                                        //     aria-label="settings"
                                        //     onClick={() => {
                                        //       setOpenedDialog("jobOverview");
                                        //       setJobOverview(jobVacancy);
                                        //     }}
                                        //   >
                                        //     <MoreVert />
                                        //   </IconButton>
                                        // }
                                        title={
                                          <MKTypography variant="body2" color="text">
                                            {truncateString(jobVacancy.fullName, 12)}{"  "} 
                                          </MKTypography>
                                        }
                                        // subheader={
                                        //   <MKTypography color={"text"} variant="subtitle2">
                                        //     {truncateString("jobVacancy.employmentAgencyFullName", 12)}
                                        //   </MKTypography>
                                        // }
                                      />  
                                    </Card>
                                  </MKBox>

                                  {/* </Grid> */}
                                </SwiperSlide>
                              ); 
                          })}
                        </Swiper>
                      </div>
                    </div>
                </div>
                    </Box>

                  </>
                </>
              </MKBox>
              {forceRerender}
            </Container>
          )}


            {/* </Card> */} 

            <Dialog
              open={openedDialog == "moreText"}
              onClose={() => {
                setOpenedDialog("");
              }}
            >
              {moreText && (
                <>
                  {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
                  {moreText.content && (
                    <DialogContent>
                      <DialogContentText>{parse(moreText.content)}</DialogContentText>
                    </DialogContent>
                  )}
                </>
              )}
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenedDialog("");
                  }}
                >
                  close
                </Button>
              </DialogActions>
            </Dialog>
 
    
    
    </>
  );
}

export default HomeJoinedAgenciesShortcutView;
