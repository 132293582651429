/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState ,useEffect} from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
// import { toast } from "react-toastify";

import image from "assets/images/examples/contactUs.jpg";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import { HttpService } from "Api-Services/httpService";
import { CircularProgress } from "@mui/material";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { toast } from "react-toastify";

function Contact() {
  const [forceRerender, setForceRerender] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [invalidResponse, setInvalidResponse] = useState("");
  const [loginResponse, setLoginResponse] = useState(null);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(true);
  const [currentData] = useCurrentUser();
  const {websitePagesContent ,agencyData,isAgentWebsite} = currentData;
  const [contactUsPage, setContactUsPage] = React.useState(null);
  
  useEffect(() => {
    // console.log("websitePagesContent",websitePagesContent) 
    if(websitePagesContent){
      if(websitePagesContent.pages){
        if(websitePagesContent.pages.contactUsPage){
          if(websitePagesContent.pages.contactUsPage.sections){ 
            setContactUsPage(websitePagesContent.pages.contactUsPage.sections)
          }
        }
      }
    }
  }, [websitePagesContent]);

  const login = async () => {
    let valid = false;
    setLoginResponse(null);
    setInvalidResponse("");
    setIsFormValid(true);
    if (fullName && message) {
      setIsFormValid(true);
      valid = true;
    } else {
      setIsFormValid(false);
      valid = false;
      setInvalidResponse(" Please provide your full name and your message");
    }
    // console.log("userEmail&&password",userEmail,password, valid)
    if (valid) {
      setLoading(true);
      let data = {
        // "platformUserName":companyName,
        email: email,
        phone: phone,
        message: message,
        fullName: fullName,
      };
      
      if(agencyData&&isAgentWebsite){
        if(agencyData.id){
        data.employmentAgencyId=agencyData.id
        }
      }
      let relativePath = "/api/employmentcontactmessage/new";
      // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
    
      let toastId = toast.loading("Sending Message...",{
        position: toast.POSITION.BOTTOM_LEFT
      })

        let contactUsRes = await postDocuments("contactus", relativePath, data, {});
        toast.update(toastId, { render: contactUsRes.message, type: contactUsRes.success?"success":"warn", isLoading: false , autoClose: 7000});

        if (contactUsRes.success) {
            setEmail("") 
            setMessage("")  
            setPhone("") 
            setFullName("") 
            setForceRerender(!forceRerender)
        // toast.success(contactUsRes.message, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
      } else {
        // toast.error(contactUsRes.message, {
        //   position: toast.POSITION.TOP_LEFT,
        // });
      }
    }
    setLoading(false);
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    setLoading(false);

    if (documentsResponse.success) {
      responseD.success = true;
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
      let record = responseD.record;

      //write codtions down here//

      if (documentName == "login") {
        localStorage.clear();
        setLoginResponse(documentsResponse);
        setLoading(true);
        // await getDocumentsAll("allOffices","/api/officeUserRole/myoffices",{})//init user offices and data
        setLoading(false);
        let path = "/";
        window.location.assign(path);
      }
    } else {
      responseD.message = documentsResponse.message;
      if (documentName == "login") {
        setLoginResponse(responseD);
      }
    }

    // console.log(documentName, " responseD: ", responseD   );
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  return (
    <MKBox
      component="section"
      display="grid"
      position="relative"
      minHeight="90vh"
      borderRadius="xl"
      mr={{ xs: 0, lg: -2 }}
      mb={{ xs: 0, lg: -2 }}
      sx={{ overflow: { xs: "hidden", lg: "visible" }, placeItems: "center" }}
    >
     {(contactUsPage)&&<>
     {(contactUsPage.card)&& <MKBox
        component="img"
        alt="lake house"
        src={contactUsPage.card.cardImage||image}
        width={{ xs: "100%", lg: "50%" }}
        height={{ xs: "100%", lg: "100%" }}
        position="absolute"
        right={0}
        bottom={{ xs: "-25%", lg: "unset" }}
        top={{ xs: 0, lg: "unset" }}
        sx={{
          objectFit: "cover",
          borderTopLeftRadius: ({ borders: { borderRadius } }) => ({ xs: 0, lg: borderRadius.lg }),
        }}
      />}</>}
      <Container>
        <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ mt: { xs: 0, lg: 12 } }}>
          <Grid item xs={12} lg={7} justifyContent="center" flexDirection="column">
            <MKBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              shadow="lg"
              borderRadius="xl"
              sx={{
                backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                  rgba(white.main, 0.8),
                backdropFilter: "saturate(200%) blur(30px)",
                px: { xs: 3, sm: 6 },
                py: { xs: 3, sm: 8 },
                mb: { xs: 0, lg: 8 },
                mt: { xs: 0, lg: -6 },
              }}
            >
              
            {(contactUsPage)&&<>
            {(contactUsPage.card)&& <> 
            {(contactUsPage.card.title)&& 
              <MKTypography variant="h3" textAlign="center" mb={2}>
                {contactUsPage.card.title}
              </MKTypography>}
              
            {(contactUsPage.card.description)&& 
              <MKTypography variant="text" textAlign="center" mb={2}>
                {contactUsPage.card.description}
              </MKTypography>}
              </>}
        
             {(contactUsPage.formSection)&&  <>
              {(contactUsPage.formSection.note)&& 
              <MKTypography variant="body2" color="text" mb={3}>
               { contactUsPage.formSection.note}
              </MKTypography>}
              </>}

              </>}

              <MKBox component="form">
                <MKBox py={3} px={{ xs: 3, md: 12, lg: 6, xl: 12 }}>
                  <Grid container spacing={3} sx={{ mb: 3 }}>
                    <Grid item xs={12} sx={{ my: 1 }}>
                      <MKInput
                        variant="outlined"
                        placeholder="eg. Michael Belayneh"
                        label="Full Name"
                        value={fullName}
                        onChange={(e) => {
                          setFullName(e.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ my: 1 }}>
                      <MKInput
                        variant="outlined"
                        type="email"
                        placeholder="eg. example@gmail.com"
                        label="Email Address"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ my: 1 }}>
                      <MKInput
                        variant="outlined"
                        type="phone"
                        placeholder="09********"
                        label="Phone"
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        variant="outlined"
                        label="Your Message"
                        placeholder="Describe your message here"
                        rows={4}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    {/* <Grid item xs={12} sx={{ mb: 3, ml: -1 }}>
                      <Switch id="flexSwitchCheckDefault" defaultChecked />
                      <MKTypography
                        component="label"
                        variant="button"
                        color="text"
                        fontWeight="regular"
                        htmlFor="flexSwitchCheckDefault"
                        sx={{ userSelect: "none", cursor: "pointer" }}
                      >
                        I agree to the{" "}
                        <MKTypography component="a" href="#" variant="button" fontWeight="regular">
                          <u>Terms and Conditions</u>
                        </MKTypography>
                        .
                      </MKTypography>
                    </Grid> */}
                    <Grid item xs={12}>
                      {(loginResponse || !isFormValid) && (
                        <MKBox mt={3} mb={1} textAlign="center">
                          {loginResponse && <small>{loginResponse.message}</small>}
                          {!isFormValid && <small>{invalidResponse}</small>}
                        </MKBox>
                      )}
                      {loading && (
                        <MKBox mt={3} mb={1} textAlign="center">
                          <CircularProgress size={24} />{" "}
                        </MKBox>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <MKButton
                        variant="gradient"
                        color="success"
                        fullWidth
                        disabled={!(message!="")||!(fullName!="")||(!((phone!="")||(email!="")))||loading}
                        onClick={() => {
                          login();
                        }}
                      >
                        Send Message
                      </MKButton>
                    </Grid>
                  </Grid>
                </MKBox>
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contact;
