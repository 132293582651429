// @mui material components  
 
import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography"; 
import {  useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect  } from "react";
import { HttpService } from "Api-Services/httpService";
 
import {
  Avatar, 
  Button, 
  CardContent,
  CardHeader,
  Chip, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,  
  Stack, Card, 
  Typography,
} from "@mui/material"; 
import {  
  Favorite, 
  LockClockOutlined, 
  MoreVert, 
  PlaceRounded,
  Receipt, 
  Reply, 
  Visibility,
  WorkOutlineOutlined,
} from "@mui/icons-material"; 
import { red } from "@mui/material/colors";
 
import { DataManagementService } from "Api-Services/dataManagementService";
 
  
function JobShortInfoBox(props) { 
  const navigateComp = useNavigate();

  const [currentData, updateContext] = useCurrentUser();

  const {
    employmentTypes,
    employmentJobLocationTypes,
    employmentSalaryDealTypes,   
  } = currentData;
  
  const [forceRenderer, setForceRenderer] = React.useState(false); 
  const [forceRerender, setForceRerender] = React.useState(false); 
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [jobOverview, setJobOverview] = React.useState({});

  const viewDetails = (id, data) => {
    DataManagementService.setLocalstorageDataSecured(data, "jobDetail");
    let path = "/pages/jobs/job-details?id=" + id; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
    navigateComp(path);
  };

  useEffect(() => {
    // console.log("  jobVacancy short ------------------------------------------")
  });
  
  const incrementField = async (id, fieldName, updateBy) => {
    try {
      if (id && fieldName) {
        let relativePath = "/api/employmentjobvacancy/incrementfieldbyid";
        let data = {
          field: fieldName,
          id: id,
          updateBy: updateBy || 1,
        };
          await postDocuments("contactus", relativePath, data); 
      }
      // setLoading(false);
    } catch (error) {}
  };
  
  const parseDate = (dt) => {
    let d = new Date(dt);
    let m = d.getMonth() + 1;
    let dateD = m + "/" + d.getDate() + "/" + d.getFullYear();
    return dateD;
  };

  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = returnField?"":{};
    // console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = "";
                if (row[returnField]) {
                  rowField = row[returnField];
                }
                res = rowField;
              }
            }
          }
        }
      } 
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false }; 
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
 
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true; 
    } 
    return responseD; 
  };

  const truncateString = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          let btn = lableName ? (
            <Button
              variant="text"
              onClick={() => {
                setMoreText({ content: str, title: lableName });
                setOpenedDialog("moreText");
                setForceRerender(!forceRerender);
              }}
            >
              {"View more"}
            </Button>
          ) : null;
          let txt = str.slice(0, num) + "...";
          res = (
            <>
              {parse(txt)} {btn}
            </>
          );
        } else {
          res = <>{parse(res)}</>;
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };
 

  const jobComponent=(jobVacancy)=>{
     let numberOfLikes = jobVacancy.numberOfLikes || 0;
     let jobOverviewComponent=<></> 
    try {
       jobOverviewComponent=(<>
     
     {jobVacancy&& 
                            <Card 
                            // sx={{ maxWidth: 345 }}
                            >
                              <CardHeader
                                avatar={
                                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                    <WorkOutlineOutlined />
                                  </Avatar>
                                }
                                action={
                                  <IconButton
                                    aria-label="settings"
                                    onClick={() => {
                                      setOpenedDialog("jobOverview");
                                      setJobOverview(jobVacancy);
                                    }}
                                  >
                                    <MoreVert />
                                  </IconButton>
                                }
                                title={
                                  <MKTypography variant="h6">
                                    {truncateString(jobVacancy.title, 65)}
                                  </MKTypography>
                                }
                                subheader={
                                  <MKTypography color={"text"} variant="subtitle2">
                                    {truncateString(jobVacancy.employmentAgencyFullName, 65)}
                                  </MKTypography>
                                }
                              />

                              <CardContent
                                onClick={() => {
                                  viewDetails(jobVacancy.id, jobVacancy);
                                }}
                                // sx={{textAlign: "left",pl:2}}
                              >
                                <Typography variant="body2" color="text.secondary">
                                  <PlaceRounded />{" "}
                                  {jobVacancy.city ? (
                                    <>
                                      {jobVacancy.city + " , " + jobVacancy.subCity}
                                      {" , " + jobVacancy.specificArea}
                                    </>
                                  ) : (
                                    "Not Mentioned"
                                  )}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  <LockClockOutlined /> {parseDate(jobVacancy.deadLineDateTime)}
                                </Typography>
                                <Stack direction="row" m={1} spacing={2}>
                                  <Chip
                                    size="small"
                                    color="error"
                                    variant="outlined"
                                    label={
                                      jobVacancy.salary
                                        ? jobVacancy.salary +
                                          " " +
                                          jobVacancy.salaryCurrency +
                                          " (" +
                                          jobVacancy.salaryDuration +
                                          ")"
                                        : ""
                                    }
                                  />
                                  {(jobVacancy.jobLocationTypeId)?
                                  <Chip
                                    size="small"
                                    label={getDataFromArray(
                                      employmentJobLocationTypes,
                                      "id",
                                      jobVacancy.jobLocationTypeId,
                                      "single",
                                      "title"
                                    )||null}
                                  />:null}
                                  {(jobVacancy.employmentTypeId)?<Chip
                                    size="small"
                                    label={getDataFromArray(
                                      employmentTypes,
                                      "id",
                                      jobVacancy.employmentTypeId,
                                      "single",
                                      "title"
                                    )}
                                  />:null}
                                </Stack>
                                <Divider />

                                <Stack direction="row" m={1} spacing={2}>
                                  {
                                    <Chip
                                      size="small"
                                      icon={<Favorite fontSize="medium" />}
                                      label={numberOfLikes || ""}
                                      onClick={async () => {
                                        numberOfLikes = numberOfLikes + 1;
                                        await incrementField(jobVacancy.id, "numberOfLikes");
                                        // updateContext("myJobApplications")
                                      }}
                                      // onDelete={handleDelete}
                                    />
                                  }
                                  {jobVacancy.numberOfViews > 0 && (
                                    <Chip
                                      size="small"
                                      label={jobVacancy.numberOfViews}
                                      // onClick={handleClick}
                                      // onDelete={handleDelete}
                                      icon={<Visibility fontSize="medium" />}
                                    />
                                  )}
                                  {jobVacancy.numberOfApplications > 0 && (
                                    <Chip
                                      size="small"
                                      label={jobVacancy.numberOfApplications}
                                      // onClick={handleClick}
                                      // onDelete={handleDelete}
                                      icon={<Receipt fontSize="medium" />}
                                    />
                                  )}
                                  {jobVacancy.numberOfShares > 0 && (
                                    <Chip
                                      size="small"
                                      label={jobVacancy.numberOfShares}
                                      // onClick={handleClick}
                                      // onDelete={handleDelete}
                                      icon={<Reply fontSize="medium" />}
                                      // variant="outlined"
                                    />
                                  )}
                                </Stack>
                              </CardContent>
                            </Card> }
     </>) 
    } catch (error) {
    }
      return jobOverviewComponent

  }
 
 
  return (
    <>
          {props.jobVacancy?jobComponent(props.jobVacancy):null}

          {/* </Card> */}

          {forceRenderer}

          <Dialog
            open={openedDialog == "moreText"}
            onClose={() => {
              setOpenedDialog("");
            }}
          >
            {moreText && (
              <>
                {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
                {moreText.content && (
                  <DialogContent>
                    <DialogContentText>{parse(moreText.content)}</DialogContentText>
                  </DialogContent>
                )}
              </>
            )}
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenedDialog("");
                }}
              >
                close
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openedDialog == "jobOverview"}
            onClose={() => {
              setOpenedDialog("");
            }}
          >
            {jobOverview && (
              <>
                {jobOverview.title && <DialogTitle>{parse(jobOverview.title)}</DialogTitle>}
                {jobOverview.id && (
                  <DialogContent>
                    {/* <DialogContentText>{parse(jobOverview.content)}</DialogContentText>
                     */}

                    <MKBox ml={4}>
                      <MKTypography variant="h6" color="text">
                        Overview:
                      </MKTypography>
                      <MKBox ml={1}>
                        <MKTypography variant="subtitle2" fontWeight="light" color="text">
                          Employment type :
                          <strong style={{ fontWeight: "430" }}>
                            {" "}
                            {getDataFromArray(
                              employmentTypes,
                              "id",
                              jobOverview.employmentTypeId,
                              "single",
                              "title"
                            )}
                          </strong>
                        </MKTypography>
                        <MKTypography variant="subtitle2" fontWeight="light" color="text">
                          Salary deal :{" "}
                          <strong style={{ fontWeight: "430" }}>
                            {getDataFromArray(
                              employmentSalaryDealTypes,
                              "id",
                              jobOverview.salaryDealTypeId,
                              "single",
                              "title"
                            )}
                          </strong>
                        </MKTypography>
                        <MKTypography variant="subtitle2" fontWeight="light" color="text">
                          Gender :
                          <strong style={{ fontWeight: "430" }}>
                            {" "}
                            {jobOverview.applicantGender}
                          </strong>
                        </MKTypography>
                        <MKTypography variant="subtitle2" fontWeight="light" color="text">
                          Experiance :{" "}
                          <strong style={{ fontWeight: "430" }}>
                            {(jobOverview.minimumExperianceInYear != null
                              ? "starting from " + jobOverview.minimumExperianceInYear + " "
                              : "") +
                              (jobOverview.maximumExperianceInYear >
                              jobOverview.minimumExperianceInYear
                                ? " to " + jobOverview.maximumExperianceInYear
                                : "") +
                              " year(s) "}
                          </strong>
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    <MKBox ml={4} mt={2}>
                      <MKTypography variant="h6" color="text">
                        Job Description:
                      </MKTypography>
                      <MKBox ml={1}>
                        <MKTypography variant="subtitle2" fontWeight="light" color="text">
                          {parse(jobOverview.description)}
                        </MKTypography>
                      </MKBox>
                      <Button
                        size="small"
                        // variant={Link}
                        // disabled={loading || !professionTitle}
                        // color="success"
                        onClick={() => {
                          viewDetails(jobOverview.id, jobOverview);
                        }}
                      >
                        Read More
                      </Button>
                    </MKBox>
                  </DialogContent>
                )}
              </>
            )}
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenedDialog("");
                }}
              >
                close
              </Button>
            </DialogActions>
          </Dialog>
      {forceRerender}
    </>
  );
}

export default JobShortInfoBox;
