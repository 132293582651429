/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components 
// Material Kit 2 PRO React components 
import queryString from "query-string";
 
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext"; 
import React, { useEffect } from "react";
 
import { HttpService } from "Api-Services/httpService";  
import { Avatar, BottomNavigation, BottomNavigationAction, Box, Paper } from "@mui/material";
import { Add, Archive, Favorite, Feed, Home, NotificationAdd, People, Person, PostAdd, Restore, Work } from "@mui/icons-material";
import MKBox from "components/MKBox";
 
 

function BottomNavBar() {
  const [currentData,updateContext] = useCurrentUser();
  const { currentUser,agencyData, myAgencies,myAgencyJobSeekerInformation,myJobSeekerInformation, jobs } = currentData;
  
  const [selectedNav, setSelectedNav] = React.useState("/home");
  const [expanded, setExpanded] = React.useState(false);
  const [warningExpanded, setWarningExpanded] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [loadingBuildJobSeeker, setLoadingBuildJobSeeker] = React.useState(false);
  const [userInformation, setUserInformation] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [profileSectionIndex, setProfileSectionIndex] = React.useState(0);
  const [forceRerender, setForceRerender] = React.useState(false);
  const [warnings, setWarnings] = React.useState([]);
  const [dropzoneFiles, setDropzoneFiles] = React.useState([]);
  const [openedDialog , setOpenedDialog ] = React.useState("warningList");
  const [moreText, setMoreText] = React.useState({content:"",title:""});
  const [newWorkExperianceData , setNewWorkExperianceData ] = React.useState({
            description :  "",
            expectingSalaryPerHour: null,
            expectingSalaryPerMonth: null, 
            isAvailable: true,
            jobId: null, 
            workExperianceInMonth:null,
            skillsOrSubjects:[],
  });
  // user
  // {id: DataTypes.DOUBLE,
  // userFrontImage: DataTypes.TEXT,
  // userEmail: DataTypes.TEXT,
  // googleEmail: DataTypes.TEXT,
  // facebookId: DataTypes.TEXT,
  // userStatus: DataTypes.DOUBLE,

  // currentEducationLevel:DataTypes.DOUBLE ,
  // informationApproved:DataTypes.BOOLEAN ,
  // informationApprovedDate:DataTypes.TEXT ,
  // TotoringEducationLevelStart:DataTypes.DOUBLE ,
  // TotoringEducationLevelEnd:DataTypes.DOUBLE ,

  // isInstructor :DataTypes.BOOLEAN,
  // status :DataTypes.TEXT,
  // registeredDate :DataTypes.TEXT,
 
  useEffect(() => {
    // console.log("currentUser", currentUser);
    // console.log(" myJobSeekerInformation", myJobSeekerInformation);
  
    if (currentUser.user) {
      setUser(currentUser.user);
      setUserInformation(currentUser.userInformation);
    }
  }, [currentUser]);

  useEffect(() => { 
  }, [currentUser,agencyData, myAgencies,myAgencyJobSeekerInformation,myJobSeekerInformation, jobs]);
    
  useEffect(() => {
    // console.log("myAgencies", myAgencies.length);
    setForceRerender(!forceRerender)
  }, [myAgencies]);

  useEffect(() => {
    // tab=2 
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch.tab) { 
        setProfileSectionIndex(locationSearch.tab);
      }
    }

  }, [location.search]);
  
  const navigate = useNavigate();
  

  const postDocuments = async (documentName, relativePath, data,additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, additionalData||{}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    }
    else{
     responseD.record = documentsResponse.data;  
    }

    // console.log(documentName, " responseD: ", responseD);
    // setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  
  const getDocumentsAll = async (documentName, relativePath, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    ////////// UPDATED/////////////
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }

    if (documentsResponse.success) {
      responseD.success = true;
    }
    setForceRerender(!forceRerender);
    return responseD;
  }; 
  
  const getProfileImage = () => {
    let res=""
    try {
    if( myJobSeekerInformation){
      if(  myJobSeekerInformation.profileImageUrl){
        res= myJobSeekerInformation.profileImageUrl
      }
     } 
    if((res=="")&&currentUser){
         if(currentUser.user){
            if(currentUser.user.userFrontImage ){
              res=currentUser.user.userFrontImage 
            }
          }
      }
      
    } catch (error) {
      
    }
      return res
  } 
   

  return (
    <>
     
      <Box pt={6} px={1} mt={6}> 
     <Paper  
      sx={{ 
         position: 'fixed', bottom: 0,
          left: 0, right: 0 ,
          zIndex:11111
        }}
       elevation={3}>
        <BottomNavigation 
          // showLabels
          value={selectedNav}
          onChange={(event, newValue) => {
            console.log("value",newValue)
            navigate(newValue)
            setSelectedNav(newValue)
          }}
        > 
          <BottomNavigationAction sx={{minWidth:"73px"}} value={"/home"} label="Home" icon={<Home   />}/> 
          <BottomNavigationAction sx={{minWidth:"73px"}}  value={"/pages/search"}  label="Employees" icon={<People/>} />
          <BottomNavigationAction sx={{minWidth:"73px"}}  value={"/pages/jobs"}  label="Jobs" icon={<Work     />} />
          {/* <BottomNavigationAction value={"/Add"}  label="Add" icon={<Add   />} /> */}
          {/* <BottomNavigationAction value={"/Inbox"}  label="Inbox" icon={<NotificationAdd />} /> */}
          <BottomNavigationAction sx={{minWidth:"73px"}}  value={"/pages/blogs"}  label="Blogs" icon={<Feed />} />
          <BottomNavigationAction sx={{minWidth:"73px"}}   value={"/profile"} label="Profile" 
          icon={
            <Avatar 
            sx={{ width: 25, height: 25 }} 
             src={getProfileImage() }/>
          // <Person />
        
        }
           /> 
        </BottomNavigation>
      </Paper>  
      </Box>   

    </>
  );
}

export default BottomNavBar;
