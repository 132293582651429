/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import queryString from "query-string";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isMobile } from 'react-device-detect';

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// Images
import profilePicture from "assets/images/bruce-mars.jpg";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import Card from "@mui/material/Card";
import React, { useEffect } from "react";
import { ArrowDropDownSharp, CalendarViewMonth, Drafts, Edit, Inbox, Paid, Place, School, Sell, Send, Work } from "@mui/icons-material";
import MKInput from "components/MKInput";
import { cities ,subCities,specificAreas} from "Api-master-datas/masterDatas.js";
import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { HttpService } from "Api-Services/httpService";
import { string } from "prop-types";
import { DataManagementService } from "Api-Services/dataManagementService";
import Share from "@mui/icons-material/Share";
import { green } from "@mui/material/colors";
import { CommonServices } from "Api-Services/commonServices";
const ratings = {
  0.5: [
    <Icon key={1}>star_outline</Icon>,
    <Icon key={2}>star_outline</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  1: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star_outline</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  1.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star_half</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  2: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  2.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star_half</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  3: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  3.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star_half</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  4: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  4.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star_half</Icon>,
  ],
  5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star</Icon>,
  ],
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

function JobSeekerDetails(props) {
  const [currentData] = useCurrentUser();
  const { currentUser, educationLevels,agencyData,isAgentWebsite , jobs } = currentData;
  // const { currentUser, educationLevels, myJobSeekerInformation, jobs } = currentData;
  const [expanded, setExpanded] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [myJobSeekerInformation, setJobSeekerInformation] = React.useState(null); 
  // const [employmentAgencyAndJobSeeker, setEmploymentAgencyAndJobSeeker] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [forceRerender, setForceRerender] = React.useState(false);

  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({content:"",title:""});
  const [sendRequestData, setSendRequestData] = React.useState({
    jobSeekerId:"",
    employmentAgencyId:"",
    requesterFullName:"",
    requesterEmail:"",
    requesterPhone:"", 
    requesterUserId:"",
    
    requesterWorkingCountry: "", 
    requesterWorkingCity: "", 
    requesterWorkingSubCity :"", 
    requesterWorkingSpecificArea: "", 
  });
  // user
  // {id: DataTypes.DOUBLE,
  // userFrontImage: DataTypes.TEXT,
  // userEmail: DataTypes.TEXT,
  // googleEmail: DataTypes.TEXT,
  // facebookId: DataTypes.TEXT,
  // userStatus: DataTypes.DOUBLE,

  // currentEducationLevel:DataTypes.DOUBLE ,
  // informationApproved:DataTypes.BOOLEAN ,
  // informationApprovedDate:DataTypes.TEXT ,
  // TotoringEducationLevelStart:DataTypes.DOUBLE ,
  // TotoringEducationLevelEnd:DataTypes.DOUBLE ,

  // isInstructor :DataTypes.BOOLEAN,
  // status :DataTypes.TEXT,
  // registeredDate :DataTypes.TEXT,

  useEffect(() => {
    // console.log(" location.search", location);

    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch.id) {
        let data={jobSeekerId:locationSearch.id,
                 employmentAgencyId:locationSearch.employmentAgencyId} 
                 
        let jobSeekerDetail= DataManagementService.getLocalstorageDataSecured("jobSeekerDetail");
        if(jobSeekerDetail){
          if(jobSeekerDetail.id){
            if(jobSeekerDetail.id==locationSearch.id){
              setJobSeekerInformation(jobSeekerDetail)
            }
          }
        }
        search(data);

      }
    }
    // search()
    if (currentUser.user) {
      setUser(currentUser.user);
    }
  }, [currentUser, location.pathname, location.search]);

  const search = async (dataa) => {
    // console.log(" location.search", dataa);

    try {
      if (dataa) {
        // let relativePath = "/api/employmentagencyjobseeker/getemployeebyid/" + id;
        let relativePath = "/api/employmentagencyjobseeker/getfulldatabyfield"
        let relativePathEmploymentAgency = "/api/employmentagency/getfulldatabyfield"
        let relativePathEmploymentJobseeker = "/api/employmentjobseeker/getfulldatabyfield"
        // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
        let jobSeekerInformationRes = await postDocuments("contactus", relativePathEmploymentJobseeker, {id:dataa.jobSeekerId});
        if (jobSeekerInformationRes.success) {
          if (jobSeekerInformationRes.record) {
            if (jobSeekerInformationRes.record.length) { 
                let agencyAndJobSeeker=jobSeekerInformationRes.record[0] 
                // setEmploymentAgencyAndJobSeeker(agencyAndJobSeeker); 
                if (agencyAndJobSeeker) {
                setJobSeekerInformation(agencyAndJobSeeker);
                } 
                // if (agencyAndJobSeeker.employmentAgency) {
                // setEmploymentAgency(agencyAndJobSeeker.employmentAgency);
                // } 
            }
          }
        }
      }
      // setLoading(false);
    } catch (error) {}
  };

  const getDocumentsAll = async (documentName, relativePath, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    ////////// UPDATED/////////////
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;
    }
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const getCityById = (city, rows, field) => {
    let res = field ? "" : {};
    try {
      if (cities && city) {
        let filteredLevels = cities.filter((level) => level.TOWN_NAME == city);
        if (filteredLevels.length) {
          res = filteredLevels;
          if (rows == "single") {
            let row = filteredLevels[0];
            res = row;
            if (field) {
              let rowField = row[field];
              res = rowField;
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };

  
  const getEducationLevelById = (id, rows, field) => {
    let res = field?"":{};
    if (educationLevels && id) {
      let filteredLevels = educationLevels.filter((level) => level.id == id);
      if (filteredLevels.length) {
        res = filteredLevels;
        if (rows == "single") {
          let row = filteredLevels[0];
          res = row;
          if (field) {
            let rowField = row[field];
            res = rowField;
          }
        }
      }
      // console.log("filtered educat error", res);
    }
    return res;
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    }

    // console.log(documentName, " responseD: ", responseD);
    // setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeExpanded2 = (panel) => (event, isExpanded) => {
    setExpanded2(isExpanded ? panel : false);
    if(!isExpanded){
      setExpanded(false);
    }
  };

  const navigate = useNavigate();
  const goToPage = () => {
    navigate("/pages/SendTutorRequest");
  };

  const truncateString=(str, num, lableName) =>{
    let res=str
    try {
      if (str) {
        if (str.length > num) { 
        let btn= <Button variant="text" onClick={()=>{setMoreText({content:str,title:lableName}); setOpenedDialog("moreText"); setForceRerender(!forceRerender) }} >{"View more"}</Button>
        let txt=str.slice(0, num) + "..."
        res= <>{txt} {btn}</>;
        }  
    } 
  }catch (error) {
      // console.log("errrr",error)
    }
    return res
  }
  
  const truncateStringDefualt=(str, num, lableName,) =>{
    let res=""
    try {
      if (str) {
        if (str.length > num) {  
        let txt=str.slice(0, num) + "..."
           res= txt 
        }  
        else{ 
           res= str 
        }
    } 
  }catch (error) {
      // console.log("errrr",error)
    }
    return res
  }  
  
  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res =returnField?"": {}; 

    try {
      if (searchValue) {
      if (dataArray&&searchField&&searchValue) {
        if (dataArray.length>0) {
        let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = row[returnField];
                res = rowField;
              }
            }
           }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
      // console.log("filtered city  ", res);
    return res;
  };


  const filterJobsFromString = (jobsString, field) => {
    let res = [];
    if (jobs && jobsString) {
      let jobsArray = jobsString.split(",");
      if (jobsArray) {
        let filteredLevels = jobs.filter((job) => {
          let ress = false;
          jobsArray.forEach((element) => {
            let id = parseInt(element);
            if (job.id == id) {
              ress = true;
            }
          });

          return ress;
        });
        res = filteredLevels;
      }
      // console.log("filtered jobs", res);
    }
    return res;
  };

  const handleSendRequestData=(field,value)=>{
    let data=sendRequestData
    if(field&&value){
      data[field]=value
    }
    setSendRequestData(data)
    setForceRerender(!forceRerender)
  } 

  const sendRequest= async()=>{
    let data=sendRequestData
    
    try { 
      let relativePath ="/api/employeehiringrequest/new";
 
      if(myJobSeekerInformation){
        if(myJobSeekerInformation.id){
        data.jobSeekerId=myJobSeekerInformation.id
        }
      }
      if(agencyData){
        if(agencyData.id){
        data.employmentAgencyId=agencyData.id
        }
      }
 
        // console.log("data", data);

      if ((relativePath) && (data.jobSeekerId)) { 

        let toastId = toast.loading("Sending Request...",{
          position: toast.POSITION.BOTTOM_LEFT
        })

        let changeUserProfileRes = await postDocuments("changeUserInfo", relativePath, data);
         toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});

        if (changeUserProfileRes.success) {
          setOpenedDialog("")
          // updateContext("currentUser")
        }
      }
    } catch (error) {}
  };

  const updateUserInformation = async (dataD, dataParentName) => {
    // console.log("data", dataD);

    try {
      let id;
      let relativePath;
      if (dataParentName == "agencyjobseeker") {
        id = myJobSeekerInformation.id;
        relativePath = "/api/employmentagencyjobseeker/edit";
      } else if (dataParentName == "jobseeker") {
        id = myJobSeekerInformation.id;
        relativePath = "/api/employmentjobseeker/edit";
      }

      if (relativePath && id) {
        let data = {
          id: id,
        };
        data[dataD.fieldName] = dataD.value;
        // console.log("data", data);

        // let toastId = toast.loading("Updating Profile data...",{
        //   position: toast.POSITION.BOTTOM_LEFT
        // })

        let changeUserProfileRes = await postDocuments("changeUserInfo", relativePath, data);
        //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});

        if (changeUserProfileRes.success) {
          // setExpanded2(false)
          // updateContext("currentUser")
        }
      }
    } catch (error) {}
  };

  // const updateEmailInformation= async(action,dataD)=>{
  //   try {
  //    let data= null
  //    let relativePath=""
  //    if(action=="changeemail"){
  //        relativePath="/api/auth/changemyemail"
  //        data={"email":dataD.value}
  //    }
  //    else if(action=="verifyemail"){
  //        relativePath="/api/auth/verifymyemail"
  //       //  data={"pendingEmail":dataD.value}
  //        data={"emailVerificationCode":dataD.value}
  //    }
  //    else if(action=="dismisspendingemail"){
  //        relativePath="/api/auth/dismissmypendingemail"
  //        data={"email":dataD.value}
  //    }

  //   // console.log("data", data);
  //    if(data&&relativePath) {
  //         let toastId = toast.loading("taking action on email...",{
  //           position: toast.POSITION.BOTTOM_LEFT
  //         })

  //         let changeUserProfileRes= await postDocuments("emailaction",relativePath,data)
  //         toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});

  //         if(changeUserProfileRes.success){
  //           updateContext("currentUser")
  //           if(action=="changeemail"){
  //             setExpanded2("pendingEmail")
  //           }
  //         }
  //           }
  //   } catch (error) {

  //   }

  // }

  const editInputComponent = (
    parentFieldName,
    fieldName,
    fieldLabel,
    valueD,
    fieldType,
    options,
    optionsSelectedField,
    optionsSelectedLable
  ) => {
    // const editInputComponent = (fieldName, fieldLabel, valueD, fieldType, options, selectedField) => {
    // console.log("fieldName", valueD);
    let res = <></>;
    let inputComp = <></>;
    let value = valueD;
    try { 
      let accordionTitle = "-";
      if (fieldType == "fileUrl") {
        accordionTitle = (
          <Button
            href={value}
            target="_blank"
            onClick={() => {
              // setDocumentUrl(value);
              // setOpenedDialog("documentviewer")
            }}
            color="primary"
            round
            size="sm"
          >
            open file
          </Button>
        );
      } else if ((fieldType == "chip")||(fieldType == "chipText")) {
        
        let chipsData=[]//value
        if(value){
          if(typeof(value)=="string"){
            chipsData=value.split(",")
          }
          else if(value.length){
            chipsData=value 
          }

        }
        accordionTitle = (
          <>
          <>
            {chipsData.map((chipValue) => (
              <Chip
                label={optionsSelectedLable ? chipValue[optionsSelectedLable] : chipValue}
                variant="outlined"
              />
            ))}</>
          </>
        );
      } else {
        if (value) {
          accordionTitle = optionsSelectedLable
            ? value[optionsSelectedLable]
              ? value[optionsSelectedLable]
              : ""
            : value;
        }
      }

      if(typeof(accordionTitle)=="string"){
       accordionTitle= truncateString(accordionTitle,50)  
      }
      res = (
        <>
          {/* <Accordion expanded={expanded === fieldName} onChange={handleChange(fieldName)}> */}
          <Accordion expanded={false}>
            <AccordionSummary
              // expandIcon={<Edit />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <MKTypography variant="body2" sx={{ width: "43%", flexShrink: 0 }}>{fieldLabel} {isMobile?" : ":""}</MKTypography>
              <MKTypography variant="subtitle2" sx={{ color: "text.secondary" }}> <strong> {accordionTitle}</strong></MKTypography>
            </AccordionSummary> 
          </Accordion>
        </>
      );
    } catch (error) {
      // console.log("inp comp", error);
    }
    return res;
  };

  return (<>
    <MKBox component="section" >
      {myJobSeekerInformation && (
        <Container>
          <Grid container item xs={12} justifyContent="center" mx="auto">
           

            
{ myJobSeekerInformation && (
                  <Grid container item xs={12} py={3}  justifyContent="center">
                    <Card sx={{ width: "100%" }}>
                      <Grid container alignItems="center">
                        <Grid px={2} item xs={12} lg={12}>
                          <MKBox p={1}>
                            <Grid
                              container 
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="flex-end"
                            >
                              {/* <MKTypography variant="h6">{"Profile"}</MKTypography> */}
                              
                            <Avatar  sx={{ width: 30, height: 30, ml:2, bgcolor: green[500] }}>
                             <Share  /></Avatar>
                             
                            <Avatar  sx={{ width: 30, height: 30, ml:2, bgcolor: green[500] }}>
                             <Send  onClick={()=>setOpenedDialog("sendRequest")} /></Avatar>
                            </Grid>
                          </MKBox>
                          <MKBox pb={{ sm: 0, md: 1 }} px={{ sm: 2, md: 3 }}>
                            <List>
                              {/* <Divider/> */}
                              {myJobSeekerInformation && (
                                <>
                                  <ListItem>
                                    <ListItemAvatar sx={{ marginRight: "20px" }}>
                                      {/* <Avatar>
            <ImageIcon />
          </Avatar> */}
                                      {myJobSeekerInformation.isAvailable ? (
                                        <StyledBadge
                                          // sx={{  marginLeft: "-10px" }}
                                          color="primary"
                                          // overlap="circular"
                                          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                          variant="dot"
                                        >
                                          <Avatar
                                            sx={{
                                              width: 90,
                                              height: 90,
                                              marginBottom: "-10px",
                                              marginRight: "-10px",
                                            }}
                                            variant="circular"
                                            src={
                                              myJobSeekerInformation
                                                ? myJobSeekerInformation.profileImageUrl
                                                : profilePicture
                                            }
                                            // size="xl"
                                            // shadow="xl"
                                          />
                                        </StyledBadge>
                                      ) : (
                                        <MKAvatar
                                          sx={{ width: 90, height: 90 }}
                                          variant="circular"
                                          src={
                                            myJobSeekerInformation
                                              ? myJobSeekerInformation.profileImageUrl
                                              : profilePicture
                                          }
                                          size="xxl"
                                          shadow="xl"
                                        />
                                      )}
                                      {/* <Avatar
                                        alt="Travis Howard"
                                        sx={{ width: 90, height: 90, marginRight: "20px" }}
                                        src={
                                          myJobSeekerInformation
                                            ? myJobSeekerInformation.profileImageUrl
                                            : profilePicture
                                        }
                                      /> */}
                                    </ListItemAvatar>
                                    <ListItemText
                                      secondary={
                                        <>
                                          <MKTypography variant="body2">
                                            <Place />{" "}
                                            {myJobSeekerInformation.country +
                                              " , " +
                                              getCityById(
                                                myJobSeekerInformation.city,
                                                "single",
                                                "TOWN_NAME"
                                              )}
                                          </MKTypography>
                                          {myJobSeekerInformation.expectingSalaryPerHourConvertedInUSD?<>
                                            <MKTypography variant="subtitle2" color={"text"}>
                                              <strong> <Paid />{" "}
                                              { "USD " +
                                                myJobSeekerInformation.expectingSalaryPerHourConvertedInUSD +
                                                " / hr"}</strong>
                                            </MKTypography></>:
                                          (myJobSeekerInformation.expectingSalaryCurrency
                                            ? true
                                            : false) && (
                                            <MKTypography variant="subtitle2">
                                              <Sell />{" "}<strong>
                                              {myJobSeekerInformation.expectingSalaryCurrency +
                                                " " +
                                                myJobSeekerInformation.expectingSalaryPerHour +
                                                " / hr"}</strong>
                                            </MKTypography>
                                          )}{" "}
                                        </>
                                      }
                                      primary={
                                        myJobSeekerInformation.firstName && (
                                          <MKTypography variant="h4">
                                            {myJobSeekerInformation.firstName
                                              ? myJobSeekerInformation.firstName +
                                                " " +
                                                myJobSeekerInformation.lastName
                                              : "user"}
                                          </MKTypography>
                                        )
                                      }
                                    />
                                  </ListItem>
                                  <Divider />
                                  <ListItem> 
                                    <ListItemText 
                                    secondary={<strong> {myJobSeekerInformation.isAvailable?"Available":"Not Available"}</strong>}
                                      primary={<>          
                                        <MKTypography
                                        variant="h4"
                                        // color={color === "transparent" || color === "light" ? "text" : "white"}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          ml: 0.375,

                                          "& .material-icons-round": {
                                            ml: -0.375,
                                          },
                                        }}
                                      >
                                        {ratings[5]}
                                      </MKTypography>


                                        {/* <MKTypography variant="h5" style={{color:"darkgreen"}}>
                                        {myJobSeekerInformation.workExperianceInMonth||"Not mentioned"}
                                        <MKBox component="small" style={{color:"darkgreen"}}>{" Month(s)"}</MKBox>
                                        </MKTypography>  */}
                                        </>   



                                      }
                                    />
                                  </ListItem>
                                  <Divider />
                                  <ListItem
                                  // secondaryAction={
                                  //   <MKButton
                                  //     size={"small"}
                                  //     onClick={() => {
                                  //       setProfileSectionIndex(4);
                                  //     }}
                                  //     variant={"outlined"}
                                  //     color="warning"
                                  //   >
                                  //     Edit
                                  //   </MKButton>
                                  // }
                                  >
                                    <ListItemText
                                      secondary={
                                        <MKTypography variant="body2">
                                          {myJobSeekerInformation.title}
                                        </MKTypography>
                                      }
                                      primary={"Title"}
                                    />
                                  </ListItem>
                                  <Divider />
                                  {/* <ListItem 
                                  >
                                    <ListItemText
                                      secondary={
                                        myJobSeekerInformation.currentEducationLevelEducatedField && (
                                          <MKTypography variant="h6" mb={1}>
                                            {myJobSeekerInformation.currentEducationLevelEducatedField +
                                              " , " +
                                              myJobSeekerInformation.currentEducationLevelEducatedSchool}
                                          </MKTypography>
                                        )
                                      }
                                      primary={"Latest Education"}
                                    />
                                  </ListItem>
                                  <Divider /> */}
                                  <ListItem
                                  //   secondaryAction={ <MKButton size={"small"}  onClick={()=>{
                                  //     setProfileSectionIndex(4)
                                  //   }}
                                  //   variant={"outlined"}
                                  //    color="warning"
                                  //    >
                                  //    Edit
                                  // </MKButton>
                                  //   }
                                  >
                                    <ListItemText
                                      secondary={
                                        <MKTypography
                                          variant="subtitle2"
                                          fontWeight="light"
                                          color="text"
                                        >
                                          {truncateString(
                                            myJobSeekerInformation.description,
                                            isMobile ? 100 : 300,
                                            "Description"
                                          )}
                                        </MKTypography>
                                      }
                                      primary={"Description"}
                                    />
                                    {/* <ListItemIcon>
                                      <Folder />
                                    </ListItemIcon> */}
                                  </ListItem>{" "}
                                </>
                              )}
                            </List>
                          </MKBox>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                )}
 
            
            {myJobSeekerInformation &&<Grid container item xs={12} py={3} justifyContent="center">
              <Card sx={{ width: "100%" }}> 
                <Grid container alignItems="center">
                  <Grid item xs={12} lg={12}>
                    

                  <MKBox p={2}>
                            <List>
                              <ListItem 
                              >
                                <ListItemText
                                  secondary={
                                    <>
                                      {myJobSeekerInformation.workExperiances
                                        ? myJobSeekerInformation.workExperiances.length
                                          ? myJobSeekerInformation.workExperiances.length +
                                            " profession(s)"
                                          : "No Professions added"
                                        : "No Professions added"}
                                    </>
                                  }
                                  primary={
                                    <MKTypography variant="h5">
                                      {"Professions / Talents"}
                                    </MKTypography>
                                  }
                                />
                              </ListItem>
                              {myJobSeekerInformation && (
                                <>
                                  <Divider />
                                  <ListItem
                                  // secondaryAction={
                                  //   <MKButton
                                  //     size={"small"}
                                  //     onClick={() => {
                                  //       setProfileSectionIndex(1);
                                  //     }}
                                  //     variant={"outlined"}
                                  //     color="warning"
                                  //   >
                                  //     {myJobSeekerInformation.workExperiances
                                  //       ? myJobSeekerInformation.workExperiances.length
                                  //         ? "Manage"
                                  //         : "Add"
                                  //       : "Add"}
                                  //   </MKButton>
                                  // }
                                  >
                                    <ListItemText
                                      primary={
                                        <>
                                          {(myJobSeekerInformation.workExperiances
                                            ? myJobSeekerInformation.workExperiances.length
                                              ? true
                                              : false
                                            : false) && (
                                            <>
                                              {myJobSeekerInformation.workExperiances.map(
                                                (experiance, index) => (
                                                  <Chip
                                                    sx={{ margin: "3px" }}
                                                    // variant="outlined"
                                                    label={getDataFromArray(
                                                      jobs,
                                                      "id",
                                                      experiance.jobId,
                                                      "single",
                                                      "title"
                                                    )}
                                                    // {...getTagProps({ index })}
                                                  />
                                                )
                                              )}
                                            </>
                                          )}
                                        </>
                                      }
                                    />
                                  </ListItem>
                                </>
                              )}
                            </List>
                          </MKBox>

 
                  </Grid>
                </Grid>  
              </Card>
            </Grid>}

            
            {myJobSeekerInformation && (
                  <Grid container item xs={12} justifyContent="center">
                    <Card sx={{ width: "100%" }}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} lg={12}>
                          <MKBox p={2}>
                            <List>
                              <ListItem 
                              >
                                <ListItemText
                                  secondary={
                                    myJobSeekerInformation.employmentContracts
                                      ? myJobSeekerInformation.employmentContracts.length
                                        ? myJobSeekerInformation.employmentContracts.length +
                                          " Contract(s)"
                                        : "No Contracts added"
                                      : "No Contracts added"
                                  }
                                  primary={
                                    <MKTypography variant="h5">{"Work History"}</MKTypography>
                                  }
                                />
                              </ListItem>
                              {(myJobSeekerInformation.employmentContracts
                                ? myJobSeekerInformation.employmentContracts.length > 0
                                  ? true
                                  : false
                                : false) && (
                                <>
                                  {myJobSeekerInformation.employmentContracts.map(
                                    (employmentContract) => (
                                      <>
                                        <Divider />
                                        <ListItem
                                        // secondaryAction={
                                        //   <MKButton
                                        //     size={"small"}
                                        //     onClick={() => {
                                        //       setProfileSectionIndex(1);
                                        //     }}
                                        //     variant={"outlined"}
                                        //     color="warning"
                                        //   >
                                        //     {myJobSeekerInformation.workExperiances
                                        //       ? myJobSeekerInformation.workExperiances.length
                                        //         ? "Manage"
                                        //         : "Add"
                                        //       : "Add"}
                                        //   </MKButton>
                                        // }
                                        >
                                          {" "}
                                          <ListItemIcon>
                                            <Work />
                                          </ListItemIcon>
                                          <ListItemText
                                            secondary={
                                              <>
                                                <MKTypography variant="body2">
                                                  <CalendarViewMonth />{" "}
                                                  {CommonServices.parseDate(employmentContract.createdAt) + " - " }
                                                  {employmentContract.isEnded?CommonServices.parseDate(employmentContract.endedDateTime):"present " }
                                                </MKTypography></>}
                                            primary={
                                              <MKTypography variant="h6" color="text">
                                                {employmentContract.jobTitle}
                                              </MKTypography>
                                            }
                                          />
                                        </ListItem>
                                      </>
                                    )
                                  )}
                                </>
                              )}
                            </List>
                          </MKBox>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                )}


{user && myJobSeekerInformation && (
                  <Grid container item xs={12} pt={3} justifyContent="center">
                    <Card sx={{ width: "100%" }}>
                      <Grid container alignItems="center">
                        <Grid p={1} item xs={12} lg={12}>
                          <MKBox py={1} px={2}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="flex-start"
                            >
                              <MKTypography variant="h5">{"Portfolio"}</MKTypography>
                            </Grid>
                          </MKBox>
                          <MKBox pb={{ sm: 0, md: 1 }} px={{ sm: 2, md: 3 }}>
                            <List>
                              {/* <Divider/> */}
                              {myJobSeekerInformation && (
                                <ListItem
                                // secondary
                                >
                                  <ListItemText
                                    secondary={"No item"}
                                    // primary  ={"My Professioins"  }
                                  />
                                </ListItem>
                              )}
                            </List>
                          </MKBox>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                )}

{ myJobSeekerInformation && (
                  <Grid container item xs={12} pt={3} justifyContent="center">
                    <Card sx={{ width: "100%" }}>
                      <Grid container alignItems="center">
                        <Grid p={1} item xs={12} lg={12}>
                          <MKBox py={1} px={2}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="flex-start"
                            >
                              <MKTypography variant="h5" mb={1}>
                                {"Educational Information"}
                              </MKTypography> 
                            </Grid>
                          </MKBox>
                          <MKBox pb={{ sm: 0, md: 1 }} px={{ sm: 2, md: 3 }}>
                            <List>
                              {/* <Divider/> */}
                              {myJobSeekerInformation && (
                                <>
                                  <ListItem 
                                  >
                                    <ListItemIcon>
                                      <School />
                                    </ListItemIcon>
                                    <ListItemText
                                      secondary={
                                        myJobSeekerInformation.currentEducationLevelEducatedSchool+", "+myJobSeekerInformation.currentEducationLevelEducatedCountry
                                      }
                                      primary={
                                        <MKTypography variant="subtitle2">
                                          {getEducationLevelById(
                                            myJobSeekerInformation.currentEducationLevelId,
                                            "single",
                                            "title"
                                          ) +
                                            " , " +
                                            myJobSeekerInformation.currentEducationLevelEducatedField}
                                        </MKTypography>
                                      }
                                    />
                                  </ListItem>{" "}
                                </>
                              )}
                            </List>
                          </MKBox>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                )}


            {myJobSeekerInformation && (
              <Grid container item xs={12} pt={3} justifyContent="center">
                <Card sx={{ width: "100%" }}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={12}>
                      <MKBox pt={3} px={4}>
                        <MKTypography variant="h5" mb={1}>
                          Personal Informations
                        </MKTypography>

                      </MKBox>

                      <MKBox >
                        {editInputComponent(
                          "jobseeker",
                          "firstName",
                          "Full Name",
                          myJobSeekerInformation.firstName+" "+myJobSeekerInformation.lastName+" "+myJobSeekerInformation.middleName,
                          "textField"
                        )} 
                        {/* {editInputComponent(
                          "jobseeker",
                          "userEmail",
                          "User Email",
                          "-",
                          // myJobSeekerInformation.email,
                          "email"
                        )} */}
                        {editInputComponent(
                          "jobseeker",
                          "sex",
                          "Sex",
                          myJobSeekerInformation.sex,
                          "radio",
                          ["male", "female"]
                        )}
                        {/* {editInputComponent(
                          "jobseeker",
                          "phone",
                          "Phone",
                          //  myJobSeekerInformation.phone,
                          "-",
                          "phone"
                        )} */}
                        <MKTypography mt={5} mb={2} mx={2}>
                          Address
                        </MKTypography>
                        {editInputComponent(
                          "jobseeker",
                          "country",
                          "Country, City",
                          myJobSeekerInformation.country+"/ "+ myJobSeekerInformation.city,
                          "textField"
                        )} 
                       { (myJobSeekerInformation.city)&&<>
                      {(myJobSeekerInformation.city.toLowerCase()=="addis ababa")&&
                       <>
                        {editInputComponent(
                          "jobseeker",
                          "subCity",
                          "Specific Area",
                          myJobSeekerInformation.subCity+"/ "+ myJobSeekerInformation.specificArea,
                          "textField"
                        )} </>}
                        </>}
                      </MKBox>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )} 
          </Grid>
        </Container>
      )}
    </MKBox>

    <Dialog open={openedDialog=="moreText"} onClose={()=>{setOpenedDialog("")}}>
        {moreText&&<>
       {moreText.title&& <DialogTitle>{moreText.title}</DialogTitle>}
       {moreText.content&& <DialogContent>
          <DialogContentText>
           {moreText.content}
          </DialogContentText> 
        </DialogContent>}</>}
        <DialogActions>
          <Button onClick={()=>{setOpenedDialog("")}}>close</Button> 
        </DialogActions>
      </Dialog>
    
    <Dialog open={openedDialog=="sendRequest"} onClose={()=>{setOpenedDialog("")}}>
        <DialogTitle>Send Request</DialogTitle>
        <DialogContent>



          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
 

          {myJobSeekerInformation&&<List>
          <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <Avatar  src={
                        myJobSeekerInformation.profileImageUrl
                          ? myJobSeekerInformation.profileImageUrl
                          : profilePicture
                      }/>
                        </ListItemIcon>
                        <ListItemText primary={myJobSeekerInformation.firstName
                        ? myJobSeekerInformation.firstName + " " + myJobSeekerInformation.lastName
                        : "Job seeker"} />
                      </ListItemButton>
                    </ListItem> 
                    {(agencyData&& isAgentWebsite )&&
                    <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <Avatar  src={
                          agencyData.logo
                            ? agencyData.logo
                            : profilePicture
                        }/>
                          </ListItemIcon>
                          <ListItemText primary={agencyData.fullName
                          ? agencyData.fullName  : "Employee Agency"} />
                        </ListItemButton>
                    </ListItem> }
           </List>}

           <Divider />

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Your Full Name"
            type="text"
            fullWidth
            defaultValue={sendRequestData.requesterFullName}
            variant="outlined"
            onChange={(e)=>{  
              handleSendRequestData("requesterFullName",e.target.value)
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="phone"
            label="Your Phone"
            type="text"
            fullWidth
            variant="outlined"
            defaultValue={sendRequestData.requesterPhone}
            onChange={(e)=>{ 
              handleSendRequestData("requesterPhone",e.target.value)
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Your Email"
            type="email"
            fullWidth
            variant="outlined"
            defaultValue={sendRequestData.requesterEmail}
            onChange={(e)=>{ 
              handleSendRequestData("requesterEmail",e.target.value)
            }}
          /> 
          {(cities)&&
          <Autocomplete fullWidth  sx={{ marginTop: 3}}
               variant="outlined"
                onChange={(e, val) => {
                    if(val){
                      if(val){
                      // console.log("val,id",val); 
                        handleSendRequestData("requesterWorkingCity",val.TOWN_NAME)
                      }}
                }}
                options={cities}
                // defaultValue={sendRequestData.requesterEmail}
                defaultValue={cities.length?cities[1]:{}}
                getOptionLabel={(option) =>  option.TOWN_NAME}
                // renderInput={(params) => <MKInput {...params} variant="standard" />}
                renderInput={(params) => <TextField {...params} label="Working City" />}
              />}
          {specificAreas&&sendRequestData&&<>
          {(sendRequestData.requesterWorkingCity?((sendRequestData.requesterWorkingCity=="Addis Ababa")?true:false):false)
          &&<Autocomplete fullWidth  sx={{ marginTop: 3}}
               variant="outlined"
                onChange={(e, val) => {
                    if(val){ 
                      // console.log("val,id",val); 
                        handleSendRequestData("requesterWorkingSpecificArea",val.title)
                      } 
                }}
                options={specificAreas}
                defaultValue={specificAreas.length?specificAreas[0]:{}}
                getOptionLabel={(option) =>  option.title}
                // renderInput={(params) => <MKInput {...params} variant="standard" />}
                renderInput={(params) => <TextField {...params} label="Working Area" />}
              />}</>}
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setOpenedDialog("")}}>Cancel</Button>
          {/* <Button  variant="contained" >Send</Button> */}
          <MKButton disabled={!((sendRequestData.requesterEmail||sendRequestData.requesterPhone))} onClick={()=>{sendRequest()}} variant="gradient" color="success" size="small" sx={{ my: 2 }}>
            Send 
          </MKButton>
        </DialogActions>
      </Dialog>


  </>);
}

export default JobSeekerDetails;
