/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
 
import parse from "html-react-parser";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React, { useState ,useEffect} from "react";

// Rental page components
import FaqCollapse from "pages/LandingPages/Rental/components/FaqCollapse";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";

function Faq() { 
  const [collapse, setCollapse] = useState(false);
  const [currentData] = useCurrentUser();
  const {websitePagesContent } = currentData;
  const [FAQFromJobSeekers, setFAQFromJobSeekers] = React.useState([
   {question:`How can I find job opportunities online?`,answer:`Create profiles on job platforms, upload your resume, and use filters to search for relevant job openings. Set up job alerts to receive notifications about new opportunities.`},
   {question:`What should I include in my online job application?`,answer:`Include a well-crafted resume, a cover letter tailored to the job, and any requested documents or portfolios. Highlight relevant skills and experiences.`},
   {question:`Are online job interviews common, and how do they work?`,answer:`Yes, online interviews are common. Employers may use video conferencing tools. Ensure you have a stable internet connection and conduct the interview in a quiet, professional space.`},
  //  {question:``,answer:``},
]);
  const [FAQFromEmployers, setFAQFromEmployers] = React.useState([
    // {question:``,answer:``},
     {question:`How can I post a job opening online?`,answer:`To post a job opening, create an account on an online job platform, fill in the details about the position, and follow the platform's instructions for posting.`},
    {question:`What are the benefits of online job hiring for employers?`,answer:`Online job hiring offers a wider reach, efficient candidate filtering, and streamlined communication. It also allows for better organization of applications and hiring processes.`},
    {question:`How can I effectively screen candidates online?`,answer:`Use filters and search features on job platforms to narrow down candidates based on specific criteria. Additionally, conduct virtual interviews to assess skills and qualifications.`},
    {question:`Is it secure to hire employees online?`,answer:`Reputable online job platforms prioritize security. However, it's essential for employers to use trusted platforms and follow best practices for data protection.`},

  ]);
  const [FAQFromAgencies, setFAQFromAgencies] = React.useState([
    // {question:``,answer:``},
    // {question:``,answer:``},
    // {question:``,answer:``},
  ]);
  const [FAQGeneral , setFAQGeneral] = React.useState([
    {question:`What is online job hiring?`,answer:`Online job hiring refers to the process of recruiting and hiring candidates for job positions using digital platforms and internet-based tools.`},
    {question:`How does online job hiring work?`,answer:`Employers post job openings on online platforms, and candidates submit their applications digitally. The hiring process, including interviews and document submissions, often takes place online.`},
    // {question:``,answer:``},
  ]);
  const [FAQTechnical , setFAQTechnical] = React.useState([
    {question:`What do I do if I'm having technical issues with an online job platform?`,answer:`Contact the platform's support team for assistance. Ensure your internet connection is stable, and you are using a compatible browser.`},
    {question:`How do I protect my privacy when applying for jobs online?`,answer:`Be cautious about sharing sensitive personal information. Use secure, reputable job platforms, and verify the legitimacy of the job postings.`},
    // {question:``,answer:``},
  ]);
  const [FAQPage, setFAQPage] = React.useState(null);
  useEffect(() => {
    // console.log("websitePagesContent",websitePagesContent) 
    if(websitePagesContent){
      if(websitePagesContent.pages){
        if(websitePagesContent.pages.FAQPage){
          if(websitePagesContent.pages.FAQPage.sections){ 
            setFAQPage(websitePagesContent.pages.FAQPage.sections)
          }
        }
      }
    }
  }, [websitePagesContent]);

  return (<> 
      {(FAQPage)&&<> 
    <MKBox component="section" py={3}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Frequently Asked Questions
            </MKTypography> 
            <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
              {" The purpose of FAQs is to provide users with quick and easily accessible information, reducing the need for repetitive inquiries. By offering concise answers to common questions, FAQs aim to enhance user experience, improve customer service efficiency, and minimize confusion."}
              </MKTypography>
            </MKBox> 
          </Grid> 

          
          {(FAQPage.faq)&&<>
          {((FAQPage.faq.length?true:false))&& 
          <>
          <Grid item xs={12} md={10} mt={3}>
           <MKBox mb={2}>
              {/* <MKTypography variant="h3"   color="text">
              {"Filtered questions"}
              </MKTypography> */}
              
              <MKTypography variant="h4" mb={1}>
              {"Filtered questions"}
                </MKTypography>
            </MKBox> 
           </Grid>
         {(FAQPage.faq) &&<Grid item xs={12} md={10}>
           {FAQPage.faq.map((faq,i) => <FaqCollapse
              title={faq.question}
              open={collapse === faq.question}
              onClick={() => (collapse === faq.question ? setCollapse(false) : setCollapse(faq.question))}
            >{parse(faq.answer)}
            </FaqCollapse>)}
          </Grid>}
          </> }
        </>}



        {(FAQGeneral)&&<>
          {((FAQGeneral.length?true:false))&& 
          <>
          <Grid item xs={12} md={10} mt={3}>
           <MKBox mb={2}> 
              <MKTypography variant="h4" mb={1}>
              {"General Questions:"}
                </MKTypography>
            </MKBox> 
           </Grid> 
         <Grid item xs={12} md={10}>
           {FAQGeneral.map((faq,i) => <FaqCollapse
              title={faq.question}
              open={collapse === faq.question}
              onClick={() => (collapse === faq.question ? setCollapse(false) : setCollapse(faq.question))}
            >{parse(faq.answer)}
            </FaqCollapse>)}
          </Grid> 
          </> }
        </>}


        {(FAQFromJobSeekers)&&<>
          {((FAQFromJobSeekers.length?true:false))&& 
          <>
          <Grid item xs={12} md={10} mt={3}>
           <MKBox mb={2}> 
              <MKTypography variant="h4" mb={1}>
              {"For Job Seekers:"}
                </MKTypography>
            </MKBox> 
           </Grid> 
         <Grid item xs={12} md={10}>
           {FAQFromJobSeekers.map((faq,i) => <FaqCollapse
              title={faq.question}
              open={collapse === faq.question}
              onClick={() => (collapse === faq.question ? setCollapse(false) : setCollapse(faq.question))}
            >{parse(faq.answer)}
            </FaqCollapse>)}
          </Grid> 
          </> }
        </>}





        {(FAQFromEmployers)&&<>
          {((FAQFromEmployers.length?true:false))&& 
          <>
          <Grid item xs={12} md={10} mt={2}>
           <MKBox mb={2}> 
              <MKTypography variant="h4" mb={1}> 
              {"For Employers:"}
                </MKTypography>
            </MKBox> 
           </Grid> 
         <Grid item xs={12} md={10}>
           {FAQFromEmployers.map((faq,i) => <FaqCollapse
              title={faq.question}
              open={collapse === faq.question}
              onClick={() => (collapse === faq.question ? setCollapse(false) : setCollapse(faq.question))}
            >{parse(faq.answer)}
            </FaqCollapse>)}
          </Grid> 
          </> }
        </>}


        {(FAQTechnical)&&<>
          {((FAQTechnical.length?true:false))&& 
          <>
          <Grid item xs={12} md={10} mt={2}>
          <MKBox mb={2}> 
              <MKTypography variant="h4" mb={1}>
              {"Technical Questions:"}
                </MKTypography>
            </MKBox> 
          </Grid> 
        <Grid item xs={12} md={10}>
          {FAQTechnical.map((faq,i) => <FaqCollapse
              title={faq.question}
              open={collapse === faq.question}
              onClick={() => (collapse === faq.question ? setCollapse(false) : setCollapse(faq.question))}
            >{parse(faq.answer)}
            </FaqCollapse>)}
          </Grid> 
          </> }
        </>}
        </Grid>
      </Container>
    </MKBox>
        </>} 

 <MKBox component="section"  py={3}>
      <Container>
        <Grid container  justifyContent="center" spacing={3}>
        <Grid item xs={12} md={8}>
          {/* <Grid item xs={12} lg={10} flexDirection="column" justifyContent="center" ml="auto">
            <MKBox component="form" method="" action=""> */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}> 
                <MKTypography variant="h4" mb={1}>
                  Do you have any other question?
                </MKTypography>
                <MKTypography variant="body2" color="text">
                  If you have any other question, contact us through our contact form !
                </MKTypography>
              </Grid>
            <Grid item xs={12} sm={4}>
                <Link  to={"/pages/support/contact-us"} >
              <MKButton  variant="gradient" color="info" fullWidth  >
                Contact US
              </MKButton></Link>
            </Grid>
              </Grid>
            {/* </MKBox> */}
          {/* </Grid> */}
          </Grid>
        </Grid>
      </Container>
    </MKBox>



  </>);
}

export default Faq;
