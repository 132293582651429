 
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import React, { useState, useEffect } from "react";

// import required modules
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
 

// Images
// import bgImage from "assets/images/bg-rental.jpeg";
import bgImage from "assets/images/js-beautiful-Ethiopian-girl.webp";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, 
  IconButton,
  InputBase,
  Menu,
  Paper, 
} from "@mui/material"; 
import { Link, useNavigate } from "react-router-dom"; 
import { SearchRounded } from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices"; 
function HomeHeader() {
  const navigateComp = useNavigate();
  const [currentData] = useCurrentUser();
  const {
    websitePagesContent, 
    agencyData,
    myAgencies, 
  } = currentData;
  const [homePage, setHomePage] = React.useState(null);
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [openedDialog, setOpenedDialog] = React.useState(""); 
  useEffect(() => {
    // console.log("websitePagesContent",websitePagesContent)
    if (websitePagesContent) {
      if (websitePagesContent.pages) {
        if (websitePagesContent.pages.homePage) {
          if (websitePagesContent.pages.homePage.sections) {
            setHomePage(websitePagesContent.pages.homePage.sections);
          }
        } 
      }
    }
  }, [websitePagesContent]);
 
  return (
    <>
      {homePage && (
        <MKBox
          minHeight="40vh"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.5),
                rgba(gradients.dark.state, 0.5)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              md={7}
              justifyContent={{ xs: "center", md: "start" }}
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              {homePage.banner.title && (
                <MKTypography
                  variant="h1"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  {"Baally Jobs"}
                </MKTypography>
              )}

              {homePage.banner.description && (
                <MKTypography
                  variant="body1"
                  color="white"
                  mt={1}
                  pr={{ md: 12, lg: 24, xl: 32 }}
                  opacity={0.8}
                >
                  {" "}
                  {"Find experienced job seekers and great jobs near you."}
                </MKTypography>
              )}
            </Grid>
          </Container>
        </MKBox>
      )}
<Container>
  
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 6 },
          mt: -13,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
        {/* <Search /> */}
        {/* <Places /> */}
        <Grid container mb={2}>
          <Grid item xs={12} lg={5} justifyContent={"flex-end"}>
            <MKTypography variant="h5" opacity={0.4}>
              {"Find your dream job"}
            </MKTypography>
          </Grid>

          <Grid item xs={12} md={12} sx={{ mt: 2 }} alignItems={"right"}>
            {
              <Paper
                // component="form"
                sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: "100%" }}
              >
                {/* <IconButton sx={{ p: "10px" }} aria-label="menu">
                  <Menu />
                </IconButton> */}
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search "
                  // value={searchKeyword}
                  onKeyDown={(event) => {
                    if (event.keyCode) {
                      if (event.keyCode === 13) {
                        let pathSearch = { keyword: searchKeyword };
                        pathSearch = CommonServices.encryptLocalData(pathSearch);
                        let path = "/pages/jobs?search=" + pathSearch; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
                        navigateComp(path);
                      }
                    }
                  }}
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                    // console.log(e.target.value)
                  }}
                  inputProps={{ "aria-label": "search google maps" }}
                />
                <IconButton
                  // disabled={!searchKeyword}
                  onClick={() => {
                    let pathSearch = { keyword: searchKeyword };
                    pathSearch = CommonServices.encryptLocalData(pathSearch);
                    let path = "/pages/jobs?search=" + pathSearch; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
                    navigateComp(path);
                  }}
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchRounded />
                </IconButton>
                {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
            <Directions />
          </IconButton> */}
              </Paper>
            }
          </Grid>
        </Grid>
      </Card> 
 
</Container>

      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{moreText.title}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{moreText.content}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default HomeHeader;
