// @mui material components  
 
import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography"; 
import {  Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect  } from "react";
import { HttpService } from "Api-Services/httpService";
 
import {
  Avatar, 
  Button, 
  CardContent,
  CardHeader,
  Chip, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,  
  Stack, Card, 
  Typography,
  CardMedia,
  Grid,
} from "@mui/material"; 
import {  
  AddCircle,
  Favorite, 
  LockClockOutlined, 
  MoreVert, 
  PeopleOutlineRounded, 
  PlaceRounded,
  Receipt, 
  Reply, 
  Visibility,
  WorkOutlineOutlined,
} from "@mui/icons-material"; 
import { red } from "@mui/material/colors";
 
import { DataManagementService } from "Api-Services/dataManagementService";
import MKButton from "components/MKButton";
 
  
function AgencyShortInfoBox(props) { 
  const navigateComp = useNavigate();

  const [currentData, updateContext] = useCurrentUser();

  const {
    employmentTypes,
    employmentJobLocationTypes,
    employmentSalaryDealTypes,   
  } = currentData;
  
  const [forceRenderer, setForceRenderer] = React.useState(false); 
  const [forceRerender, setForceRerender] = React.useState(false); 
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [jobOverview, setJobOverview] = React.useState({});

  const viewDetails = (id, data) => {
    DataManagementService.setLocalstorageDataSecured(data, "agencyDetail");
    // let path = "/pages/jobs/job-details?id=" + id; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
    // navigateComp(path);
  };

  useEffect(() => {
    // console.log("  jobVacancy short ------------------------------------------")
  });
  
  const incrementField = async (id, fieldName, updateBy) => {
    try {
      if (id && fieldName) {
        let relativePath = "/api/employmentjobvacancy/incrementfieldbyid";
        let data = {
          field: fieldName,
          id: id,
          updateBy: updateBy || 1,
        };
          await postDocuments("contactus", relativePath, data); 
      }
      // setLoading(false);
    } catch (error) {}
  };
  
  const parseDate = (dt) => {
    let d = new Date(dt);
    let m = d.getMonth() + 1;
    let dateD = m + "/" + d.getDate() + "/" + d.getFullYear();
    return dateD;
  };

  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = returnField?"":{};
    // console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = "";
                if (row[returnField]) {
                  rowField = row[returnField];
                }
                res = rowField;
              }
            }
          }
        }
      } 
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false }; 
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
 
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true; 
    } 
    return responseD; 
  };

  const truncateString = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          let btn = lableName ? (
            <Button
              variant="text"
              onClick={() => {
                setMoreText({ content: str, title: lableName });
                setOpenedDialog("moreText");
                setForceRerender(!forceRerender);
              }}
            >
              {"View more"}
            </Button>
          ) : null;
          let txt = str.slice(0, num) + "...";
          res = (
            <>
              {parse(txt)} {btn}
            </>
          );
        } else {
          res = <>{parse(res)}</>;
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };
 

  const agencyComponent=(jobVacancy)=>{ 
     let jobOverviewComponent=<></> 
    try {
       jobOverviewComponent=(<>
     
     {jobVacancy&&<MKBox display="flex" flexDirection="column">
     <Card 
    //  sx={{ maxWidth: 345 }}
     >
                              <CardHeader
                                avatar={
                                  <Avatar 
                                  sx={{ width:54, height: 54, bgcolor: red[500] }} src={jobVacancy.logo} aria-label="recipe" />
                                }
                                action={(props.componentViewType==3)? 
                                    <AddCircle  color="success" sx={{ marginTop:"10px",}}  fontSize="large"/> :null
                                }
                                title={
                                  <MKTypography variant="h6">
                                    {truncateString(jobVacancy.fullName, 65)}
                                  </MKTypography>
                                }
                                subheader={ 
                                  <Typography variant="body2" color="text.secondary">
                                  <PeopleOutlineRounded /> 
                                  {" "}{1461+jobVacancy.numberOfMembers} {" Members"}
                                </Typography> 
                                }
                              />

                            { (props.componentViewType==1)&& <CardMedia
                                      component="img"
                                      sx={{ height: 140 ,
                                        // width:"150px" ,
                                        marginTop:"0px"
                                      }}
                                      image={jobVacancy.bannerImage}
                                      alt={jobVacancy.fullName}
                                    />}
                           { (props.componentViewType==1||props.componentViewType==2)&&<CardContent 
                              sx={{marginBottom: "-11px"}}
                                onClick={() => {
                                  // viewDetails(jobVacancy.id);
                                }}
                                // sx={{textAlign: "left",pl:2}}
                              >
                                <Typography variant="body2" color="text.secondary">
                                  <PlaceRounded />{" "}
                                  {jobVacancy.locationRegion &&(
                                    <>
                                      {jobVacancy.locationRegion + " , " + jobVacancy.locationTown} 
                                    </>
                                  ) }
                                </Typography>
                                {/* <Typography variant="body2" color="text.secondary">
                                  <PeopleOutlineRounded /> 
                                  {" "}{1461+jobVacancy.numberOfMembers} {" Members"}
                                </Typography>  */}
                                <Divider /> 
                                          <Grid
                // sx={{ marginBottom: "-39px" }}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item>
               {/* <Stack direction="row" m={1} spacing={2}> */} 
                    <MKButton  component={Link}  onClick={()=>{viewDetails(jobVacancy.id,jobVacancy)}} to={"/pages/agencies/join-agency?id="+jobVacancy.id}  size="small" color="success" variant="outlined"
                    startIcon={<AddCircle  color="success"  fontSize="large"/>}
                    >
                 
                      Join</MKButton>
                     {/* </Stack> */}
                </Grid>
                <Grid item>
                  <MKButton component={Link}  onClick={()=>{viewDetails(jobVacancy.id,jobVacancy)}} to={"/pages/agencies/agency?id="+jobVacancy.id} size="small"   >see jobs</MKButton>
                      
                </Grid>
              </Grid> 
                              </CardContent>}
                            </Card>
                          </MKBox>}
     </>) 
    } catch (error) {
    console.log(error,"  agency errr short  ")

    }
      return jobOverviewComponent

  }
 
 
  return (
    <>
          {props.agency?agencyComponent(props.agency):null}

          {/* </Card> */}

          {forceRenderer}

          <Dialog
            open={openedDialog == "moreText"}
            onClose={() => {
              setOpenedDialog("");
            }}
          >
            {moreText && (
              <>
                {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
                {moreText.content && (
                  <DialogContent>
                    <DialogContentText>{parse(moreText.content)}</DialogContentText>
                  </DialogContent>
                )}
              </>
            )}
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenedDialog("");
                }}
              >
                close
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openedDialog == "jobOverview"}
            onClose={() => {
              setOpenedDialog("");
            }}
          >
            {jobOverview && (
              <>
                {jobOverview.title && <DialogTitle>{parse(jobOverview.title)}</DialogTitle>}
                {jobOverview.id && (
                  <DialogContent>
                    {/* <DialogContentText>{parse(jobOverview.content)}</DialogContentText>
                     */}

                    <MKBox ml={4}>
                      <MKTypography variant="h6" color="text">
                        Overview:
                      </MKTypography>
                      <MKBox ml={1}>
                        <MKTypography variant="subtitle2" fontWeight="light" color="text">
                          Employment type :
                          <strong style={{ fontWeight: "430" }}>
                            {" "}
                            {getDataFromArray(
                              employmentTypes,
                              "id",
                              jobOverview.employmentTypeId,
                              "single",
                              "title"
                            )}
                          </strong>
                        </MKTypography>
                        <MKTypography variant="subtitle2" fontWeight="light" color="text">
                          Salary deal :{" "}
                          <strong style={{ fontWeight: "430" }}>
                            {getDataFromArray(
                              employmentSalaryDealTypes,
                              "id",
                              jobOverview.salaryDealTypeId,
                              "single",
                              "title"
                            )}
                          </strong>
                        </MKTypography>
                        <MKTypography variant="subtitle2" fontWeight="light" color="text">
                          Gender :
                          <strong style={{ fontWeight: "430" }}>
                            {" "}
                            {jobOverview.applicantGender}
                          </strong>
                        </MKTypography>
                        <MKTypography variant="subtitle2" fontWeight="light" color="text">
                          Experiance :{" "}
                          <strong style={{ fontWeight: "430" }}>
                            {(jobOverview.minimumExperianceInYear != null
                              ? "starting from " + jobOverview.minimumExperianceInYear + " "
                              : "") +
                              (jobOverview.maximumExperianceInYear >
                              jobOverview.minimumExperianceInYear
                                ? " to " + jobOverview.maximumExperianceInYear
                                : "") +
                              " year(s) "}
                          </strong>
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    <MKBox ml={4} mt={2}>
                      <MKTypography variant="h6" color="text">
                        Job Description:
                      </MKTypography>
                      <MKBox ml={1}>
                        <MKTypography variant="subtitle2" fontWeight="light" color="text">
                          {parse(jobOverview.description)}
                        </MKTypography>
                      </MKBox>
                      <Button
                        size="small"
                        // variant={Link}
                        // disabled={loading || !professionTitle}
                        // color="success"
                        onClick={() => {
                          viewDetails(jobOverview.id, jobOverview);
                        }}
                      >
                        Read More
                      </Button>
                    </MKBox>
                  </DialogContent>
                )}
              </>
            )}
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenedDialog("");
                }}
              >
                close
              </Button>
            </DialogActions>
          </Dialog>
      {forceRerender}
    </>
  );
}

export default AgencyShortInfoBox;
