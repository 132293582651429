// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples

// Images
import bgImage from "assets/images/examples/city.jpg";
import team1 from "assets/images/team-5.jpg";
import team2 from "assets/images/bruce-mars.jpg";
import team3 from "assets/images/ivana-squares.jpg";
import team4 from "assets/images/ivana-square.jpg"; 
// import Search from "pages/LandingPages/Extreme/sections/Search";
import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect } from "react";
import Search from "./Search";

import ReactGA from "react-ga4"; 
import JobSeekersPreview from "./components/JobSeekersPreview";
function SearchEmployee() {
  const navigateComp = useNavigate();
  // console.log("cities", cities);

  const [currentData] = useCurrentUser();
  const { currentUser, newJobSeekers, myJobSeekerInformation, jobs } = currentData;
  const [currentEducationLevelId, setCurrentEducationLevelId] = React.useState(5);
  const [searchResult, setSearchResult] = React.useState([]);
  const [notSearchedYet, setNotSearchedYet] = React.useState(true);
  const [forceRerender, setForceRerender] = React.useState(false);
  const [professionTitle, setProfessionTitle] = React.useState("");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Employee search" });
    // console.log("educationLevels", educationLevels);
    // console.log(" myJobSeekerInformation", myJobSeekerInformation);
  }, [currentUser]);

  const goToPage = (page) => {
    navigateComp(page);
  };

  const handleSearchResult = (result) => {
    // console.log("handleSearchResult",result)
    setNotSearchedYet(false);

    setProfessionTitle(result.professionTitle || "");
    let res = false;
    if (result.employees) {
      res = result.employees;
    }
    if (res) {
      setSearchResult(res);
    } else {
      setSearchResult([]);
    }
    setForceRerender(!forceRerender);
    // console.log(res);
  };

  return (
    <>
      <MKBox component="section" pt={3}>
        {/* <div component="section" position="relative" py={12}> */}

        <MKBox
          component="section"
          position="relative"
          pt={2}
          sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
              `${linearGradient(rgba(gradients.dark.main, 0.8), rgba(gradients.dark.state, 0.8))}, 
                ` +
              `
                // url(${bgImage})
                `,
          }}
        >
          <Container>
           {/* { notSearchedYet&&  <Grid container>
              <Grid item xs={12} md={8} sx={{ mb: 4 }}>
                <MKTypography
                  variant="h3"
                  // color="white"
                >
                  {searchResult.length > 0
                    ? "Select " + professionTitle
                    : notSearchedYet
                    ? "Welcome, Fill the below form and click search "
                    : "No matching " + professionTitle + ", Try again"}
                </MKTypography>
              </Grid>
            </Grid>} */}
            <Search handleSearchResult={handleSearchResult} />
          {!notSearchedYet&&<Grid container>
              <Grid item xs={12} md={8} sx={{ mb: 4 }}>
                {/* <MKBox
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="3rem"
                    height="3rem"
                    variant="gradient"
                    bgColor="info"
                    color="white"
                    shadow="md"
                    borderRadius="lg"
                    mb={2}
                  >
                    <Icon>supervisor_account</Icon>
                  </MKBox> */}
              {  <MKTypography
                  variant="h3"
                  // color="white"
                >
                  {searchResult.length > 0
                    ? "Select " + professionTitle
                    : notSearchedYet
                    ? "Welcome, Fill the above form and click search "
                    : "No matching " + professionTitle + ", Try again"}
                </MKTypography>}
                {!searchResult.length && (
                  <MKTypography
                    variant="body2"
                    // color="white"
                    opacity={0.8}
                  >
                    {searchResult.length > 0
                      ? "Select any of below  " + professionTitle + " and send request to join you"
                      : notSearchedYet
                      ? "Here we are to address your needs"
                      : "No matching " + professionTitle + " found, Search the above form again"}
                  </MKTypography>
                )}
              </Grid>
            </Grid>}
          </Container>
        </MKBox>
        {forceRerender}
            {searchResult.length > 0 && ( 
                 <JobSeekersPreview jobSeekers={searchResult}/>
            )}
        {/* </div> */}
        {notSearchedYet && <JobSeekersPreview jobSeekers={newJobSeekers}/>}
      </MKBox>
    </>
  );
}

export default SearchEmployee;
