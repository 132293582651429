// @mui material components
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { cities, specificAreas, subCities, citiesOnly } from "Api-master-datas/masterDatas.js";
import queryString from "query-string";
import { toast } from "react-toastify";

import PropTypes from "prop-types";
import split from "just-split";
import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography"; 
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect, useRef } from "react";
import { HttpService } from "Api-Services/httpService";

import ReactGA from "react-ga4";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Avatar,
  Box,
  Button, 
  CardActions, 
  CardContent,
  CardHeader,
  Chip, 
  Collapse, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton, 
  Skeleton,
  Stack, 
  Typography,
} from "@mui/material";
import { Card, TextField } from "@mui/material"; 
import { 
  ArrowForwardIosSharp,  
  ExpandMoreOutlined,  
  Favorite, 
  LockClockOutlined,
  Map,
  MoreVert, 
  PlaceRounded,
  Receipt, 
  Reply, 
  Visibility,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices";
import { isMobile } from "react-device-detect";
import { red } from "@mui/material/colors";
import MKButton from "components/MKButton";
import { DataManagementService } from "Api-Services/dataManagementService";
  
 
 
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0.5px solid ${theme.palette.divider}`,
  borderBottom: "0.5px darkgreen solid",
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
}));
 
 
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

 
function JobSeekersPreview(props) { 
  // const [forceRerender, setForceRerender] = React.useState(false);

  const navigateComp = useNavigate();
  // console.log("cities", cities);

  const [currentData, updateContext] = useCurrentUser();

  const {
    employmentTypes,
    employmentJobLocationTypes,
    employmentSalaryDealTypes, 
  } = currentData;
   

  const [forceRerender, setForceRerender] = React.useState(false); 
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [jobOverview, setJobOverview] = React.useState({});
 
  const ref = useRef(null);
  const [jobsData, setJobsData] = React.useState([]);
  const viewDetails = (id) => {
    let path = "/pages/jobs/job-details?id=" + id; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
    navigateComp(path);
  }; 
  const [expandedJobSeekerOverview, setExpandedJobSeekerOverview] = React.useState(false);

  const handleExpandClick = (id) => {
    // console.log("id.",id)
    // console.log("expandedJobSeekerOverview.",expandedJobSeekerOverview)
    if(expandedJobSeekerOverview==id){
      setExpandedJobSeekerOverview(false)
    }
    else{
      setExpandedJobSeekerOverview(id);}
  };

  useEffect(() => {
    try {
      // console.log("props.",props)
      let JobListProp=props.jobSeekers 
        if (JobListProp) {
          if (JobListProp.length) { 
            setJobsData(JobListProp);
            setForceRerender(!forceRerender)
          }
        } 
    } catch (error) {}
  }, [props.jobSeekers]);


  const incrementField = async (id, fieldName, updateBy) => {
    try {
      if (id && fieldName) {
        let relativePath = "/api/employmentjobvacancy/incrementfieldbyid";
        let data = {
          field: fieldName,
          id: id,
          updateBy: updateBy || 1,
        };
        // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
        let employmentJobVacancyRes = await postDocuments("contactus", relativePath, data);
        // console.log("vacancy incrementField", employmentJobVacancyRes);
      }
      // setLoading(false);
    } catch (error) {}
  }; 
  
  const parseDate = (dt) => {
    let d = new Date(dt);
    let m = d.getMonth() + 1;
    let dateD = m + "/" + d.getDate() + "/" + d.getFullYear();
    return dateD;
  };
 
  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = {};
    // console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = row[returnField];
                res = rowField;
              }
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    }

    // console.log(documentName, " responseD: ", responseD);
    // setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const truncateString = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          let btn = lableName ? (
            <Button
              variant="text"
              onClick={() => {
                setMoreText({ content: str, title: lableName });
                setOpenedDialog("moreText");
                setForceRerender(!forceRerender);
              }}
            >
              {"View more"}
            </Button>
          ) : null;
          let txt = str.slice(0, num) + "...";
          res = (
            <>
              {parse(txt)} {btn}
            </>
          );
        } else {
          res = <>{parse(res)}</>;
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };
 

  const truncateStringDefualt=(str, num, lableName,) =>{
    let res=""
    try {
      if (str) {
        if (str.length > num) {  
        let txt=str.slice(0, num) + "..."
           res= txt 
        }  
        else{ 
           res= str 
        }
    } 
  }catch (error) {
      // console.log("errrr",error)
    }
    return res
  }

  const jobsLoading = (num) => {
    try {
      return (
        <> 

          <div style={{ marginBottom: "10px" }}>
            {[...Array(num ? num : 5)].map(() => (
              <div style={{ marginBottom: "10px" }}>
                <Accordion expanded={false}>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    {/* <MKBox component="section"> */}
                    {/* <Container> */}
                    <Grid container>
                      <Grid item xs={12} lg={10} sx={{ mt: 2 }}>
                        <Grid
                          container
                          spacing={{ xs: 0, lg: 0 }}
                          // sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}
                        >
                          <Grid item xs={12} lg={4} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="h6"
                              fontWeight="bold"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="rectangular" width={210} height={30} />
                            </MKTypography>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={250} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                          </Grid>

                          <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={150} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                            {/* <Autocomplete
                              // defaultValue="High School"
                              onChange={(e, val) => { 
                                  // setCurrentEducationLevelId(val.id);
                                handleSearchFieldsandValue("currentEducationLevelId",val?val.id:"Any","jobseeker")}
                              }  
                              options={[...[{title:"Any",id:"Any"}], ...educationLevels]}
                              defaultValue={getEducationLevelById(jobSeekerSearchFieldsandValue.currentEducationLevelId, "single")}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => <MKInput {...params} variant="standard" />}
                            /> */}

                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                          </Grid>

                          <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={150} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                            {/* <Autocomplete
                            onChange={(e, val) => {
                              // setSex(val);
                              handleSearchFieldsandValue("sex",val?val:"Any","jobseeker")

                            }}
                            value={jobSeekerSearchFieldsandValue.sex}
                            options={["Any", "male", "female"]}
                            getOptionLabel={(option) =>  option }
                            renderInput={(params) => <MKInput {...params} variant="standard" placeholder="select gender"/>}
                          /> */}
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                          </Grid>
                          <Grid item xs={12} lg={2} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={150} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                            {/* <Autocomplete
                            onChange={(e, val) => {
                              // setSex(val);
                              handleSearchFieldsandValue("sex",val?val:"Any","jobseeker")

                            }}
                            value={jobSeekerSearchFieldsandValue.sex}
                            options={["Any", "male", "female"]}
                            getOptionLabel={(option) =>  option }
                            renderInput={(params) => <MKInput {...params} variant="standard" placeholder="select gender"/>}
                          /> */}
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />{" "}
                            </MKTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* </Container> */}
                    {/* </MKBox> */}
                  </AccordionSummary>
                  <AccordionDetails></AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </>
      );
    } catch (error) {
      return <></>;
    }
  };
 

  return (<> 
  <Container maxWidth="sm">
        <>
          <> 
             
        {/* <div ref={ref}> */}
          {(jobsData ? (jobsData.length > 0 ? true : false) : false) && (
            <div style={{ marginBottom: "10px" }}>
              {/* <Grid
                // sx={{ marginBottom: "-39px" }}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item>
                  <MKTypography variant={"h4"}>Recent Jobs</MKTypography>
                </Grid>
                <Grid item>
                  <Button component={Link} variant="text" to={"/pages/jobs"}>
                    See all
                  </Button>
                </Grid>
              </Grid> */}
 
                 <Grid
                      container
                      spacing={1}
                      direction="row" 
                      alignItems="center"
                    >
                  {jobsData.map((jobSeeker) => {
                    // var numberOfLikes = jobSeeker.numberOfLikes || 0;
                    if (true) {
                      return ( 
                        <Grid item xs={12} md={12} lg={(expandedJobSeekerOverview==jobSeeker.id)?12:6}   >
                            <MKBox display="flex" flexDirection="column">
                              {/* <Card 
                              sx={{
                                //  maxWidth: 345,
                                 boxShadow:"0rem 0.25rem 3.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)" }}>
                                <CardHeader
                                  avatar={
                                    <Avatar  aria-label="recipe" src={jobVacancy.profileImageUrl}/>
                                  }
                                  action={
                                    <IconButton
                                      aria-label="settings"
                                      onClick={() => {
                                        setOpenedDialog("jobOverview");
                                        setJobOverview(jobVacancy);
                                      }}
                                    >
                                      <MoreVert />
                                    </IconButton>
                                  }
                                  title={
                                    <MKTypography variant="h6">
                                      {truncateString( jobVacancy.firstName +" "+jobVacancy.firstName , 65)}
                                    </MKTypography>
                                  }
                                  subheader={
                                    <MKTypography color={"text"} variant="subtitle2">
                                      {truncateString("jobVacancy.employmentAgencyFullName", 65)}
                                    </MKTypography>
                                  }
                                />
 
                              </Card> */}

                              <Card  >
      <CardHeader sx={{padding:"5px"}}
      
      onClick={()=>handleExpandClick(jobSeeker.id)}
        avatar={
         
          <Avatar  aria-label="recipe" 
          sx={{ width: 56, height: 56 }} src={jobSeeker.profileImageUrl}/>
        }
        action={
          <IconButton aria-label="settings"  sx={{ marginTop:"-11px",
          /* margin-right: 15px; */
          marginBottom: "-4px",
          marginLeft: "-22px"}}>
            {/* <MoreVert />
             */}
             
        <ExpandMore
          expand={expandedJobSeekerOverview==jobSeeker.id}
          onClick={()=>handleExpandClick(jobSeeker.id)}
          aria-expanded={expandedJobSeekerOverview==jobSeeker.id}
          aria-label="show more"
        >
          <ExpandMoreOutlined />
        </ExpandMore>
          </IconButton>
        }
        
        title={
          <MKTypography variant="h6">
            {truncateString( jobSeeker.firstName +" "+jobSeeker.lastName  , 65)}
          </MKTypography>
        }
        subheader={<> 
             {(jobSeeker.currentEducationLevelEducatedSchool)&&
            <MKTypography variant="caption " >
              { truncateStringDefualt(jobSeeker.currentEducationLevelEducatedField +" / "+ jobSeeker.currentEducationLevelEducatedSchool,26) }
            </MKTypography>}
          <MKTypography color={"text"} variant="subtitle2">

            {(jobSeeker.skillsOrSubjects)&&<>
            {(jobSeeker.skillsOrSubjects.length)&& <>
            {(jobSeeker.skillsOrSubjects.length>0)&& 
             <Stack direction="row"  useFlexGap flexWrap="wrap" spacing={1}>
                      {truncateStringDefualt(jobSeeker.skillsOrSubjects,isMobile?60:70).split(",").map((skillD)=> 
              <Chip size="small" style={{marginBottom:"3px"}} label={skillD}/> 
            )} </Stack>}</>}</>}
          </MKTypography></>
        }
      />
      {/* <CardMedia
        component="img"
        height="194"
        image="/static/images/cards/paella.jpg"
        alt="Paella dish"
      /> */}
      {/* <CardContent>
        <Typography variant="body2" color="text.secondary">
          This impressive paella is a perfect party dish and a fun meal to cook
          together with your guests. Add 1 cup of frozen peas along with the mussels,
          if you like.
        </Typography>
      </CardContent> */}
      {/* <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions> */}
      <Collapse in={expandedJobSeekerOverview==jobSeeker.id} timeout="auto" unmountOnExit>
        <CardContent>
          
        <MKTypography variant="subtitle2" fontWeight="light" color="text">
                            {parse(jobSeeker.description||"")}
                          </MKTypography>
                          <Grid
                // sx={{ marginBottom: "-39px" }}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
              > 
                <Grid item>
                  <Button component={Link} variant="text" onClick={()=>{
                       DataManagementService.setLocalstorageDataSecured(jobSeeker, "jobSeekerDetail");
                      // navigateComp("/pages/JobSeeker-Details?id="+jobSeeker.id)

                  }} 
                  to={"/pages/JobSeeker-Details?id="+jobSeeker.id}
                  >
                    See More
                  </Button>
                </Grid>
              </Grid>
          {/* <Typography paragraph>{parse(jobSeeker.description)}</Typography>  */}
        </CardContent>
      </Collapse>
    </Card>
                            </MKBox>
                        </Grid>
 
                      );
                    }
                  })}  
                  </Grid>
            </div>
          )}
        {/* </div> */} 
                              
                              <MKButton >Load More</MKButton> 
            {/* </Card> */}
 

          </>
        </> 
      {forceRerender} 
      </Container>
            <Dialog
              open={openedDialog == "moreText"}
              onClose={() => {
                setOpenedDialog("");
              }}
            >
              {moreText && (
                <>
                  {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
                  {moreText.content && (
                    <DialogContent>
                      <DialogContentText>{parse(moreText.content)}</DialogContentText>
                    </DialogContent>
                  )}
                </>
              )}
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenedDialog("");
                  }}
                >
                  close
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openedDialog == "jobOverview"}
              onClose={() => {
                setOpenedDialog("");
              }}
            >
              {jobOverview && (
                <>
                  {jobOverview.title && <DialogTitle>{parse(jobOverview.title)}</DialogTitle>}
                  {jobOverview.id && (
                    <DialogContent>
                      {/* <DialogContentText>{parse(jobOverview.content)}</DialogContentText>
                       */}

                      <MKBox ml={4}>
                        <MKTypography variant="h6" color="text">
                          Overview:
                        </MKTypography>
                        <MKBox ml={1}>
                          <MKTypography variant="subtitle2" fontWeight="light" color="text">
                            Employment type :
                            <strong style={{ fontWeight: "430" }}>
                              {" "}
                              {getDataFromArray(
                                employmentTypes,
                                "id",
                                jobOverview.employmentTypeId,
                                "single",
                                "title"
                              )}
                            </strong>
                          </MKTypography>
                          <MKTypography variant="subtitle2" fontWeight="light" color="text">
                            Salary deal :{" "}
                            <strong style={{ fontWeight: "430" }}>
                              {getDataFromArray(
                                employmentSalaryDealTypes,
                                "id",
                                jobOverview.salaryDealTypeId,
                                "single",
                                "title"
                              )}
                            </strong>
                          </MKTypography>
                          <MKTypography variant="subtitle2" fontWeight="light" color="text">
                            Gender :
                            <strong style={{ fontWeight: "430" }}>
                              {" "}
                              {jobOverview.applicantGender}
                            </strong>
                          </MKTypography>
                          <MKTypography variant="subtitle2" fontWeight="light" color="text">
                            Experiance :{" "}
                            <strong style={{ fontWeight: "430" }}>
                              {(jobOverview.minimumExperianceInYear != null
                                ? "starting from " + jobOverview.minimumExperianceInYear + " "
                                : "") +
                                (jobOverview.maximumExperianceInYear >
                                jobOverview.minimumExperianceInYear
                                  ? " to " + jobOverview.maximumExperianceInYear
                                  : "") +
                                " year(s) "}
                            </strong>
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                      <MKBox ml={4} mt={2}>
                        <MKTypography variant="h6" color="text">
                          Job Description:
                        </MKTypography>
                        <MKBox ml={1}>
                          <MKTypography variant="subtitle2" fontWeight="light" color="text">
                            {parse(jobOverview.description)}
                          </MKTypography>
                        </MKBox>
                        <Button
                          size="small"
                          // variant={Link}
                          // disabled={loading || !professionTitle}
                          // color="success"
                          onClick={() => {
                            viewDetails(jobOverview.id);
                          }}
                        >
                          Read More
                        </Button>
                      </MKBox>
                    </DialogContent>
                  )}
                </>
              )}
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenedDialog("");
                  }}
                >
                  close
                </Button>
              </DialogActions>
            </Dialog>
    </> );
}

export default JobSeekersPreview;
