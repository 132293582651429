
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
var CryptoJS = require("crypto-js"); 
var encryptionSecretLocal="employment1234"
export class CommonServices {
  
 
  static confirmPopUp = (title,message) => {
     let res= false 
   // Decrypt
       try {
       return new Promise(function(myResolve) {
         confirmAlert({
            title: title||'Confirm to submit',
            message: message||'Are you sure to do this.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {myResolve(true)}
              },
              {
                label: 'No',
                onClick: () => { myResolve(false)}
              }
            ]
          });


        })
      } catch (error) {
       return res
       }  
     };  
      
     static decryptLocalData = (data) => {
      let decryptedData=false
      console.log(typeof(data), data,"decryptedData   data")
         try {
          if(typeof(data)=="string"){ 
             decryptedData  =this.decrypt(data,encryptionSecretLocal) 
         }  
        } catch (error) {
          console.log(error,"decryptedData   error")
         } 
         return decryptedData
       }; 
       
     static encryptLocalData = (data) => { 
        let response={success:false,message:"No response, please contact your service provider",record:null}
        try { 
            response =this.encrypt(data,encryptionSecretLocal) 
        } catch (err) {}
        return response;
      }

    static  encrypt(word,secretKey) {
        let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), secretKey).toString()
        let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
        return encData
    }
     
    static  decrypt(word,secretKey) {
        let decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8)
        let bytes = CryptoJS.AES.decrypt(decData, secretKey).toString(CryptoJS.enc.Utf8)
        return JSON.parse(bytes)
    }
    
    static getDataFromArray (dataArray, searchField, searchValue, returnRows, returnField){
      let res = returnField?"":{};
      // console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
      try {
        if (dataArray && searchField && searchValue) {
          if (dataArray.length > 0) {
            let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
            if (filteredLevels.length) {
              res = filteredLevels;
              if (returnRows == "single") {
                let row = filteredLevels[0];
                res = row;
                if (returnField) {
                  let rowField = "";
                  if (row[returnField]) {
                    rowField = row[returnField];
                  }
                  res = rowField;
                }
              }
            }
          }
        } 
        // console.log("filtered city  ", res);
      } catch (error) {
        // console.log("filtered city error", error);
      }
      return res;
    };

     
    static parseDate = (dt) => {
          let d = new Date(dt);
          let m = d.getMonth() + 1;
          let dateD = m + "/" + d.getDate() + "/" + d.getFullYear();
          return dateD;
        };
    static getDomainFromOrigin (domainNameOrigin)  {
        let resp = "";
        let domainName = domainNameOrigin;
        if (domainName) {
          if (domainName.length) {
            domainName = domainName.toLowerCase();
            // domainName="https://www.mmmm2.gedeopeople2.gov.et/"
            let exdomainName = domainName.replace("//www.", "//");
            exdomainName = new URL(exdomainName);
            if (exdomainName) {
              if (exdomainName.hostname) {
                resp = exdomainName.hostname;
              }
            }
          }
        }
        return resp;
      };

}
