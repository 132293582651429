// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Baally Jobs",
    // image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/BFamilyPromotion",
    },
    // {
    //   icon: <TwitterIcon />,
    //   link: "https://www.ethiopianairlines.com/",
    // },
    // {
    //   icon: <GitHubIcon />,
    //   link: "https://www.ethiopianairlines.com/",
    // },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/@bfamilypromotion",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "about us", href: "/pages/company/about-us" }, 
        { name: "blogs", href: "/pages/blogs" },
      ],
    },
    // {
    //   name: "resources",
    //   items: [
    //     { name: "illustrations", href: "https://www.ethiopianairlines.com/" },
    //     { name: "bits & snippets", href: "https://www.ethiopianairlines.com/" },
    //     { name: "affiliate program", href: "https://www.ethiopianairlines.com/" },
    //   ],
    // },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "/pages/support/contact-us" },
        { name: "knowledge center", href: "/pages/AskQuestion" }, 
      ],
    },
    {
      name: "legal",
      items: [
      //   { name: "terms & conditions", href: "https://www.ethiopianairlines.com/" },
        { name: "privacy policy", href: "/pages/privacy-policy" }, 
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Baally Jobs by{" "}
      <MKTypography
        component="a"
        href="https://bfamily.et/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        B-Family
      </MKTypography>
      .
    </MKTypography>
  ),
};
