import { HttpService } from "../Api-Services/httpService";
import { createContext, useEffect, useState, useContext } from "react";
import { DataManagementService } from "../Api-Services/dataManagementService";
// import { SideBarRoutes } from 'sideBarRoutes';
import { UseAuth } from "Api-Services/useAuth";
import groupArray from "group-array";
import ReactGA from "react-ga4";
import { CommonServices } from "Api-Services/commonServices";
// home-banner

import homeBanner from "assets/images/examples/home-banner.jpg";
import aboutUsBanner from "assets/images/examples/about-us.jpg"; //aboutUsCard.jpg
import aboutUsCard from "assets/images/aboutUsCard.jpg"; //aboutUsCard.jpg
import queryString from "query-string";
const CurrentUserContext = createContext();
//now we have a context object

function CurrentUserProvider(props) {
  //create the provider and its functionality

  const [currentUser, setCurrentUser] = useState({});
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [employmentJobLocationTypes, setEmploymentJobLocationTypes] = useState([]);
  const [employmentSalaryDealTypes, setEmploymentSalaryDealTypes] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);
  const [websiteFiles, setWebsiteFiles] = useState({});
  const [userFiles, setUserFiles] = useState({});
  const [jobs, setJobs] = useState([]); //
  const [myAgencies, setMyAgencies] = useState([]);
  const [myJobApplications, setMyJobApplications] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [popularEmploymentAgencies, setPopularEmploymentAgencies] = useState([]);
  const [employmentAgencyPaymentAccounts, setEmploymentAgencyPaymentAccounts] = useState([]);
  const [employmentAgencyJobVacancies, setEmploymentAgencyJobVacancies] = useState([]);
  const [recentEmploymentJobVacancies, setRecentEmploymentJobVacancies] = useState([]);
  const [newJobSeekers, setNewJobSeekers] = useState([]);
  const [agencyNewJobSeekers, setAgencyNewJobSeekers] = useState([]);
  const [myJobSeekerInformation, setMyJobSeekerInformation] = useState(null);
  const [myAgencyJobSeekerInformation, setMyAgencyJobSeekerInformation] = useState(null);
  const [myJobSeekerAndAgencies, setMyJobSeekerAndAgencies] = useState([]);
  const [isAgentWebsite, setIsAgentWebsite] = useState(false);
  const [agencyData, setAgencyData] = useState(null);
 
  const [forceRerender, setForceRerender] = useState(true);
  const websitePagesContentD = {
    pages: {
      joinUsPage: {
        sections: {
          instruction: {
            title: "instructions",
            body: `<p>
            ONLINE REGISTRATION INSTRUCTIONS
            Note: The majority of returning students are eligible to use online registration. You are eligible unless you fall within
            any of the following categories:
            1) You are a new job seeker.
            2) You are a returning seeker who must reapply because of a lapse in continuous enrollment.
            3) You have earned less than 12 hours of college credit.
            4) You have a 1005–General College major AND have 30 or more hours of college credit. You may register online
            once you declare a specific major. Contact the Admissions Office to declare a specific major.
            5) You are non-degree seeking.
            6) You need to enroll in college preparatory/remedial courses.
            7) Your cumulative GPA is less than 2.00.
            8) You are a dual enrolled or early admission student.
            9) You are a clock-hour student (automotive, cosmetology, law enforcement, CNA, etc.)
            10) You have a hold on your record.
            11) You wish to take advantage of a state employee fee waiver.</p>
            `,
          },
          card: {
            cardImage:
              "https://images.unsplash.com/photo-1539803442075-48618f39bb3d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=963&amp;q=80",
          },
        },
      },
      homePage: {
        sections: {
          banner: {
            backgroundImage: homeBanner,
            title: "",
            // description:
            //   " The time is now for it be okay to be great. People in this world shun people for being nice.",
          },
        },
      },
      contactUsPage: {
        sections: {
          card: {
            image: null,
            title: "",
            description: "",
          },
          formSection: {
            note: `For further questions,, please contact using our contact form.`,
          },
        },
      },
      aboutUsPage: {
        sections: {
          banner: {
            backgroundImage: aboutUsBanner,
            title: "",
            // description:
            //   "We are constantly trying to express ourselves and actualize our dreams. If you  have the opportunity to play this game",
          },
          companyDescription: {
            title: "The BAALLY JOBS Platform",
            description: ` 
            <article> 
          
              <p>In today's dynamic job market, BAALLY JOBS has redefined the hiring landscape, bridging the gap between employers and job seekers. This brief explores the platform's evolution, highlighting its solutions to common recruitment challenges.</p>
          
              <p>BAALLY JOBS is a virtual nexus reshaping the recruitment process. It eliminates geographical constraints, connecting employers with a diverse global talent pool while empowering job seekers to explore opportunities beyond local boundaries.</p>
          
              <p>The platform's advanced features, including robust filters and algorithms, streamline hiring. Employers efficiently find candidates, and job seekers receive personalized recommendations based on their profiles and preferences. Incorporating AI and machine learning, BAALLY JOBS ensures a data-driven, strategic recruitment process.</p>
          
              <p>While offering efficiency, BAALLY JOBS prioritizes user experience. Its user-friendly interface and personalized recommendations address the potential overwhelming nature of job postings. Additionally, the platform is committed to eliminating algorithmic bias, fostering a fair and inclusive environment.</p>
          
              <p>In conclusion, BAALLY JOBS stands at the forefront of modern recruitment, providing tailored solutions for employers and job seekers. Through global reach, advanced features, and a commitment to inclusivity, BAALLY JOBS is transforming the employment landscape, promising a more connected and equitable professional future.</p>
            </article> `,
          },
          card: {
            image: aboutUsCard,
            description: `"Hire or Get Hired on our online platform involves a seamless and efficient process for both employers and job seekers. Employers can post job openings, define criteria, and review applicants, while job seekers can create profiles, apply for positions, and showcase their skills. Our platform facilitates a connection between talent and opportunities, streamlining the hiring process for employers and providing a user-friendly interface for individuals seeking their next career move."`,
            title: "Get insights on Search",
          },
          // teams: [
          //   {
          //     image: "https://wfcsradio.com/wp-content/uploads/2019/08/team-member-01.jpg",
          //     name: "Alec Thompson",
          //     profession: "CEO / Co-Founder",
          //     description:
          //       "And I love you like Kanye loves Kanye. We need to restart the human foundation.",
          //   },
          //   {
          //     image: "https://wfcsradio.com/wp-content/uploads/2019/08/team-member-01.jpg",
          //     name: "Alec Thompson",
          //     profession: "CEO / Co-Founder",
          //     description:
          //       "And I love you like Kanye loves Kanye. We need to restart the human foundation.",
          //   },
          //   {
          //     image: "https://wfcsradio.com/wp-content/uploads/2019/08/team-member-01.jpg",
          //     name: "Alec Thompson",
          //     profession: "CEO / Co-Founder",
          //     description:
          //       "And I love you like Kanye loves Kanye. We need to restart the human foundation.",
          //   },
          // ],
        },
      },
      FAQPage: {
        sections: {
          header: {
            title: "",
            description: "",
          },
          faq: [],
        },
      },
      footerPage: {
        sections: {
          socialMedias: [
            {
              icon: "facebook",
              link: "https://www.facebook.com/BFamilyPromotion",
            },
            {
              icon: "YouTube",
              link: "https://www.youtube.com/channel/UCBdLexsyb4xyeA7XPAOyqEw",
            },
          ],
        },
      },
      testimonialsPage: {
        sections: {
          header: {
            title: "Some thoughts from our clients",
            description: ` If you are selected for them you&apos;ll also get three tickets, opportunity to
                    access Investor Office Hours and Mentor Hours and much more all for free.
                 `,
          },
          testimonials: [
            {
              image: "",
              name: "Biniam Asefa",
              role: "Tutor",
              review:
                "The connections you make at Web Summit are unparalleled, we met users all over the world.",
            },
            {
              image: "",
              name: "Kibrom Ayele",
              role: "trainee parent",
              review:
                "The connections you make at Web Summit are unparalleled, we met users all over the world.",
            },
            {
              image: "",
              name: "Meron Kidane",
              role: "trainee",
              review:
                "The connections you make at Web Summit are unparalleled, we met users all over the world.",
            },
          ],
        },
      },
    },
  };
  const [agencyWebsitePagesContent, setAgencyWebsitePagesContent] = useState(null);
  const [websitePagesContent, setWebsitePagesContent] = useState(websitePagesContentD);
 

  useEffect(async () => {
    // console.log("location", location); 
    try {
    await getStarted();
      //
      let originUrl = location.href;
      let locationOrigin = location.origin;
      // let originUrl = "https://extremetutors.et/"; //"https://job.et/"// location.href;
      let newhref = null;
      let isLocalHost = null;
      let hostName = null;
      let currentAgencyDomain = null;
      if (DataManagementService.getLocalstorageDataSecured("currentAgencyDomain")) {
        currentAgencyDomain =
          DataManagementService.getLocalstorageDataSecured("currentAgencyDomain");
      }
      if (location.search) {
        let locationSearch = queryString.parse(location.search);
        if (locationSearch) {
          if (locationSearch.currentAgencyDomain) {
            currentAgencyDomain = locationSearch.currentAgencyDomain;
            DataManagementService.setLocalstorageDataSecured(
              currentAgencyDomain,
              "currentAgencyDomain"
            );
          }
        }
      }

      if (location.href) {
        isLocalHost = location.href.indexOf("localhost") > -1 ? true : false;
        if (isLocalHost) {
          originUrl = "http://extremetutors.et" + location.pathname + location.search;
          currentAgencyDomain = "http://extremetutors.et";
        }
      }
      hostName = CommonServices.getDomainFromOrigin(originUrl);

      // console.log(originUrl, "originUrl new");
      // console.log(currentAgencyDomain, "currentAgencyDomain");
      // console.log(hostName, "hostName  ");
      // must be deleted to use agwncies data

      if (hostName == "job.et") {
        // console.log("Must Not redirect");
        await getAgentDataForWebsite_byWebsiteDomain(currentAgencyDomain);
      } else if (isLocalHost && currentAgencyDomain) {
        // console.log("Must Not redirect, it is localhost");
        await getAgentDataForWebsite_byWebsiteDomain(currentAgencyDomain);
      } else {
        let newUrl = originUrl.replace(hostName, "job.et");
        let newAgencyDomain = location.search ? "&" : "?";
        newAgencyDomain =
          location.search + newAgencyDomain + "currentAgencyDomain=" + locationOrigin;
        newhref = newUrl + newAgencyDomain;
        // console.log("Must redirect ", newhref);
        // window.open(newhref);
        window.location.assign(newhref);
      }
    } catch (error) {
      // console.log("error", error);
    }
  }, []);

  const getStarted = async () => {
    // getWebsiteContentTypes()
    try {
      let isUserLoggedInD = await UseAuth.verifyUser();
      setIsUserLoggedIn(isUserLoggedInD);
      //////////////////////////////
      getEmploymentSalaryDealTypes();
      getEmploymentJobLocationTypes();
      getEducationLevels();
      getEmploymentTypes();
      getJobs();
      //////////////////////////////

      if (isUserLoggedInD) {
        getMyJobSeekerInformation(); 
        getCurrentUser();
        getMyAgencies();
        getMyJobApplications();
        // getUserFiles();
      }

      getRecentEmploymentJobVacancies();
      getPopularEmploymentAgencies();
      getNewJobSeekers()
      // await getAgentDataForWebsite_byWebsiteDomain();
      //  agencyDataD2=await getAgentDataForWebsite_byId(agentId)
    } catch (error) {}
  };

  // Initialize App Data
  // Initialize App Data
  const initializeAppData = async (agencyDataD) => {
    try {
      let GAMeasurementId = "G-9WH6WP5G6X"; //Baally jobs GA ID////G-9WH6WP5G6X
      let agencyId = null;
      let agencyWebsiteContentsData = {};
      let isUserLoggedInD = await UseAuth.verifyUser();
      if (agencyDataD) {
        //agency only data
        if (agencyDataD.id) {
          agencyId = agencyDataD.id;
          getEmploymentAgencyPaymentAccounts(agencyDataD.id);
          if (agencyDataD.agencyWebsiteContents) {
            agencyWebsiteContentsData = agencyDataD.agencyWebsiteContents;
            agencyWebsiteContentsData = JSON.parse(agencyWebsiteContentsData);
          }
          if (agencyDataD.googleAnalyticsMeasurementId) {
            if (
              agencyDataD.googleAnalyticsMeasurementId != null &&
              agencyDataD.googleAnalyticsMeasurementId == ""
            ) {
              // GAMeasurementId = agencyDataD.googleAnalyticsMeasurementId;
            }
          }
          if (isUserLoggedInD) {
            getMyJobSeekerInformation(agencyDataD);
          }

          initializeWebsiteMetaDatas(agencyDataD);
          getPaymentMethods();
          getNewJobVacancies(agencyId);
          getAgencyNewJobSeekers(agencyId);
        }
      }

      setAgencyWebsitePagesContent(agencyWebsiteContentsData);

      if (GAMeasurementId) {
        ReactGA.initialize(GAMeasurementId);
      }

      // getWebsiteContentTypes()
    } catch (error) {
      console.log(error, "init error ");
    }
  };
  // Initialize App Data
  // Initialize App Data

  const initializeWebsiteMetaDatas = (initData) => {
    try {
      if (initData) {
        if (initData.favIcon) {
          // console.log(initData,"init assign")
          // websiteFavIcon
          let hd = document.getElementById("htmlheadtag1");
          let itm = document.createElement("link");
          itm.id = "whatever";
          itm.rel = "shortcut icon";
          itm.type = "image/png";
          itm.href = initData.favIcon;
          hd.appendChild(itm);
          // console.log("head tags",hd)
        }
      }
    } catch (error) {
      // console.log("head tags",error)
    }
  };

  const getCurrentUser = () => {
    let mydetails;
    getDocumentsAll("mydetails", "/api/auth/mydetails").then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setCurrentUser(mydetails);
      }
      // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    });
  };

  const getEmploymentAgencyPaymentAccounts = (id) => {
    let mydetails;
    let agentId = id;
    if (!id) {
      if (agencyData) {
        if (agencyData.id) {
          agentId = agencyData.id;
        }
      }
    }
    let relativePath = "/api/employmentagencypaymentaccount/getbyfield";
    // agentId

    try {
      if (agentId) {
        let data = { employmentAgencyId: agentId };
        postDocuments("contactus", relativePath, data, {}).then((mydetailsRes) => {
          if (mydetailsRes.success) {
            mydetails = mydetailsRes.record;
            if (mydetails.length) {
              setEmploymentAgencyPaymentAccounts(mydetails);
            }
          } else {
            setEmploymentAgencyPaymentAccounts([]);
          }
          // console.log(mydetails ,jobSeekersD , "getNewJobSeekers  ")
        });
      }
    } catch (error) {
      // console.log(error , "error  ")
    }
  };

  const getRecentEmploymentJobVacancies = () => {
    let mydetails=[]; 
    
    let relativePath = "/api/employmentjobvacancy/recentEmploymentJobVacancies";
    // agentId

    try {
      // if (agentId) {
      let data = { };
      postDocuments("setRecentEmploymentJobVacancies", relativePath, data, {}).then((mydetailsRes) => {
        if (mydetailsRes.success) {
          mydetails = mydetailsRes.record;
          if (mydetails.length) {
            mydetails.reverse();
            setRecentEmploymentJobVacancies(mydetails);
          }
        } else {
          setRecentEmploymentJobVacancies(mydetails);
        }
        // console.log(mydetails , "recentEmploymentJobVacancies  ")
      });
      // }
    } catch (error) {
      // console.log(error , "error  recentEmploymentJobVacancies")
    }
  };

  const getNewJobVacancies = (id) => {
    let mydetails;
    let agentId = id;
    if (!id) {
      if (agencyData) {
        if (agencyData.id) {
          agentId = agencyData.id;
        }
      }
    }
    let relativePath = "/api/employmentjobvacancy/getbyfield";
    // agentId

    try {
      // if (agentId) {
      let data = { employmentAgencyId: agentId };
      postDocuments("contactus", relativePath, data, {}).then((mydetailsRes) => {
        if (mydetailsRes.success) {
          mydetails = mydetailsRes.record;
          if (mydetails.length) {
            mydetails.reverse();
            setEmploymentAgencyJobVacancies(mydetails);
          }
        } else {
          setEmploymentAgencyJobVacancies([]);
        }
        // console.log(mydetails , "setEmploymentAgencyJobVacancies  ")
      });
      // }
    } catch (error) {
      // console.log(error , "error  ")
    }
  };

  const getAgencyNewJobSeekers = (id) => {
    let mydetails;
    let agentId = id;
    if (!id) {
      if (agencyData) {
        agentId = agencyData.id;
      }
    }
    let jobSeekersD = [];
    let relativePath = "/api/employmentjobseeker/search";
    // agentId
    let data = {
      jobSeeker: {},
      job: {}, //jobId:223
      agencyJobSeeker: { employmentAgencyId: agentId },
    };
    postDocuments("contactus", relativePath, data, {}).then((mydetailsRes) => {
      try {
        if (mydetailsRes.success) {
          mydetails = mydetailsRes.record;

          if (mydetails.length) {
            mydetails = groupArray(mydetails, "id");
            mydetails = Object.entries(mydetails);
            if (mydetails.length) {
              mydetails.forEach((element) => {
                let keyValue = element;
                let allValue = [];
                let value = {};
                if (keyValue.length) {
                  allValue = keyValue[1];
                  if (allValue.length) {
                    value = allValue[0];
                    // console.log( allValue[0] ,keyValue[0])
                    // value.allWorkExperiances=allValue
                    if (value) {
                      jobSeekersD.push(value);
                    }
                  }
                }
              });
            }
            jobSeekersD.reverse();
            setAgencyNewJobSeekers(jobSeekersD);
          }
        } else {
          setAgencyNewJobSeekers([]);
        }
        // console.log( jobSeekersD , "get agency NewJobSeekers  ")
      } catch (error) {
        // console.log(error , "error  ")
      }
    });
  };

  const getNewJobSeekers = () => {
    let mydetails;  
    let jobSeekersD = [];
    let relativePath = "/api/employmentjobseeker/newJobSeekers";
    // agentId
    let data = {
      jobSeeker: {},
      job: {}, //jobId:223
      agencyJobSeeker: { },
    };
    postDocuments("contactus", relativePath, data, {}).then((mydetailsRes) => {
      try {
        if (mydetailsRes.success) {
          mydetails = mydetailsRes.record;

          if (mydetails.length) {
            mydetails = groupArray(mydetails, "id");
            mydetails = Object.entries(mydetails);
            if (mydetails.length) {
              mydetails.forEach((element) => {
                let keyValue = element;
                let allValue = [];
                let value = {};
                if (keyValue.length) {
                  allValue = keyValue[1];
                  if (allValue.length) {
                    value = allValue[0];
                    // console.log( allValue[0] ,keyValue[0])
                    // value.allWorkExperiances=allValue
                    if (value) {
                      jobSeekersD.push(value);
                    }
                  }
                }
              });
            }
            jobSeekersD.reverse();
            setNewJobSeekers(jobSeekersD);
          }
        } else {
          setNewJobSeekers([]);
        }
        // console.log( jobSeekersD , "getNewJobSeekers  ")
      } catch (error) {
        // console.log(error , "error  ")
      }
    });
  };

  const getAgentDataForWebsite_byId = async (agentId) => {
    let mydetails = null;
    let agencyWebsiteContentsData;
    await getDocumentsAll(
      "myjobseekerinformation",
      "/api/employmentagency/getagentdataforwebsite/" + agentId
    ).then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setAgencyData(mydetails);
        if (mydetails.agencyWebsiteContents) {
          agencyWebsiteContentsData = mydetails.agencyWebsiteContents;
          agencyWebsiteContentsData = JSON.parse(agencyWebsiteContentsData);
          setAgencyWebsitePagesContent(agencyWebsiteContentsData);
        }
      } else {
        setAgencyData(null);
        setAgencyWebsitePagesContent(null);
      }
      //  console.log( mydetails, "getAgentDataForWebsite")
      // console.log( agencyWebsiteContentsData, "agency Website ContentsData   ")
    });
    return mydetails;
  };

  const getAgentDataForWebsite_byWebsiteDomain = async (locationHostName) => {
    let mydetails = null;
    let originUrl = locationHostName;
    // if (!locationHostName) {
    //   originUrl = "http://extremetutors.et"; ///
    // }
    try {
      if (originUrl) {
        await postDocuments(
          "myjobseekerinformation",
          "/api/employmentagency/getagentdatabywebsitedoamin",
          { originUrl: originUrl }
        ).then((mydetailsRes) => {
          if (mydetailsRes.success) {
            mydetails = mydetailsRes.record;
          }
        });
      }
    } catch (error) {}
    setIsAgentWebsite(mydetails ? true : false);
    setAgencyData(mydetails);
    initializeAppData(mydetails);
    return mydetails;
  };

  const getMyJobSeekerInformation = (agencyDataDDD) => {
    let mydetails;
    getDocumentsAll(
      "myjobseekerinformation",
      "/api/employmentjobseeker/myjobseekerinformation"
    ).then((mydetailsRes) => {
      setMyJobSeekerInformation(null);
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setMyJobSeekerInformation(mydetails);
        let agencyDataD = agencyDataDDD || agencyData;
        if (agencyDataD) {
          if (agencyDataD.id) {
            // let dataaa = {jobseekerId:mydetails.id,employmentAgencyId:agencyDataD.id}
            getMyAgencyJobSeekerInformation(mydetails, agencyDataD);
            getMyJobSeekerAndAgencies(mydetails);
          }
        }
      }
      // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    });
  };

  const getMyJobSeekerAndAgencies = (jobseekerD) => { 
    let jobseekerDD = jobseekerD || myJobSeekerInformation;
    let dataa = { jobseekerId: jobseekerDD.id };
    // console.log(" location.search", dataa);
    let res = [];
    try {
      if ( jobseekerDD) {
        let relativePath = "/api/employmentagencyjobseeker/getbyfield";
        postDocuments("employmentagencyjobseeker", relativePath, dataa).then(
          (AgencyJobSeekerInformation) => {
            if (AgencyJobSeekerInformation.success) {
              if (AgencyJobSeekerInformation.record) {
                if (AgencyJobSeekerInformation.record.length) {
                  let agencyJobSeekerInformationD = AgencyJobSeekerInformation.record;
                  if (agencyJobSeekerInformationD) {
                    res = agencyJobSeekerInformationD;
                  }
                }
              }
            }
            setMyJobSeekerAndAgencies(res);
          }
        );
      }
      // setLoading(false);
    } catch (error) {}
  };

  const getMyAgencyJobSeekerInformation = (jobseekerD, agencyDataD) => {
    let agencyDataDD = agencyDataD || agencyData;
    let jobseekerDD = jobseekerD || myJobSeekerInformation;

    let dataa = { jobseekerId: jobseekerDD.id, employmentAgencyId: agencyDataDD.id };
    // console.log(" location.search", dataa);
    let res = null;
    try {
      if (agencyDataDD && jobseekerDD) {
        let relativePath = "/api/employmentagencyjobseeker/getbyfield";
        postDocuments("employmentagencyjobseeker", relativePath, dataa).then(
          (AgencyJobSeekerInformation) => {
            if (AgencyJobSeekerInformation.success) {
              if (AgencyJobSeekerInformation.record) {
                if (AgencyJobSeekerInformation.record.length) {
                  let agencyJobSeekerInformationD = AgencyJobSeekerInformation.record[0];
                  if (agencyJobSeekerInformationD) {
                    res = agencyJobSeekerInformationD;
                  }
                }
              }
            }
            setMyAgencyJobSeekerInformation(res);
          }
        );
      }
      // setLoading(false);
    } catch (error) {}
  };

  const getPaymentMethods = () => {
    try {
      let mydetails;
      getDocumentsAll("paymentMethods", "/api/paymentmethod/all").then((mydetailsRes) => {
        if (mydetailsRes.success) {
          mydetails = mydetailsRes.record;
          setPaymentMethods(mydetails);
        } else {
          setPaymentMethods(null);
        }
        // console.log( mydetails, "getCurrentUser  CurrentUserContext")
      });
    } catch (error) {}
  };

  const getMyJobApplications = () => {
    try {
      let mydetails;
      getDocumentsAll("myJobApplications", "/api/employmentjobseeker/myjobapplications").then(
        (mydetailsRes) => {
          if (mydetailsRes.success) {
            mydetails = mydetailsRes.record;

            mydetails.reverse();
            setMyJobApplications(mydetails);
          } else {
            setMyJobApplications([]);
          }
          // console.log( mydetails, "getCurrentUser  CurrentUserContext")
        }
      );
    } catch (error) {}
  };

  const getMyAgencies = () => {
    try {
      let mydetails = [];
      getDocumentsAll("myjobseekerinformation", "/api/employmentjobseeker/agenciesijoined").then(
        (mydetailsRes) => {
          if (mydetailsRes.success) {
            if (mydetailsRes.record) {
              mydetails = mydetailsRes.record;
            }
          }
          if (mydetails.length) {
            mydetails.reverse();
          }
          setMyAgencies(mydetails);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getEmploymentTypes = () => {
    try {
      let mydetails;
      getDocumentsAll("mydetails", "/api/employmenttype/all").then((mydetailsRes) => {
        if (mydetailsRes.success) {
          mydetails = mydetailsRes.record;
          setEmploymentTypes(mydetails);
        } else {
          setEmploymentTypes([]);
        }
        // console.log( mydetails, "setEmploymentTypes  setEmploymentTypes")
      });
    } catch (error) {}
  };

  const getPopularEmploymentAgencies = () => {
    try {
      let mydetails;
      // console.log( mydetails, "getPopularEmploymentAgencies 111 getPopularEmploymentAgencies")
      getDocumentsAll("mydetails", "/api/employmentagency/popularemploymentagencies").then(
        (mydetailsRes) => {
          if (mydetailsRes.success) {
            mydetails = mydetailsRes.record;
            setPopularEmploymentAgencies(mydetails);
          } else {
            setPopularEmploymentAgencies([]);
          }
          // console.log( mydetails, "getPopularEmploymentAgencies  getPopularEmploymentAgencies")
        }
      );
    } catch (error) {}
  };

  const getEmploymentSalaryDealTypes = () => {
    let mydetails;
    getDocumentsAll("mydetails", "/api/employmentsalarydealtype/all").then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setEmploymentSalaryDealTypes(mydetails);
      } else {
        setEmploymentSalaryDealTypes([]);
      }
      // console.log( mydetails, "employmentSalaryDealTypes    ")
    });
  };

  const getEmploymentJobLocationTypes = () => {
    let mydetails;
    getDocumentsAll("mydetails", "/api/employmentJobLocationType/all").then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setEmploymentJobLocationTypes(mydetails);
      } else {
        setEmploymentJobLocationTypes([]);
      }
      // console.log( mydetails, "employmentSalaryDealTypes    ")
    });
  };

  const getEducationLevels = () => {
    let mydetails;
    getDocumentsAll("mydetails", "/api/educationLevel/all").then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setEducationLevels(mydetails);
      } else {
        setEducationLevels([]);
      }
      // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    });
  };

  const getJobs = () => {
    getDocumentsAll("job", "/api/job/all").then((websiteUserTypesRes) => {
      //  console.log("currentloggedwebsiteRes",currentloggedwebsiteRes)
      if (websiteUserTypesRes.success) {
        if (websiteUserTypesRes.record) {
          setJobs(websiteUserTypesRes.record);
        }
      } else {
        setJobs([]);
      }
    });
  };

  const getUserFiles = () => {
    let fileRelativePath = "/api/file/allFoldersAndFiles_user";
    getDocumentsAll("getphotosalbum", fileRelativePath).then((resdata) => {
      if (resdata.success) {
        setUserFiles(resdata.record);
      } else {
        setUserFiles({});
      }
      // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    });
  };

  const getWebsiteFiles = () => {
    let fileRelativePath = "/api/file/allFoldersAndFiles";
    getDocumentsAll("getphotosalbum", fileRelativePath).then((resdata) => {
      if (resdata.success) {
        setWebsiteFiles(resdata.record);
      } else {
        setWebsiteFiles({});
      }
      // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    });
  };

  const getDocumentsAll = async (documentName, relativePath, additionalData) => {
    let responseD = { success: false, message: "", record: null };

    try {
      let documentsResponse = { success: false };
      ////////// UPDATED/////////////
      documentsResponse = await HttpService.getService(
        relativePath,
        additionalData ? additionalData : {},
        ""
      );
      if (documentsResponse.data) {
        responseD.record = documentsResponse.data.record;
        responseD.message = documentsResponse.data.message;
      }
      if (documentsResponse.success) {
        responseD.success = true;  
      }
      setForceRerender(!forceRerender);
    } catch (error) {
      console.log(error);
    }
    return responseD;
    ////////// UPDATED/////////////
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    try {
      let documentsResponse = { success: false };
      // setLoading(true);

      ////////// UPDATED/////////////
      documentsResponse = await HttpService.postService(relativePath, data, {}, "");

      if (documentsResponse.data) {
        responseD.record = documentsResponse.data.record;
        responseD.message = documentsResponse.data.message;
      }
      if (documentsResponse.success) {
        responseD.success = true;
      }
      setForceRerender(!forceRerender);
    } catch (error) {}
    return responseD;
    ////////// UPDATED/////////////
  };

  async function updateCurrentUser(variableName) {
    if (variableName) {
      if (variableName == "currentUser") {
        getCurrentUser();
      }
      if (variableName == "myAgencyJobSeekerInformation") {
        getMyAgencyJobSeekerInformation();
      }
      if (variableName == "myJobSeekerAndAgencies") {
        getMyJobSeekerAndAgencies();
      }
      if (variableName == "myAgencies") {
        getMyAgencies();
      }
      if (variableName == "myJobApplications") {
        getMyJobApplications();
      }
      if (variableName == "newJobSeekers") {
        getNewJobSeekers();
      }
      if (variableName == "agencyNewJobSeekers") {
        getAgencyNewJobSeekers();
      }
      if (variableName == "paymentMethods") {
        getPaymentMethods();
      }
      if (variableName == "employmentAgencyPaymentAccounts") {
        getEmploymentAgencyPaymentAccounts();
      }
      if (variableName == "recentEmploymentJobVacancies") {
        getRecentEmploymentJobVacancies();
      }
      if (variableName == "employmentAgencyJobVacancies") {
        getNewJobVacancies();
      }
      if (variableName == "websiteFiles") {
        getWebsiteFiles();
      }
      if (variableName == "userFiles") {
        getUserFiles();
      }
      if (variableName == "popularEmploymentAgencies") {
        getPopularEmploymentAgencies();
      }
      if (variableName == "employmentTypes") {
        getEmploymentTypes();
      }
      if (variableName == "employmentJobLocationTypes") {
        getEmploymentJobLocationTypes();
      }
      if (variableName == "employmentSalaryDealTypes") {
        getEmploymentSalaryDealTypes();
      }
      if (variableName == "jobs") {
        getJobs();
      }
      if (variableName == "myJobSeekerInformation") {
        getMyJobSeekerInformation();
      }
    } else {
      getStarted();
    }
    // await getDocumentsAll("allOffices","/api/officeUserRole/myoffices",{})
    // await getDocumentsAll("currentloggedwebsite","/api/auth/currentloggedwebsite")
  }

  return (
    <CurrentUserContext.Provider
      value={[
        {
          paymentMethods: paymentMethods,
          employmentAgencyPaymentAccounts: employmentAgencyPaymentAccounts,
          employmentAgencyJobVacancies: employmentAgencyJobVacancies,
          recentEmploymentJobVacancies:recentEmploymentJobVacancies,
          myAgencies: myAgencies,
          myJobApplications: myJobApplications,
          myAgencyJobSeekerInformation: myAgencyJobSeekerInformation,
          myJobSeekerAndAgencies: myJobSeekerAndAgencies,
          newJobSeekers: newJobSeekers,
          agencyNewJobSeekers: agencyNewJobSeekers,
          websitePagesContent: websitePagesContent,
          agencyWebsitePagesContent: agencyWebsitePagesContent,
          isAgentWebsite: isAgentWebsite,
          agencyData: agencyData,
          educationLevels: educationLevels,
          employmentSalaryDealTypes: employmentSalaryDealTypes,
          popularEmploymentAgencies: popularEmploymentAgencies,
          employmentTypes: employmentTypes,
          employmentJobLocationTypes: employmentJobLocationTypes,
          isUserLoggedIn: isUserLoggedIn,
          currentUser: currentUser,
          userFiles: userFiles,
          jobs: jobs,
          websiteFiles: websiteFiles,
          forceRerenderContext: forceRerender,
          myJobSeekerInformation: myJobSeekerInformation,
        },
        updateCurrentUser,
      ]}
      {...props}
    />
  );
}

function useCurrentUser() {
  //for pages that want to access the context object's value
  //custom hook use...
  try {
    const context = useContext(CurrentUserContext);
    // console.log('  inside the Provider',context);
    // if (!context) throw new Error('Not inside the Provider');
    return context; // [fav, updateFav]
  } catch (err) {}
}

export { useCurrentUser, CurrentUserProvider };
