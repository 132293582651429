// @mui material components
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import queryString from "query-string";

import PropTypes from "prop-types";
import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect, useRef } from "react";
import { HttpService } from "Api-Services/httpService";

import ReactGA from "react-ga4";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { 
  Box,
  Button,  
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, 
  Skeleton, 
  Tab, 
  Tabs,
  Typography,
} from "@mui/material"; 
import { 
  ArrowForwardIosSharp 
} from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices";
import { isMobile } from "react-device-detect";

import { makeStyles } from "@mui/styles";
import { Navigation, Pagination } from "swiper";
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

import "./swiperStyles.css";

// import 'swiper/css/pagination';
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { DataManagementService } from "Api-Services/dataManagementService";
import JobShortInfoBox from "../Job/sections/JobShortInfoBox";

const useStylesSwiper = makeStyles((theme) => ({
  swiperStyle: {
    "& .swiper": {
      width: "100%",
      paddingTop: "5px !important",
      paddingBottom: "50px",
    },
    "& .swiper-slide": {
      backgroundPosition: "center",
      backgroundSize: "cover",
      width: "300px",
      maxHeight: "300px",
    },
    "& .swiper-slide img": {
      display: "block",
      width: "100%",
    },
  },

  swiperPaginationNavigationStyle: {
    "& .swiper": {
      width: "100%",
      height: " 100%",
    },

    "& .swiper-slide": {
      // textAlign: "center",
      fontSize: "18px",
      background: "#fff",

      /* Center slide text vertically */
      display: "-webkit-box",
      display: "-ms-flexbox",
      display: "-webkit-flex",
      display: "flex",
      // -webkit-box-pack: "center",
      // -ms-flex-pack: "center",
      // -webkit-justify-content: "center",
      // justify-content: "center",
      // -webkit-box-align: "center",
      // -ms-flex-align: "center",
      // -webkit-align-items: "center",
      // align-items: "center",
    },

    "& .swiper-slide img": {
      display: "block",
      width: " 100%",
      height: "100%",
      objectFit: "cover",
    },

    "& .swiper": {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  swiperEffectFlipStyle: {
    "& .swiper": {
      width: "100%",
      height: " 100%",
      // width: "300px",
      // maxHeight: "500px",
      padding: "50px",
    },
    "& .swiper-slide": {
      backgroundPosition: "center",
      backgroundSize: "cover",
      width: "300px",
      maxHeight: "500px",
    },
    "& .swiper-slide img": {
      display: "block",
      width: "100%",
    },
  },
 
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0.5px solid ${theme.palette.divider}`,
  borderBottom: "0.5px darkgreen solid",
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, px: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
 

function HomeRecentJobsView() {
  // console.log("cities", cities);

  const swiperStyles = useStylesSwiper();  

  const navigateComp = useNavigate();

  const [currentData, updateContext] = useCurrentUser();

  const {
    employmentTypes,
    employmentJobLocationTypes,
    employmentSalaryDealTypes, 
    employmentAgencyJobVacancies,
    recentEmploymentJobVacancies, 
    jobs,
    agencyData, 
  } = currentData;
 
  const [agencyJobs, setAgencyJobs] = React.useState(null);
  const [jobsToChoose, setJobsToChoose] = React.useState(jobs);
  const [forceRenderer, setForceRenderer] = React.useState(false);
  const [searchExpanded, setSearchExpanded] = React.useState(true); 
  const [loading, setLoading] = React.useState(false);
  const [searchResult, setSearchResult] = React.useState(null); 
  const [forceRerender, setForceRerender] = React.useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [searchedKeyword, setSearchedKeyword] = React.useState("");
  const [isFirstTimeSearch, setIsFirstTimeSearch] = React.useState(true);
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [jobOverview, setJobOverview] = React.useState({});

  const viewDetails = (id, data) => {
    DataManagementService.setLocalstorageDataSecured(data, "jobDetail");
    let path = "/pages/jobs/job-details?id=" + id; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
    navigateComp(path);
  };

  useEffect(() => {
    // console.log("Home RecentJobsView ------------------------------------------")
  });

  useEffect(() => { 
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch) {
        if (locationSearch.search) {
          searchByKeyword(locationSearch.search);
        }
      }
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    // console.log("agencyData",agencyData)
    let filterdJobs = [];
    if (agencyData) {
      if (agencyData.jobsId) {
        let allagencyjobs = agencyData.jobsId.split(",");
        filterdJobs = jobs.filter((jobD) => {
          let res = false;
          allagencyjobs.forEach((element) => {
            element = parseInt(element);
            if (element) {
              if (element == jobD.id) {
                res = true;
              }
            }
          });
          return res;
        });
        setAgencyJobs(filterdJobs);
        setJobsToChoose(filterdJobs);
      }
    }
    setForceRenderer(!forceRenderer);
  }, [agencyData, jobs]);

  const incrementField = async (id, fieldName, updateBy) => {
    try {
      if (id && fieldName) {
        let relativePath = "/api/employmentjobvacancy/incrementfieldbyid";
        let data = {
          field: fieldName,
          id: id,
          updateBy: updateBy || 1,
        };
          await postDocuments("contactus", relativePath, data); 
      }
      // setLoading(false);
    } catch (error) {}
  };

  const searchByKeyword = async (searchD) => {
    let pathSearch;
    let searchKeywordD = "";
    try {
      if (searchD) {
        let pathSearchData = CommonServices.decryptLocalData(searchD);
        // console.log("pathSearchData", pathSearchData);
        searchKeywordD = pathSearchData.keyword;
        setSearchKeyword(searchKeywordD); 
      } else {
        searchKeywordD = searchKeyword;
        if (searchKeyword) {
          // jobSearchData = jobSearchFieldsandValue;
          pathSearch = { keyword: searchKeywordD };
          pathSearch = CommonServices.encryptLocalData(pathSearch);
          let path = "/pages/jobs?search=" + pathSearch; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
          navigateComp(path);
        }
      }

      let data = {
        keyword: searchKeywordD,
      };

      if (agencyData.id) {
        data.employmentAgencyId = agencyData.id;
      }

      try {
        setIsFirstTimeSearch(false);
        // let relativePath = "/api/employmentagencyjobseeker/search";
        let relativePath = "/api/employmentjobvacancy/getmyagencyjobvacanciesbykeyword";

        setLoading(true);

        // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
        let contactUsRes = await postDocuments("contactus", relativePath, data, {});
        setSearchedKeyword(searchKeywordD);
        setLoading(false); 
        let searchResultD = [];
        if (contactUsRes.success) {
          if (contactUsRes.record) {
            if (contactUsRes.record.length) {
              searchResultD = contactUsRes.record;
              setSearchExpanded(false);
            }
          }
        }

        if (contactUsRes.success) {
          ReactGA.event({
            category: "job search",
            action: "job search found",
            label: searchKeywordD, // optional
            value: contactUsRes.record ? contactUsRes.record.length : 0, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        } else {
          ReactGA.event({
            category: "job search",
            action: "job search not found",
            label: searchKeywordD, // optional
            value: 0, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        }
        setSearchResult(searchResultD);
      } catch (error) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const parseDate = (dt) => {
    let d = new Date(dt);
    let m = d.getMonth() + 1;
    let dateD = m + "/" + d.getDate() + "/" + d.getFullYear();
    return dateD;
  };

  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = returnField?"":{};
    console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = "";
                if (row[returnField]) {
                  rowField = row[returnField];
                }
                res = rowField;
              }
            }
          }
        }
      } 
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false }; 
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
 
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true; 
    } 
    return responseD; 
  };

  const truncateString = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          let btn = lableName ? (
            <Button
              variant="text"
              onClick={() => {
                setMoreText({ content: str, title: lableName });
                setOpenedDialog("moreText");
                setForceRerender(!forceRerender);
              }}
            >
              {"View more"}
            </Button>
          ) : null;
          let txt = str.slice(0, num) + "...";
          res = (
            <>
              {parse(txt)} {btn}
            </>
          );
        } else {
          res = <>{parse(res)}</>;
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };

  function JobList() {
    const [jobsData, setJobsData] = React.useState([]);
    const [selectedAgencyTabIndex, setSelectedAgencyTabIndex] = React.useState(0);

    const AgencyChooseTab=()=> {
      return (
        <>
          {agencyData && (
            <Container sx={{ px: 0, mx: 0 }}>
              <MKBox
              // sx={{ borderBottom: 1, borderColor: 'divider' }}
              >
                <Tabs
                  value={selectedAgencyTabIndex}
                  onChange={(e, v) => {
                    setSelectedAgencyTabIndex(v);
                  }}
                  aria-label="basic tabs example"
                >
                  <Tab label="All" />
                  <Tab label={agencyData.fullName} />
                </Tabs>
              </MKBox>
            </Container>
          )}
        </>
      );
    }

    useEffect(() => {
      try {
        let recentJobs = [];
         if(agencyData){
        if (selectedAgencyTabIndex == 0) {
          recentJobs=recentEmploymentJobVacancies;
        } else {
          recentJobs=employmentAgencyJobVacancies;
        }}
        else{
          recentJobs=recentEmploymentJobVacancies;
        }
        // console.log("recentEmploymentJobVacancies", recentEmploymentJobVacancies);
        // console.log("employmentAgencyJobVacancies", employmentAgencyJobVacancies);
        let jobsDataDD = []; 
          if (recentJobs) {
            if (recentJobs.length) {
              jobsDataDD = recentJobs.filter(
                (JV) =>
                  !JV.isJobClosed && new Date(JV.deadLineDateTime).getTime() > new Date().getTime()
              );
              setJobsData(jobsDataDD);
            }
          }
          // console.log("jobsDataDD", jobsDataDD); 
      } catch (error) {
          // console.log("jobsDataDD error", error);
         }
    }, [recentEmploymentJobVacancies, employmentAgencyJobVacancies,agencyData, selectedAgencyTabIndex]);

    try {
      return (
        <>
          {(jobsData ? (jobsData.length > 0 ? true : false) : false) && (
            <Container>
              <Grid
                // spacing={2}
                // sx={{ marginBottom: "-39px" }}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item>
                  <MKTypography variant={"h4"}>Recent Jobs</MKTypography>
                </Grid>
                <Grid item>
                  <Button component={Link} variant="text" to={"/pages/jobs"}>
                    See all
                  </Button>
                </Grid>
              </Grid>
              <AgencyChooseTab />

              <div className={swiperStyles.swiperPaginationNavigationStyle}>
                <Swiper
                  slidesPerView={isMobile ? 1 : 3}
                  spaceBetween={10}
                  // loop={true}
                  // pagination={{
                  //   clickable: true,
                  // }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 15,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                  }}
                >
                  {jobsData.map((jobVacancy) => {
                    if (true) {
                      return (
                        <SwiperSlide style={{ width: "auto" }}> 
                        <JobShortInfoBox jobVacancy={jobVacancy}/>  
                        </SwiperSlide>
                      );
                    }
                  })}
                </Swiper>
              </div>
            </Container>
          )}
        </>
      );
    } catch (error) {
      console.log(error, "error vjobsDataDD. ");
      return <></>;
    }
  }
 

  const jobsLoading = (num) => {
    try {
      return (
        <>
          {/* {<Stack spacing={1}> 
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> 
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={60} />
            <Skeleton variant="rounded" width={210} height={60} />
          </Stack>
} */}

          <div style={{ marginBottom: "10px" }}>
            {[...Array(num ? num : 5)].map(() => (
              <div style={{ marginBottom: "10px" }}>
                <Accordion expanded={false}>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    {/* <MKBox component="section"> */}
                    {/* <Container> */}
                    <Grid container>
                      <Grid item xs={12} lg={10} sx={{ mt: 2 }}>
                        <Grid
                          container
                          spacing={{ xs: 0, lg: 0 }}
                          // sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}
                        >
                          <Grid item xs={12} lg={4} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="h6"
                              fontWeight="bold"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="rectangular" width={210} height={30} />
                            </MKTypography>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={250} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                          </Grid>

                          <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={150} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                            {/* <Autocomplete
                              // defaultValue="High School"
                              onChange={(e, val) => { 
                                  // setCurrentEducationLevelId(val.id);
                                handleSearchFieldsandValue("currentEducationLevelId",val?val.id:"Any","jobseeker")}
                              }  
                              options={[...[{title:"Any",id:"Any"}], ...educationLevels]}
                              defaultValue={getEducationLevelById(jobSeekerSearchFieldsandValue.currentEducationLevelId, "single")}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => <MKInput {...params} variant="standard" />}
                            /> */}

                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                          </Grid>

                          <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={150} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                            {/* <Autocomplete
                            onChange={(e, val) => {
                              // setSex(val);
                              handleSearchFieldsandValue("sex",val?val:"Any","jobseeker")

                            }}
                            value={jobSeekerSearchFieldsandValue.sex}
                            options={["Any", "male", "female"]}
                            getOptionLabel={(option) =>  option }
                            renderInput={(params) => <MKInput {...params} variant="standard" placeholder="select gender"/>}
                          /> */}
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                          </Grid>
                          <Grid item xs={12} lg={2} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={150} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                            {/* <Autocomplete
                            onChange={(e, val) => {
                              // setSex(val);
                              handleSearchFieldsandValue("sex",val?val:"Any","jobseeker")

                            }}
                            value={jobSeekerSearchFieldsandValue.sex}
                            options={["Any", "male", "female"]}
                            getOptionLabel={(option) =>  option }
                            renderInput={(params) => <MKInput {...params} variant="standard" placeholder="select gender"/>}
                          /> */}
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />{" "}
                            </MKTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* </Container> */}
                    {/* </MKBox> */}
                  </AccordionSummary>
                  <AccordionDetails></AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </>
      );
    } catch (error) {
      return <></>;
    }
  };
 
  return (
    <>  
          <JobList />
          {forceRenderer}
          <Dialog
            open={openedDialog == "moreText"}
            onClose={() => {
              setOpenedDialog("");
            }}
          >
            {moreText && (
              <>
                {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
                {moreText.content && (
                  <DialogContent>
                    <DialogContentText>{parse(moreText.content)}</DialogContentText>
                  </DialogContent>
                )}
              </>
            )}
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenedDialog("");
                }}
              >
                close
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openedDialog == "jobOverview"}
            onClose={() => {
              setOpenedDialog("");
            }}
          >
            {jobOverview && (
              <>
                {jobOverview.title && <DialogTitle>{parse(jobOverview.title)}</DialogTitle>}
                {jobOverview.id && (
                  <DialogContent>
                    {/* <DialogContentText>{parse(jobOverview.content)}</DialogContentText>
                     */}

                    <MKBox ml={4}>
                      <MKTypography variant="h6" color="text">
                        Overview:
                      </MKTypography>
                      <MKBox ml={1}>
                        <MKTypography variant="subtitle2" fontWeight="light" color="text">
                          Employment type :
                          <strong style={{ fontWeight: "430" }}>
                            {" "}
                            {getDataFromArray(
                              employmentTypes,
                              "id",
                              jobOverview.employmentTypeId,
                              "single",
                              "title"
                            )}
                          </strong>
                        </MKTypography>
                        <MKTypography variant="subtitle2" fontWeight="light" color="text">
                          Salary deal :{" "}
                          <strong style={{ fontWeight: "430" }}>
                            {getDataFromArray(
                              employmentSalaryDealTypes,
                              "id",
                              jobOverview.salaryDealTypeId,
                              "single",
                              "title"
                            )}
                          </strong>
                        </MKTypography>
                        <MKTypography variant="subtitle2" fontWeight="light" color="text">
                          Gender :
                          <strong style={{ fontWeight: "430" }}>
                            {" "}
                            {jobOverview.applicantGender}
                          </strong>
                        </MKTypography>
                        <MKTypography variant="subtitle2" fontWeight="light" color="text">
                          Experiance :{" "}
                          <strong style={{ fontWeight: "430" }}>
                            {(jobOverview.minimumExperianceInYear != null
                              ? "starting from " + jobOverview.minimumExperianceInYear + " "
                              : "") +
                              (jobOverview.maximumExperianceInYear >
                              jobOverview.minimumExperianceInYear
                                ? " to " + jobOverview.maximumExperianceInYear
                                : "") +
                              " year(s) "}
                          </strong>
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    <MKBox ml={4} mt={2}>
                      <MKTypography variant="h6" color="text">
                        Job Description:
                      </MKTypography>
                      <MKBox ml={1}>
                        <MKTypography variant="subtitle2" fontWeight="light" color="text">
                          {parse(jobOverview.description)}
                        </MKTypography>
                      </MKBox>
                      <Button
                        size="small"
                        // variant={Link}
                        // disabled={loading || !professionTitle}
                        // color="success"
                        onClick={() => {
                          viewDetails(jobOverview.id, jobOverview);
                        }}
                      >
                        Read More
                      </Button>
                    </MKBox>
                  </DialogContent>
                )}
              </>
            )}
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenedDialog("");
                }}
              >
                close
              </Button>
            </DialogActions>
          </Dialog> 
      {forceRerender}
    </>
  );
}

export default HomeRecentJobsView;
