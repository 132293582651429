 
import React from "react";
import HomePopularAgenciesView from "pages/Agencies/components/homePopularAgenciesView";
import HomeJoinedAgenciesShortcutView from "pages/Agencies/components/homeJoinedAgenciesShortcutView";
import NewJobSeekers from "pages/Search/SearchEmployee/components/NewJobSeekers";
import HomeRecentJobsView from "pages/Search/SearchJobs/components/homeRecentJobsView";
import HomeAgencyDescription from "pages/Agencies/components/homeAgencyDescription";
import HomeUserEngagments from "./sections/homeUserEngagments";
import HomeHeader from "./sections/homeHeader";
function ExtremeLandingPage() { 
  return (
    <> 
      <HomeHeader/>
      <HomeJoinedAgenciesShortcutView />
      <HomeRecentJobsView />
      <HomePopularAgenciesView />
      <HomeAgencyDescription />
      <NewJobSeekers />
      <HomeUserEngagments/>

      {/* <Testimonials /> */}
      {/* <Faq /> */}
      {/* <Contact id="contact" /> */}
 
    </>
  );
}

export default ExtremeLandingPage;
