  
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid"; 
import React, { useState, useEffect } from "react";

 
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
 
import image from "assets/images/examples/blog2.jpg"; 
import { useCurrentUser } from "Api-contexts/currentUserContext"; 
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, 
} from "@mui/material";
import MKButton from "components/MKButton";
 
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect"; 
function HomeUserEngagments() {
  const navigateComp = useNavigate();
  const [currentData] = useCurrentUser();
  const {
    websitePagesContent,
    myJobSeekerInformation,
    isAgentWebsite,
    isUserLoggedIn,
    agencyData,
    myAgencies, 
  } = currentData;
  const [homePage, setHomePage] = React.useState(null);
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [openedDialog, setOpenedDialog] = React.useState(""); 
  useEffect(() => {
    // console.log("websitePagesContent",websitePagesContent)
    if (websitePagesContent) {
      if (websitePagesContent.pages) {
        if (websitePagesContent.pages.homePage) {
          if (websitePagesContent.pages.homePage.sections) {
            setHomePage(websitePagesContent.pages.homePage.sections);
          }
        } 
      }
    }
  }, [websitePagesContent]);

  const didIJoinedCurrentAgency = () => {
    let res = false;
    if (agencyData && myAgencies) {
      // console.log("agencyData&&myAgencies",agencyData,myAgencies)
      if (myAgencies.length) {
        myAgencies.forEach((element) => {
          if (agencyData.id == element.id) {
            res = true;
          }
        });
      }
    }

    return res;
  };
 
  return (
    <>  
      <Container sx={{ px: 1 }}>
        {isUserLoggedIn ? (
          <>
            {" "}
            {isAgentWebsite ? (
              <>
                {" "}
                {(!myJobSeekerInformation || !didIJoinedCurrentAgency()) && (
                  <>
                    {/* <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ mt: { xs: 0, lg: 12 } }}>
                                  <Grid item xs={12} lg={12} justifyContent="center" flexDirection="column"> */}
                    <>
                      {myJobSeekerInformation ? (
                        <>
                          {!didIJoinedCurrentAgency() && agencyData && (
                            <>
                              <MKBox component="section" py={5}>
                                <MKBox bgColor="grey-200" py={12} px={{ xs: 3, lg: 0 }}>
                                  <Grid container spacing={3}>
                                    <Grid item xs={12} xl={5} ml="auto">
                                      <MKTypography variant="h4" mb={1}>
                                        {"You did not join '" + agencyData.brandName + "' yet! "}
                                      </MKTypography>
                                      <MKTypography variant="body2" color="text">
                                        {"To start working with " +
                                          agencyData.brandName +
                                          ", you must accept the privacy policy, terms and conditions and follow the instructions."}
                                      </MKTypography>
                                      <MKTypography variant="body2" color="text" mb={3}>
                                        {
                                          "Click the button below to view the instructions and accept the rules."
                                        }
                                      </MKTypography>
                                      <Grid container spacing={2}>
                                        {/* <Grid item xs={12} sm={5}>
                                      <MKInput label="Email Here" fullWidth />
                                    </Grid> */}
                                        <Grid item xs={12} sm={4}>
                                          <MKButton
                                            component={Link}
                                            variant="gradient"
                                            color="success"
                                            fullWidth
                                            to={"/pages/agencies/join-agency"}
                                            sx={{ height: "100%" }}
                                          >
                                            {"Join " + agencyData.brandName}
                                          </MKButton>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    {!isMobile && (
                                      <Grid item xs={12} xl={5} position="relative">
                                        <MKBox
                                          component="img"
                                          src={
                                            "https://img.freepik.com/premium-photo/team-young-african-people-office-table-with-laptop_219728-4569.jpg"
                                          }
                                          alt="image"
                                          maxWidth="24.75rem"
                                          width="100%"
                                          borderRadius="lg"
                                          shadow="xl"
                                          mt={{ lg: -24 }}
                                          // display={{ md: "none", lg: "block" }}
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </MKBox>
                              </MKBox>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <MKBox component="section" py={5}>
                            <MKBox bgColor="grey-200" py={12} px={{ xs: 3, lg: 0 }}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} xl={5} ml="auto">
                                  <MKTypography variant="h4" mb={1}>
                                    Be the first to see the news
                                  </MKTypography>
                                  <MKTypography variant="body2" color="text" mb={3}>
                                    Your company may not be in the software business, but
                                    eventually, a software company will be in your business.
                                  </MKTypography>
                                  <Grid container spacing={2}>
                                    {/* <Grid item xs={12} sm={5}>
                                      <MKInput label="Email Here" fullWidth />
                                    </Grid> */}
                                    <Grid item xs={12} sm={4}>
                                      <MKButton
                                        component={Link}
                                        variant="gradient"
                                        color="success"
                                        sx={{ height: "100%" }}
                                        to={"/profile"}
                                        // sx={{ color: ({ palette: { dark } }) => dark.main }}
                                      >
                                        {"Build your career information"}
                                      </MKButton>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} xl={5} position="relative">
                                  <MKBox
                                    component="img"
                                    src={image}
                                    alt="image"
                                    maxWidth="24.75rem"
                                    width="100%"
                                    borderRadius="lg"
                                    shadow="xl"
                                    mt={-24}
                                    display={{ xs: "none", lg: "block" }}
                                  />
                                </Grid>
                              </Grid>
                            </MKBox>
                          </MKBox>
                        </>
                      )}
                    </>
                    {/* </Grid>
                        </Grid>  */}
                  </>
                )}
              </>
            ) : null}
          </>
        ) : (
          <MKBox component="section" mb={5} mt={{ xs: 0, sm: 3, lg: 2 }}>
            <MKBox bgColor="grey-200" pb={2} pt={{ xs: 0, lg: 2 }} px={{ xs: 3, lg: 0 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} xl={5} ml="auto">
                  <MKTypography variant="h4" mb={1}>
                    {"Are you a job seeker? "}
                  </MKTypography>
                  <MKTypography variant="body2" color="text" mb={3}>
                    {"Sign up to this site and get a job in your field."}
                  </MKTypography>
                  {/* <MKTypography variant="body2" color="text" mb={3}>
                        {"Click the button below to view the instructions and accept the rules." }
                      </MKTypography> */}
                  <Grid container spacing={2}>
                    {/* <Grid item xs={12} sm={5}>
                          <MKInput label="Email Here" fullWidth />
                        </Grid> */}
                    <Grid item xs={12} sm={4}>
                      <MKButton
                        component={Link}
                        variant="gradient"
                        color="success"
                        fullWidth
                        to={"/register"}
                        sx={{ height: "100%" }}
                      >
                        {"Sign up"}
                      </MKButton>
                    </Grid>
                  </Grid>
                </Grid>
                {true && (
                  <Grid item xs={12} xl={5} position="relative">
                    <MKBox
                      component="img"
                      src={
                        "https://image.cnbcfm.com/api/v1/image/105783687-1552080443844laptopwhileworkingfromhome.jpg?v=1591701691&w=740&h=416&ffmt=webp&vtcrop=y"
                      }
                      alt="image"
                      maxWidth="24.75rem"
                      width="100%"
                      borderRadius="lg"
                      shadow="xl"
                      // mt={{ xs: 0, lg: -14 }}
                      // display={{ md: "none", lg: "block" }}
                    />
                  </Grid>
                )}
              </Grid>
            </MKBox>
          </MKBox>
        )}
      </Container>

      {/* <Testimonials /> */}
      {/* <Faq /> */}
      {/* <Contact id="contact" /> */}

      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{moreText.title}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{moreText.content}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default HomeUserEngagments;
