// @mui material components
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { cities, specificAreas, subCities, citiesOnly } from "Api-master-datas/masterDatas.js";
import queryString from "query-string";
import { toast } from "react-toastify";

import PropTypes from "prop-types";
import split from "just-split";
import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect, useRef } from "react";
import { HttpService } from "Api-Services/httpService";

import ReactGA from "react-ga4";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Avatar,
  Box,
  Button,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Card, TextField } from "@mui/material";
import {
  ArrowForwardIosSharp,
  Favorite,
  LockClockOutlined,
  Map,
  MoreVert,
  PlaceRounded,
  Receipt,
  Reply,
  Visibility,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices";
import { isMobile } from "react-device-detect";
import { red } from "@mui/material/colors";
import { DataManagementService } from "Api-Services/dataManagementService";
import JobShortInfoBox from "../Job/sections/JobShortInfoBox";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0.5px solid ${theme.palette.divider}`,
  borderBottom: "0.5px darkgreen solid",
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
}));

function JobVacanciesPreview(props) { 
  const navigateComp = useNavigate(); 
  const [currentData, updateContext] = useCurrentUser();
  const {
    employmentTypes, 
    employmentSalaryDealTypes, 
    jobs,
    agencyData, 
  } = currentData;

  const [agencyJobs, setAgencyJobs] = React.useState(null);
  const [jobsToChoose, setJobsToChoose] = React.useState(jobs);
  const [forceRenderer, setForceRenderer] = React.useState(false);
  
  const [forceRerender, setForceRerender] = React.useState(false);
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [jobOverview, setJobOverview] = React.useState({});
 
  const [jobsData, setJobsData] = React.useState([]);
  const viewDetails = (id, data) => {
    DataManagementService.setLocalstorageDataSecured(data, "jobDetail");
    let path = "/pages/jobs/job-details?id=" + id; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
    navigateComp(path);
  };

  useEffect(() => {
    // console.log("agencyData",agencyData)
    let filterdJobs = [];
    if (agencyData) {
      if (agencyData.jobsId) {
        let allagencyjobs = agencyData.jobsId.split(",");
        filterdJobs = jobs.filter((jobD) => {
          let res = false;
          allagencyjobs.forEach((element) => {
            element = parseInt(element);
            if (element) {
              if (element == jobD.id) {
                res = true;
              }
            }
          });
          return res;
        });
        setAgencyJobs(filterdJobs);
        setJobsToChoose(filterdJobs);
      }
    }
    setForceRenderer(!forceRenderer);
  }, [agencyData, jobs]);

  useEffect(() => {
    try {
      let JobListProp = props.jobVacancies;
      if (JobListProp) {
        if (JobListProp.length) {
          // ((!(jobVacancy.isJobClosed))&&((new Date(jobVacancy.deadLineDateTime).getTime())>(new Date().getTime())))

          let jobsDataDD = JobListProp.filter(
            (JV) =>
              !JV.isJobClosed && new Date(JV.deadLineDateTime).getTime() > new Date().getTime()
          );
          setJobsData(jobsDataDD);
        }
      }
    } catch (error) {}
  }, [props.jobVacancies]);
 
 
  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = {};
    // console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = row[returnField];
                res = rowField;
              }
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };
 
  const jobsLoading = (num) => {
    try {
      return (
        <>
          {/* {<Stack spacing={1}> 
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> 
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={60} />
            <Skeleton variant="rounded" width={210} height={60} />
          </Stack>
} */}

          <div style={{ marginBottom: "10px" }}>
            {[...Array(num ? num : 5)].map(() => (
              <div style={{ marginBottom: "10px" }}>
                <Accordion expanded={false}>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    {/* <MKBox component="section"> */}
                    {/* <Container> */}
                    <Grid container>
                      <Grid item xs={12} lg={10} sx={{ mt: 2 }}>
                        <Grid
                          container
                          spacing={{ xs: 0, lg: 0 }}
                          // sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}
                        >
                          <Grid item xs={12} lg={4} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="h6"
                              fontWeight="bold"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="rectangular" width={210} height={30} />
                            </MKTypography>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={250} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                          </Grid>

                          <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={150} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                            {/* <Autocomplete
                              // defaultValue="High School"
                              onChange={(e, val) => { 
                                  // setCurrentEducationLevelId(val.id);
                                handleSearchFieldsandValue("currentEducationLevelId",val?val.id:"Any","jobseeker")}
                              }  
                              options={[...[{title:"Any",id:"Any"}], ...educationLevels]}
                              defaultValue={getEducationLevelById(jobSeekerSearchFieldsandValue.currentEducationLevelId, "single")}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => <MKInput {...params} variant="standard" />}
                            /> */}

                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                          </Grid>

                          <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={150} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                            {/* <Autocomplete
                            onChange={(e, val) => {
                              // setSex(val);
                              handleSearchFieldsandValue("sex",val?val:"Any","jobseeker")

                            }}
                            value={jobSeekerSearchFieldsandValue.sex}
                            options={["Any", "male", "female"]}
                            getOptionLabel={(option) =>  option }
                            renderInput={(params) => <MKInput {...params} variant="standard" placeholder="select gender"/>}
                          /> */}
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                          </Grid>
                          <Grid item xs={12} lg={2} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={150} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                            {/* <Autocomplete
                            onChange={(e, val) => {
                              // setSex(val);
                              handleSearchFieldsandValue("sex",val?val:"Any","jobseeker")

                            }}
                            value={jobSeekerSearchFieldsandValue.sex}
                            options={["Any", "male", "female"]}
                            getOptionLabel={(option) =>  option }
                            renderInput={(params) => <MKInput {...params} variant="standard" placeholder="select gender"/>}
                          /> */}
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />{" "}
                            </MKTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* </Container> */}
                    {/* </MKBox> */}
                  </AccordionSummary>
                  <AccordionDetails></AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </>
      );
    } catch (error) {
      return <></>;
    }
  };

  return (
    <>
      {(jobsData ? (jobsData.length > 0 ? true : false) : false) && (
        <>
          {/* <Grid
                // sx={{ marginBottom: "-39px" }}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item>
                  <MKTypography variant={"h4"}>Recent Jobs</MKTypography>
                </Grid>
                <Grid item>
                  <Button component={Link} variant="text" to={"/pages/jobs"}>
                    See all
                  </Button>
                </Grid>
              </Grid> */}

          <Grid spacing={2} container direction="row" justifyContent="center" alignItems="center">
            {jobsData.map((jobVacancy) => {
              if (true) {
                return (
                  <Grid item xs={12} md={6} lg={4}>
                    <JobShortInfoBox jobVacancy={jobVacancy} />
                  </Grid>
                );
              }
            })}
          </Grid>
        </>
      )}

      {forceRenderer}

      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{parse(moreText.content)}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openedDialog == "jobOverview"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {jobOverview && (
          <>
            {jobOverview.title && <DialogTitle>{parse(jobOverview.title)}</DialogTitle>}
            {jobOverview.id && (
              <DialogContent>
                {/* <DialogContentText>{parse(jobOverview.content)}</DialogContentText>
                 */}

                <MKBox ml={4}>
                  <MKTypography variant="h6" color="text">
                    Overview:
                  </MKTypography>
                  <MKBox ml={1}>
                    <MKTypography variant="subtitle2" fontWeight="light" color="text">
                      Employment type :
                      <strong style={{ fontWeight: "430" }}>
                        {" "}
                        {getDataFromArray(
                          employmentTypes,
                          "id",
                          jobOverview.employmentTypeId,
                          "single",
                          "title"
                        )}
                      </strong>
                    </MKTypography>
                    <MKTypography variant="subtitle2" fontWeight="light" color="text">
                      Salary deal :{" "}
                      <strong style={{ fontWeight: "430" }}>
                        {getDataFromArray(
                          employmentSalaryDealTypes,
                          "id",
                          jobOverview.salaryDealTypeId,
                          "single",
                          "title"
                        )}
                      </strong>
                    </MKTypography>
                    <MKTypography variant="subtitle2" fontWeight="light" color="text">
                      Gender :
                      <strong style={{ fontWeight: "430" }}> {jobOverview.applicantGender}</strong>
                    </MKTypography>
                    <MKTypography variant="subtitle2" fontWeight="light" color="text">
                      Experiance :{" "}
                      <strong style={{ fontWeight: "430" }}>
                        {(jobOverview.minimumExperianceInYear != null
                          ? "starting from " + jobOverview.minimumExperianceInYear + " "
                          : "") +
                          (jobOverview.maximumExperianceInYear > jobOverview.minimumExperianceInYear
                            ? " to " + jobOverview.maximumExperianceInYear
                            : "") +
                          " year(s) "}
                      </strong>
                    </MKTypography>
                  </MKBox>
                </MKBox>
                <MKBox ml={4} mt={2}>
                  <MKTypography variant="h6" color="text">
                    Job Description:
                  </MKTypography>
                  <MKBox ml={1}>
                    <MKTypography variant="subtitle2" fontWeight="light" color="text">
                      {parse(jobOverview.description)}
                    </MKTypography>
                  </MKBox>
                  <Button
                    size="small"
                    // variant={Link}
                    // disabled={loading || !professionTitle}
                    // color="success"
                    onClick={() => {
                      viewDetails(jobOverview.id, jobOverview);
                    }}
                  >
                    Read More
                  </Button>
                </MKBox>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
      {forceRerender}
    </>
  );
}

export default JobVacanciesPreview;
