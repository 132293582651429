  
// Pages 
import SingleArticle from "pages/Support/HelpCenter/sections/SingleArticle";
import AskQuestion from "pages/Support/HelpCenter/sections/AskQuestion";
// import TotorDetails from "pages/Search/Totor/TotorDetails"; 
import JobSeekerDetails from "pages/Search/SearchEmployee/components/JobSeeker/jobSeekerDetails"; 
// import SendTutorRequest from "pages/Search/Totor/sendTutorRequest";
import SignInBasicPage from "layouts/authentication/sign-in/basic";
import SignUpCoverPage from "layouts/authentication/sign-up/cover";
import ResetPasswordPage from "layouts/authentication/reset-password/cover";
import MyProfile from "pages/LandingPages/Extreme/components/User/MyProfile";  
import SendHiringRequest from "pages/Search/SearchEmployee/components/Employee/SendHiringRequest";
import EmployeeDetails from "pages/Search/SearchEmployee/components/Employee/EmployeeDetails";
import JobDetails from "pages/Search/SearchJobs/Job/JobDetails";
 
import Contact from "pages/LandingPages/Extreme/sections/Contact";
import SearchEmployee from "pages/Search/SearchEmployee"; 
import SearchJob from "pages/Search/SearchJobs";  
import MyApplications from "pages/LandingPages/Extreme/components/User/MyApplications";
import PrivacyPolicy from "pages/LandingPages/Extreme/sections/privacyPolicy";
import AgencyProfile from "pages/Agencies/Agency/agencyProfile";
import SearchAgency from "pages/Agencies";
import AgencyRegistrationFeePayment from "pages/Agencies/components/PayAgency/registrationFeePayment";
import JoinAgency from "pages/Agencies/components/JoinAgency";

const customRoutes = [
  {
    name: "Article",
    route: "/pages/Article",
    component: <SingleArticle />,
  }, 
  
  {
    name: "Employee Details",
    route: "/pages/employee-details",
    component: <EmployeeDetails/>,
  },
   {
    // isAllowedForAgent: true,
    name: "Employees",
    route: "/pages/search",
    component: <SearchEmployee />,
  },

  {
    // isAllowedForAgent: true,
    name: "Jobs",
    route: "/pages/jobs",
    component: <SearchJob />,
  },
  {
    // isAllowedForAgent: true,
    name: "Jobs",
    route: "/pages/agencies",
    component: <SearchAgency />,
  },
  {
      // isAllowedForAgent: true,
      name: "contact us",
      route: "/pages/support/contact-us",
      component: <Contact />,
    },
  
  // {
  //   name: "Job Details",
  //   route: "/pages/Job-Details",
  //   component: <JobDetails/>,
  // },
  
  {
    name: "JobSeeker-Details",
    route: "/pages/JobSeeker-Details",
    component: <JobSeekerDetails/>,
  },
  {
    name: "my-applications",
    route: "/profile/my-applications",
    component: <MyApplications/>,
  },
  
  {
    isAllowedForAgent: true,
    name: "Job Details",
    route: "/pages/jobs/job-details",
    component: <JobDetails />,
  },
  {
    name: "Agents",
    route: "/agents",
    component: <SearchEmployee />,
  },
  
  {
    name: "Send Tutor Request",
    route: "/pages/SendTutorRequest",
    component: <SendHiringRequest/>,
  },
  {
    name: "Ask Question",
    route: "/pages/AskQuestion",
    component: <AskQuestion />,
  }, 
  {
    name: "Login",
    route: "/login",
    component: <SignInBasicPage />,
  },
  {
    name: "Register",
    route: "/register",
    component: <SignUpCoverPage />,
  },
  {
    name: "Privacy Policy",
    route: "/pages/privacy-policy", 
    component: <PrivacyPolicy/>,
  },
  {
    name: "Agency Profile",
    route: "/pages/agencies/agency", 
    component: <AgencyProfile/>,
  },
  {
    name: "Join Agency",
    route: "/pages/agencies/join-agency", 
    component: <JoinAgency/>,
  },
  {
    name: "Agency Registration Fee Payment",
    route: "/pages/agencies/Agency-Registration-Fee-Payment", 
    component: <AgencyRegistrationFeePayment/>,
  },
  {
    name: "reset password" ,
    route: "/reset-password",
    component: <ResetPasswordPage />,
  },
  {
    name: "reset password" ,
    route: "/profile",
    component: <MyProfile/>,
  },
  
];

export default customRoutes;
