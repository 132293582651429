/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
 
import parse from "html-react-parser";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React, { useState ,useEffect} from "react";

// Rental page components
import FaqCollapse from "pages/LandingPages/Rental/components/FaqCollapse";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { 
  Navigation, 
  Pagination,
} from "swiper";
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js"; 

import "./swiperStyles.css";

// import 'swiper/css/pagination';
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { isMobile } from "react-device-detect";
import MKAvatar from "components/MKAvatar";
import { Button, Icon, Tab, Tabs } from "@mui/material";
import { DataManagementService } from "Api-Services/dataManagementService";
// import 'swiper/modules/thumbs/thumbs.scss'; // Pagination module
// import 'swiper/modules/free-mode/free-mode.scss'; // Pagination module
// import 'swiper/modules/effect-cube/effect-cube.scss'; // Pagination module
// import 'swiper/modules/effect-flip/effect-flip.scss'; // Pagination module
// import 'swiper/modules/effect-cards/effect-cards.scss'; // Pagination module
// import 'swiper/modules/effect-creative/effect-creative.scss'; // Pagination module

const useStylesSwiper = makeStyles((theme) => ({
  swiperStyle: {
    "& .swiper": {
      width: "100%",
      paddingTop: "50px",
      paddingBottom: "50px",
    },
    "& .swiper-slide": {
      backgroundPosition: "center",
      backgroundSize: "cover",
      width: "300px",
      maxHeight: "300px",
    },
    "& .swiper-slide img": {
      display: "block",
      width: "100%",
    },
  },

  swiperPaginationNavigationStyle: {
    "& .swiper": {
      width: "100%",
      height: " 100%",
    },

    "& .swiper-slide": {
      textAlign: "center",
      fontSize: "18px",
      background: "#fff",

      /* Center slide text vertically */
      display: "-webkit-box",
      display: "-ms-flexbox",
      display: "-webkit-flex",
      display: "flex",
      // -webkit-box-pack: "center",
      // -ms-flex-pack: "center",
      // -webkit-justify-content: "center",
      // justify-content: "center",
      // -webkit-box-align: "center",
      // -ms-flex-align: "center",
      // -webkit-align-items: "center",
      // align-items: "center",
    },

    "& .swiper-slide img": {
      display: "block",
      width: " 100%",
      height: "100%",
      objectFit: "cover",
    },

    "& .swiper": {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  swiperEffectFlipStyle: {
    "& .swiper": {
      width: "100%",
      height: " 100%",
      // width: "300px",
      // maxHeight: "500px",
      padding: "50px",
    },
    "& .swiper-slide": {
      backgroundPosition: "center",
      backgroundSize: "cover",
      width: "300px",
      maxHeight: "500px",
    },
    "& .swiper-slide img": {
      display: "block",
      width: "100%",
    },
  },

  swiperEffectCubeStyle: {
    "& .swiper": {
      width: "100%",
      height: " 100%",
      // width: "300px",
      // maxHeight: "300px" ,
      // position: "absolute",
      // left: "50%",
      // top: "50%",
      // marginLeft: "-150px",
      // marginTop: "-150px",
    },
    "& .swiper-slide": {
      backgroundPosition: "center",
      backgroundSize: "cover",

      textAlign: "center",
      fontSize: "18px",
      // background: "#fff",

      /* Center slide text vertically */
      display: "-webkit-box",
      display: "-ms-flexbox",
      display: "-webkit-flex",
      display: "flex",
      // backgroundPosition: "center",
      // backgroundSize: "cover"
    },
    "& .swiper-slide img": {
      display: "block",
      width: "100%",
    },
  },

  swiperEffectCardsStyle: {
    "& .swiper": {
      width: "100%",
      height: " 100%",
      // "width": "240px",
      // "height": "320px"
    },
    "& .swiper-slide": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "18px",
      fontSize: "22px",
      fontWeight: "bold",
      // "color": "#fff"
    },
    "& .swiper-slide img": {
      display: "block",
      width: "100%",
    },
  },

  swiperEffectCreativeStyle: {
    "& .swiper": {
      width: "100%",
      height: " 100%",
      // "width": "320px" ,
      // "height": "240px",
      margin: "100px auto",
    },
    "& .swiper-slide": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "22px",
      fontWeight: "bold",
      color: "#fff",
    },
    "& .swiper-slide img": {
      display: "block",
      width: "100%",
    },
  },

  swiperEffectThumbsStyle: {
    "& .swiper": {
      width: "100%",
      height: "100%",
    },

    "& .swiper-slide ": {
      textAlign: "center",
      fontSize: "18px",
      background: "#fff",

      /* Center slide text vertically */
      display: "-webkit-box",
      display: "-ms-flexbox",
      display: "-webkit-flex",
      display: "flex",
      // -webkit-box-pack: "center",
      // -ms-flex-pack: "center",
      // -webkit-justify-content: "center",
      // justify-content: "center",
      // -webkit-box-align: "center",
      // -ms-flex-align: "center",
      // -webkit-align-items: "center",
      // align-items: "center"
    },

    "& .swiper-slide img": {
      display: "block",
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },

    "& .swiper": {
      width: "100%",
      height: "300px",
      marginLeft: "auto",
      marginRight: "auto",
    },

    "& .swiper-slide": {
      backgroundSize: "cover",
      backgroundPosition: "center",
    },

    "& .mySwiper2 ": {
      height: "80%",
      width: "100%",
    },

    "& .mySwiper": {
      height: "20%",
      boxSizing: "border-box",
      padding: "10px 0",
    },

    "& .mySwiper .swiper-slide": {
      width: "25%",
      height: "100%",
      opacity: "0.4",
    },

    "& .mySwiper .swiper-slide-thumb-active": {
      opacity: 1,
    },

    ".swiper-slide img": {
      display: "block",
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
}));

function NewJobSeekers() { 
  const swiperStyles = useStylesSwiper(); 
  const [currentData] = useCurrentUser();
  const { 
    agencyData, 
    newJobSeekers,agencyNewJobSeekers
  } = currentData;

  
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [openedDialog, setOpenedDialog] = React.useState(""); 

  const [selectedAgencyTabIndex, setSelectedAgencyTabIndex] = React.useState(0);


  useEffect(() => {
  //  console.log("newJobSeekers",newJobSeekers)
  } );
 
  
  const getAllSkills = (workExperiances) => {
    // return truncateString( JobSeeker.skillsOrSubjects,10 )}
    let res = "";
    try {
      if (workExperiances) {
        if (workExperiances.length) {
          let skills = "";
          workExperiances.forEach((element) => {
            skills = skills + (skills.length ? ", " : "") + element.skillsOrSubjects;
          });
          skills = skills.replace(/,/g, ", ");
          res = skills;
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };

  const truncateString = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          let btn = (
            <Button
              variant="text"
              onClick={() => {
                setMoreText({ content: str, title: lableName });
                setOpenedDialog("moreText");
                // setForceRerender(!forceRerender);
              }}
            >
              {"more"}
            </Button>
          );
          let txt = <MKTypography variant="body2">{str.slice(0, num) + "..."}</MKTypography>;
          res = (
            <>
              {txt} {btn}
            </>
          );
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };

  const truncateStringDefualt = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          let txt = <MKTypography variant="body2">{str.slice(0, num) + "..."}</MKTypography>;
          res = txt;
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };


  function AgencyChooseTab(){
    return (<>
   {agencyData&& <Container sx={{px:0,mx:0}}>
     <MKBox
      // sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tabs value={selectedAgencyTabIndex} onChange={(e,v)=>{setSelectedAgencyTabIndex(v)}} aria-label="basic tabs example">
          <Tab  label="All"  />
          <Tab label={agencyData.fullName}  /> 
        </Tabs>
      </MKBox></Container>}
    
    </>)
  }

  function JobSeekersList(props){
    let jobSeekers=props.jobSeekers

    return (<>
    { (jobSeekers?(jobSeekers.length ? true : false):false) && (
    <div className={swiperStyles.swiperPaginationNavigationStyle}>
                  <Swiper
                    // slidesPerView={isMobile ? 3 : 6}
                    spaceBetween={10}
                    loop={true}
                    // pagination={{
                    //   clickable: true,
                    // }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                    breakpoints={{
                      640: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                      768: {
                        slidesPerView: 5,
                        spaceBetween: 15,
                      },
                      1024: {
                        slidesPerView: 7,
                        spaceBetween: 30,
                      },
                    }}
                  >
                    {jobSeekers.map((JobSeeker) => {
                      if (JobSeeker.profileImageUrl) {
                        return (
                          <SwiperSlide style={{ width: "auto" }}>
                            {/* <Grid item xs={12} md={6} lg={4}> */}

                            <MKBox display="flex" flexDirection="column">
                              <MKBox position="relative" width="max-content">
                                <MKAvatar
                                  variant="rounded"
                                  size="xxl"
                                  src={JobSeeker.profileImageUrl || ""}
                                  alt={truncateString(JobSeeker.firstName, 10)}
                                  sx={{
                                    borderRadius: ({ borders: { borderRadius } }) =>
                                      borderRadius.xl,
                                    position: "relative",
                                    zIndex: 2,
                                  }}
                                />
                                <MKBox
                                  borderRadius="lg"
                                  shadow="md"
                                  width="100%"
                                  height="100%"
                                  position="absolute"
                                  left={0}
                                  top="3%"
                                  zIndex={1}
                                  sx={{
                                    backgroundImage: `url(${JobSeeker.profileImageUrl})`,
                                    transform: "scale(0.87)",
                                    filter: "blur(12px)",
                                    backgroundSize: "cover",
                                  }}
                                />
                              </MKBox>
                              <MKBox>
                                <MKTypography variant="h5">
                                  {truncateStringDefualt(JobSeeker.firstName, 10)}
                                </MKTypography>
                                <MKTypography variant="body2" color="text">
                                  {truncateString(getAllSkills(JobSeeker.allWorkExperiances), 20)}
                                </MKTypography>
                                <MKTypography variant="body2" color="text">
                                  <Button
                                    component={Link}
                                    onClick={()=>{
                                      DataManagementService.setLocalstorageDataSecured(JobSeeker, "jobSeekerDetail");}}
                                    to={"/pages/JobSeeker-Details?id=" + JobSeeker.id}
                                    variant="text"
                                  >
                                    {"more"}
                                  </Button>
                                </MKTypography>
                                {/* <Stack direction="row"  mt={3}>
    
                    </Stack> */}
                              </MKBox>
                            </MKBox>

                            {/* </Grid> */}
                          </SwiperSlide>
                        );
                      }
                    })}
                  </Swiper>
                </div>)}
    </>)

  }

  return (<>     
            <Container>
              <>
                <Grid container item flexDirection="column" xs={12}  mb={6}>
                </Grid>
                <Grid 
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start">
                  <Grid item  >
                    <MKTypography variant={"h4"}>New Employees</MKTypography> 
                  </Grid> 
                  <Grid item  ><Button  component={Link}   variant="text"
                   to="/pages/search"
                   >See all</Button>
                   
                  </Grid>
                </Grid> 
               {agencyData&&<AgencyChooseTab/>}
                {(selectedAgencyTabIndex==0)&&<JobSeekersList jobSeekers={newJobSeekers} />}
                {(selectedAgencyTabIndex!=0)&&<JobSeekersList jobSeekers={agencyNewJobSeekers} />}
              </>
            </Container>   
  </>);
}

export default NewJobSeekers;
