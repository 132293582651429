/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import profilePicture from "assets/images/bruce-mars.jpg";
import { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { Link } from "react-router-dom";

function Profile(props) {
  const [currentData] = useCurrentUser();
  const { currentUser,myAgencies } = currentData;
  const [agencyProfileData, setAgencyProfileData] =  useState({}); 
  
  useEffect(() => { 
    console.log(" location.search", location.search);
    if (props.agencyProfileData) { 
      setAgencyProfileData(props.agencyProfileData)
    }
  }, [props.agencyProfileData]);


  return (
    <MKBox component="section" pt={{ xs: 6, sm: 12 }}>
      <Container sx={{px:1}}>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -12, md: -20 }} textAlign="center">
            <MKAvatar src={agencyProfileData.logo} alt="Burce Mars" size="xl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" pt={1}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h5">{agencyProfileData.fullName}</MKTypography>
              </MKBox>
              <Grid container spacing={1} >
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                  {agencyProfileData.numberOfActiveJobs}&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    New Jobs
                  </MKTypography>
                </Grid>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                  {agencyProfileData.numberOfTotalJobs}&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Total Jobs
                  </MKTypography>
                </Grid>
                <Grid item>
                  <Link to={"/pages/search"}>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    {agencyProfileData.numberOfMembers+1624}&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="primary">
                    Members
                  </MKTypography>
                  
                  </Link>
                </Grid> 
                <Grid item>
                <MKButton component={Link}  variant="outlined" color="info" size="small" to={"/pages/agencies/join-agency?id="+agencyProfileData.id}>
                  Join
                </MKButton>
                 
                      
                </Grid>  
              </Grid>
              <Divider/>
              <MKTypography variant="body2" fontWeight="light" color="text">
               {agencyProfileData.description} <br />
                {/* <MKTypography
                  component="a"
                  href="#"
                  variant="body1"
                  fontWeight="light"
                  color="info"
                  mt={3}
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      transform: `translateX(3px)`,
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translateX(6px)`,
                    },
                  }}
                >
                  More about me <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography> */}
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
