
/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import queryString from "query-string";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isMobile } from 'react-device-detect';
import {ShareSocial} from 'react-share-social'
import ReactGA from "react-ga4";

import parse from 'html-react-parser';
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// Images
import profilePicture from "assets/images/bruce-mars.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import Card from "@mui/material/Card";
import React, { useEffect } from "react";
import { ArrowDropDownSharp, ContactMail, Drafts, Edit, Inbox, Send, Share, Work } from "@mui/icons-material";
import MKInput from "components/MKInput";
import { cities ,subCities,specificAreas} from "Api-master-datas/masterDatas.js";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { HttpService } from "Api-Services/httpService";
import { string } from "prop-types";
import MDEditor from "components/MDEditor";
import { DataManagementService } from "Api-Services/dataManagementService";
const ratings = {
  0.5: [
    <Icon key={1}>star_outline</Icon>,
    <Icon key={2}>star_outline</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  1: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star_outline</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  1.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star_half</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  2: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  2.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star_half</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  3: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  3.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star_half</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  4: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  4.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star_half</Icon>,
  ],
  5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star</Icon>,
  ],
};


function JobDetails(props) {
  const [currentData,updateContext] = useCurrentUser();
  const { employmentTypes,employmentJobLocationTypes,employmentSalaryDealTypes,currentUser ,myJobApplications,myJobSeekerInformation, educationLevels,agencyData,isAgentWebsite , jobs } = currentData;
  // const { currentUser, educationLevels, employmentJobVacancy, jobs } = currentData;
  const [expanded, setExpanded] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [employmentJobVacancy, setEmploymentJobVacancy] = React.useState(null);  
  // const [employmentAgencyAndJobSeeker, setEmploymentAgencyAndJobSeeker] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [forceRerender, setForceRerender] = React.useState(false);

  const navigateComp = useNavigate();
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({content:"",title:""});
  const [sendRequestData, setSendRequestData] = React.useState({
    jobSeekerId:"",
    employmentAgencyId:"",
    employerId:"",
    employmentJobVacancyId:"",
    requesterFullName:"",
    requesterEmail:"",
    requesterPhone:"", 
    requesterUserId:"",
    
    description:"",
    requesterCountry: "", 
    requesterCity: "", 
    requesterSubCity :"", 
    requesterSpecificArea: "", 
  });
  // user
  // {id: DataTypes.DOUBLE,
  // userFrontImage: DataTypes.TEXT,
  // userEmail: DataTypes.TEXT,
  // googleEmail: DataTypes.TEXT,
  // facebookId: DataTypes.TEXT,
  // userStatus: DataTypes.DOUBLE,

  // currentEducationLevel:DataTypes.DOUBLE ,
  // informationApproved:DataTypes.BOOLEAN ,
  // informationApprovedDate:DataTypes.TEXT ,
  // TotoringEducationLevelStart:DataTypes.DOUBLE ,
  // TotoringEducationLevelEnd:DataTypes.DOUBLE ,

  // isInstructor :DataTypes.BOOLEAN,
  // status :DataTypes.TEXT,
  // registeredDate :DataTypes.TEXT,

  useEffect(() => {
        // console.log("employmentSalaryDealTypes detail", employmentSalaryDealTypes);

    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch.id) {        
        let jobDetail= DataManagementService.getLocalstorageDataSecured("jobDetail");
        if(jobDetail){
          if(jobDetail.id){
            if(jobDetail.id==locationSearch.id){
              setEmploymentJobVacancy(jobDetail)
            }
          }
        }
        search(locationSearch.id);
      }
    }
    // search()
    if (currentUser.user) {
      setUser(currentUser.user);
    }
  }, [currentUser, location.pathname, location.search]);

  const search = async (id) => {
    // console.log(" vacancy. search", id);

    try {
      if (id) {
        let relativePath = "/api/employmentjobvacancy/find/" + id;
        // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
        let employmentJobVacancyRes = await getDocumentsAll("contactus", relativePath, {});
        // console.log("vacancy detail", employmentJobVacancyRes);

        if (employmentJobVacancyRes.success) {
          if (employmentJobVacancyRes.record) {
            if(employmentJobVacancyRes.record.id){
              incrementField(employmentJobVacancyRes.record.id,"numberOfViews")
            }
            setEmploymentJobVacancy(employmentJobVacancyRes.record);
            let jobTitle=employmentJobVacancyRes.record.title||"Unknown"
            ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
          }
        }
      }
      // setLoading(false);
    } catch (error) {}
  };


  const incrementField= async (id,fieldName,updateBy)=>{
      try {
        if (id&&fieldName) {
          let relativePath = "/api/employmentjobvacancy/incrementfieldbyid";
          let data={
            field:fieldName,
            id:id,
            updateBy:updateBy||1}
          // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
          let employmentJobVacancyRes = await postDocuments("contactus", relativePath, data);
          // console.log("vacancy incrementField", employmentJobVacancyRes);
   
        }
        // setLoading(false);
      } catch (error) {} 
  }

  const getDocumentsAll = async (documentName, relativePath, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    ////////// UPDATED/////////////
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;
    }
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

   

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    }

    // console.log(documentName, " responseD: ", responseD);
    // setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeExpanded2 = (panel) => (event, isExpanded) => {
    setExpanded2(isExpanded ? panel : false);
    if(!isExpanded){
      setExpanded(false);
    }
  };

  const navigate = useNavigate();
  const goToPage = () => {
    navigate("/pages/SendTutorRequest");
  };

  const truncateString=(str, num, lableName) =>{
    let res=str
    try {
      if (str) {
        if (str.length > num) { 
        let btn= <Button variant="text" onClick={()=>{setMoreText({content:str,title:lableName}); setOpenedDialog("moreText"); setForceRerender(!forceRerender) }} >{"View more"}</Button>
        let txt=str.slice(0, num) + "..."
        res= <>{parse(txt)} {btn}</>;
        } 
        else{
          
        res= <>{parse(res)}</>;
        } 
    } 
  }catch (error) {
      // console.log("errrr",error)
    }
    return res
  }
    
  
  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res =returnField?"": {}; 

    try {
      if (searchValue) {
      if (dataArray&&searchField&&searchValue) {
        if (dataArray.length>0) {
        let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = row[returnField];
                res = rowField;
              }
            }
           }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
      // console.log("filtered city  ", res);
    return res;
  };


  const isJobAlreadyApplied = (id) => {
    let res = false;
    
    if (myJobApplications&&id) { 
      if (myJobApplications.length) { 
        for(let i=0;i<myJobApplications.length;i++){
            if(myJobApplications[i].employmentJobVacancyId==id){
                        return true;
              }
        } 
      }
    }
    return res;
  };
 

  const handleSendRequestData=(field,value)=>{
    let data=sendRequestData
    if(field&&value){
      data[field]=value
    }
    setSendRequestData(data)
    setForceRerender(!forceRerender)
  }
  
  const applyComponent=()=>{
    return (
      <Card sx={{ width: "100%" }}>
      <Grid container alignItems="center">
        <Grid item xs={12} lg={6}>
            
          <MKBox mt={2} px={4}  textAlign="center">
            {/* <MKTypography variant="h3" mb={1}>
              Job and availability Information
            </MKTypography> */}
{/*  
           <MKTypography variant="h6" mt={{ xs: 0, sm: 3 }}>
              salary
            </MKTypography> */}
            <MKTypography variant="h5" style={{color:"darkgreen"}}>
           { ((employmentJobVacancy.salary)? employmentJobVacancy.salary+" " :"" )
                 + 
                 ((employmentJobVacancy.salaryCurrency)?employmentJobVacancy.salaryCurrency+" ":"" ) }
              

              <MKBox component="small" style={{color:"darkgreen"}}>{((employmentJobVacancy.salaryDuration)?" ("+employmentJobVacancy.salaryDuration+") ":"" )}</MKBox>
            </MKTypography>
            
           {employmentJobVacancy.deadLineDateTime&&<MKTypography variant="h6" mt={{ xs: 0, sm: 3 }}>
              {"Deadline: " + parseDate(employmentJobVacancy.deadLineDateTime)}
            </MKTypography>}
        
         
          </MKBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MKBox mt={2} textAlign="center">
          {(currentUser?(currentUser.user?true:false):false)? <>
           {myJobSeekerInformation?<>
           <> 
           {(isJobAlreadyApplied(employmentJobVacancy.id))?<> 
            <MKButton  
             disabled={employmentJobVacancy.isJobClosed} 
             onClick={()=>{
              navigateComp("/profile/my-applications")

             }} variant="outlined" color="success" size="small" sx={{ m: 1 }}>
              Already Applied 
            </MKButton>
            <MKButton  endIcon={<ContactMail/>}  
             disabled={employmentJobVacancy.isJobClosed} 
             onClick={()=>{ navigateComp("/pages/support/contact-us")}}
              variant="outlined" color="primary" size="small" sx={{ m: 1 }}>
              Contact Employer
            </MKButton></> :
             <MKButton  endIcon={<Send/>}   disabled={employmentJobVacancy.isJobClosed} onClick={()=>setOpenedDialog("sendRequest")} variant="gradient" color="success" size="large" sx={{ my: 2 }}>
             Apply
           </MKButton>}
           </>
            </>:
             <MKButton 
              //  disabled={employmentJobVacancy.isJobClosed} 
                 variant="gradient" color="success" size="large" sx={{ my: 2 }} 
              component={Link}
              // variant="outlined"
              // color="default"  
              to={ "/profile?tab=0" }
              // sx={{ color: ({ palette: { dark } }) => dark.main }}
            > 

             Build profile to Apply
           </MKButton>}
          </>:<>
          <MKButton 
              //  disabled={employmentJobVacancy.isJobClosed} 
                 variant="gradient" color="success" size="large" sx={{ my: 2 }} 
              component={Link}
              // variant="outlined"
              // color="default"  
              to={ "/login?redirectTo=/pages/jobs" }
              // sx={{ color: ({ palette: { dark } }) => dark.main }}
            > 

             Login and Apply
           </MKButton>
          </>}

            {(agencyData&&isAgentWebsite) &&<MKTypography
              display="block"
              variant="button"
              color="text"
              fontWeight="regular"
            >
              Brought to you by <strong> {agencyData.fullName}</strong>
            </MKTypography>}
            
           {employmentJobVacancy.isJobClosed&& <MKTypography
              display="block"
              variant="button"
              color="warning"
              fontWeight="regular"
            >
              <strong> {"Job Closed"} </strong>
            </MKTypography>}
          </MKBox>
        </Grid> 
      </Grid>
       
      </Card>
    )

  }

  const sendRequest= async()=>{
    let data=sendRequestData   
    
    try { 
      let relativePath ="/api/employmentapplicationrequest/new";
      if(employmentJobVacancy){
        if(employmentJobVacancy.id){
        data.employmentJobVacancyId=employmentJobVacancy.id
        data.employmentJobVacancyTitle=employmentJobVacancy.title
        data.employmentAgencyId=employmentJobVacancy.employmentAgencyId
        }
      }
      if(myJobSeekerInformation){
        if(myJobSeekerInformation.id){
        data.jobSeekerId=myJobSeekerInformation.id
        data.requesterFullName=myJobSeekerInformation.firstName+" "+ myJobSeekerInformation.lastName+" "+ myJobSeekerInformation.middleName 
        data.requesterEmail=myJobSeekerInformation.email 
        data.requesterPhone=myJobSeekerInformation.phone
        data.jobSeekerAndVacancyId=myJobSeekerInformation.id+"-"+employmentJobVacancy.id
        data.requesterCountry= myJobSeekerInformation.country  
        data.requesterCity=myJobSeekerInformation.phone
        data.requesterSubCity =myJobSeekerInformation.subCity
        data.requesterSpecificArea=myJobSeekerInformation.specificArea
        }
      }
      let label="Job Title: "+ employmentJobVacancy.title+" | "



      if(myJobSeekerInformation){
        if(myJobSeekerInformation.id){
        label=label+" Appilicant : " + myJobSeekerInformation.firstName +" "+ myJobSeekerInformation.lastName+" (ID : "+ myJobSeekerInformation.id+")"
          }
        }
 
        // console.log("data", data);

      if ((relativePath) && (data.jobSeekerId)) { 

        let toastId = toast.loading("Sending Request...",{
          position: toast.POSITION.BOTTOM_LEFT
        })

        let changeUserProfileRes = await postDocuments("changeUserInfo", relativePath, data);
         toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});

        if (changeUserProfileRes.success) {
          setOpenedDialog("")
          updateContext("myJobApplications")  
            ReactGA.event({
              category: "job Application",
              action: "job Application sent",
              label: label, // optional
              value: employmentJobVacancy.id, // optional, must be a number
              nonInteraction: true, // optional, true/false
              transport: "xhr", // optional, beacon/xhr/image
            });
          incrementField(data.employmentJobVacancyId,"numberOfApplications")  
            let path="/profile/my-applications"//+"&employmentAgencyId="+jobSeeker.employmentAgencyId
            navigateComp(path)
        }
        else{
              
          ReactGA.event({
            category: category,
            action: "job Application failed",
            label: label, // optional
            value: employmentJobVacancy.id, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        }
      }
 

    } catch (error) {
      // console.log("error", error);
    }
  };

  const updateUserInformation = async (dataD, dataParentName) => {
    // console.log("data", dataD);

    try {
      let id;
      let relativePath;
      if (dataParentName == "agencyjobseeker") {
        id = employmentJobVacancy.id;
        relativePath = "/api/employmentagencyjobseeker/edit";
      } else if (dataParentName == "jobseeker") {
        id = employmentJobVacancy.id;
        relativePath = "/api/employmentjobseeker/edit";
      }

      if (relativePath && id) {
        let data = {
          id: id,
        };
        data[dataD.fieldName] = dataD.value;
        // console.log("data", data);

        // let toastId = toast.loading("Updating Profile data...",{
        //   position: toast.POSITION.BOTTOM_LEFT
        // })

        let changeUserProfileRes = await postDocuments("changeUserInfo", relativePath, data);
        //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});

        if (changeUserProfileRes.success) {
          // setExpanded2(false)
          // updateContext("currentUser")
        }
      }
    } catch (error) {}
  };

  // const updateEmailInformation= async(action,dataD)=>{
  //   try {
  //    let data= null
  //    let relativePath=""
  //    if(action=="changeemail"){
  //        relativePath="/api/auth/changemyemail"
  //        data={"email":dataD.value}
  //    }
  //    else if(action=="verifyemail"){
  //        relativePath="/api/auth/verifymyemail"
  //       //  data={"pendingEmail":dataD.value}
  //        data={"emailVerificationCode":dataD.value}
  //    }
  //    else if(action=="dismisspendingemail"){
  //        relativePath="/api/auth/dismissmypendingemail"
  //        data={"email":dataD.value}
  //    }

  //   // console.log("data", data);
  //    if(data&&relativePath) {
  //         let toastId = toast.loading("taking action on email...",{
  //           position: toast.POSITION.BOTTOM_LEFT
  //         })

  //         let changeUserProfileRes= await postDocuments("emailaction",relativePath,data)
  //         toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});

  //         if(changeUserProfileRes.success){
  //           updateContext("currentUser")
  //           if(action=="changeemail"){
  //             setExpanded2("pendingEmail")
  //           }
  //         }
  //           }
  //   } catch (error) {

  //   }

  // }

  const editInputComponent = (
    parentFieldName,
    fieldName,
    fieldLabel,
    valueD,
    fieldType,
    options,
    optionsSelectedField,
    optionsSelectedLable
  ) => {
    // const editInputComponent = (fieldName, fieldLabel, valueD, fieldType, options, selectedField) => {
    // console.log("fieldName", valueD);
    let res = <></>;
    let inputComp = <></>;
    let value = valueD;
    try { 
      let accordionTitle = "-";
      if (fieldType == "fileUrl") {
        accordionTitle = (
          <Button
            href={value}
            target="_blank"
            onClick={() => {
              // setDocumentUrl(value);
              // setOpenedDialog("documentviewer")
            }}
            color="primary"
            round
            size="sm"
          >
            open file
          </Button>
        );
      } else if ((fieldType == "chip")||(fieldType == "chipText")) {
        
        let chipsData=[]//value
        if(value){
          if(typeof(value)=="string"){
            chipsData=value.split(",")
          }
          else if(value.length){
            chipsData=value 
          }

        }
        accordionTitle = (
          <>
          <>
            {chipsData.map((chipValue) => (
              <Chip
                label={optionsSelectedLable ? chipValue[optionsSelectedLable] : chipValue}
                variant="outlined"
              />
            ))}</>
          </>
        );
      } else {
        if (value) {
          accordionTitle = optionsSelectedLable
            ? value[optionsSelectedLable]
              ? value[optionsSelectedLable]
              : ""
            : value;
        }
      }

      if(typeof(accordionTitle)=="string"){
       accordionTitle= truncateString(accordionTitle,50)  
      }
      res = (
        <>
          {/* <Accordion expanded={expanded === fieldName} onChange={handleChange(fieldName)}> */}
          <Accordion expanded={false}>
            <AccordionSummary
              // expandIcon={<Edit />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
               {/* <MKTypography sx={{ width: "43%", flexShrink: 0 }} variant="body2" color="text" fontWeight="regular" pl={1}>
              {fieldLabel}  </MKTypography>
              <MKTypography sx={{ color: "text.secondary" }} variant="body2" color="text" fontWeight="regular" pl={1}>
              <strong>{accordionTitle}</strong> </MKTypography> */}

              <MKTypography sx={{ width: "43%", flexShrink: 0 }}  variant="body2" color="text" fontWeight="regular" pl={1}>{fieldLabel} {isMobile?" : ":""}</MKTypography>
              <MKTypography sx={{ color: "text.secondary" }} variant="body2" color="text" fontWeight="regular" pl={1}> <strong> {accordionTitle}</strong></MKTypography>
            </AccordionSummary> 
          </Accordion>
        </>
      );
    } catch (error) {
      // console.log("inp comp", error);
    }
    return res;
  };

  
  const parseDate = (dt) => {
    let d = new Date(dt);
    let m = d.getMonth() + 1;
    let dateD = m + "/" + d.getDate() + "/" + d.getFullYear();
    return dateD;
  };

  return (<>
    <MKBox component="section" py={2}>
      {employmentJobVacancy && (
        <Container>
          <Grid container item xs={12} justifyContent="center" mx="auto">
            {employmentJobVacancy && (
              <Grid container justifyContent="left" py={6}>
               {(agencyData?agencyData.logo:false)&& <Grid item xs={3}  md={3}>
                  {/* <MKBox textAlign="left">  */}
                    <MKBox
                      component="img"
                      // <Avatar 
                       src={
                        agencyData.logo 
                      }
                      // />
                      // alt={name}
                      width="50%"
                      borderRadius="md"
                      shadow="lg"
                    />
                    {/* {" "}
                  </MKBox> */}
                </Grid>}

                <Grid item  xs={agencyData?(agencyData.logo?9:12):12}>
                  {/* <MKBox justifyContent="space-between"   mb={1}> */}
                    <MKTypography variant="h3">
                      {employmentJobVacancy.title }
                    </MKTypography>
 
                  {/* </MKBox> */}
                </Grid>
                <Grid item  xs={12}>
                  <MKBox justifyContent="space-between" alignItems="center" mb={1}  pl={2}>
                
                  <MKTypography variant="body1" fontWeight="light" color="text">
                    {/* {truncateString(employmentJobVacancy.description,100)}  */}
                  {truncateString(employmentJobVacancy.description,isMobile?200:300,"Description")}
                  
                    {/* <MKTypography
                      component="a"
                      href="#"
                      variant="body1"
                      fontWeight="light"
                      color="info"
                      mt={3}
                      sx={{
                        width: "max-content",
                        display: "flex",
                        alignItems: "center",

                        "& .material-icons-round": {
                          transform: `translateX(3px)`,
                          transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                        },

                        "&:hover .material-icons-round, &:focus .material-icons-round": {
                          transform: `translateX(6px)`,
                        },
                      }}
                    >
                  {truncateString(employmentJobVacancy.description,isMobile?200:300,"Description")}</MKTypography> */}
                  </MKTypography>
                  </MKBox>

                  </Grid>
              </Grid>
            )}

            {/* <Grid container item xs={12}  justifyContent="center"> */}
             {applyComponent()}
            {/* </Grid> */}
             
            {employmentJobVacancy && (
              <Grid container item xs={12} py={2} justifyContent="center">
                <Card sx={{ width: "100%" }}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={12}> 
                      <MKBox py={3} px={2}>
                        {/* <MKTypography variant="h3" mb={1}>
                          Personal Informations
                        </MKTypography> */}

                      <MKTypography sx={{ color: "text.secondary" }} variant="h5" color="text" fontWeight="regular" pl={1}>  
                       <strong> Basic Information</strong>
                      </MKTypography>
                      </MKBox>
                      

                      <MKBox >
                        {editInputComponent(
                          "jobseeker",
                          "employmentTypeId",
                          "Employment Type", 
                          getDataFromArray(
                            employmentTypes,
                            "id",
                            employmentJobVacancy.employmentTypeId,
                            "single",
                            "title"
                          ) ,
                          "textField", 
                        )} 
                        
                        {editInputComponent(
                          "jobseeker",
                          "salaryDealTypeId",
                          "Salary Amount", 
                         
                            ((employmentJobVacancy.salary)? employmentJobVacancy.salary+" " :"" )
                          + " "+
                          ((employmentJobVacancy.salaryCurrency)?employmentJobVacancy.salaryCurrency+" ":"" )
                          +" "+
                          ((employmentJobVacancy.salaryDuration)?" ("+employmentJobVacancy.salaryDuration+") ":"" )
                          ,
                          "textField", 
                        )}
                        
                        {editInputComponent(
                          "jobseeker",
                          "salaryDealTypeId",
                          "Salary Deal", 
                          getDataFromArray(
                            employmentSalaryDealTypes,
                            "id",
                            employmentJobVacancy.salaryDealTypeId,
                            "single",
                            "title"
                          ) ,
                          "textField", 
                        )}

                        {editInputComponent(
                          "jobseeker",
                          "Deadline",
                          "Deadline",  
                           parseDate( employmentJobVacancy.deadLineDateTime) ,
                          "textField", 
                        )}
                        
                        <MKTypography mt={5} mb={2} mx={2}>
                         Required
                        </MKTypography>
                        {editInputComponent(
                          "jobseeker",
                          "applicantGender",
                          "Gender",
                          employmentJobVacancy.applicantGender ,
                          "textField"
                        )}  
                        {editInputComponent(
                          "jobseeker",
                          "requiredNumberOfEmployees",
                          "Employees  ",
                          employmentJobVacancy.requiredNumberOfEmployees+ " employee(s)",
                          "textField", 
                        )} 
                       

                       {  (((employmentJobVacancy.minimumExperianceInYear!=null)||(employmentJobVacancy.maximumExperianceInYear!=null))) && <>
                        {/* <MKTypography mt={5} mb={2} mx={2}>
                          Experiance
                        </MKTypography> */}
                          {editInputComponent(
                            "jobseeker",
                            "minimumExperianceInYear",
                            "Experiance",
                              ((employmentJobVacancy.minimumExperianceInYear!=null)?"starting from "+employmentJobVacancy.minimumExperianceInYear+" ":"" )
                              + 
                              ((employmentJobVacancy.maximumExperianceInYear>employmentJobVacancy.minimumExperianceInYear)?" to "+employmentJobVacancy.maximumExperianceInYear:"" )
                              +(" year(s) ")
                            
                            ,
                            "textField", 
                          )} 
                          </>}
                        <MKTypography mt={5} mb={2} mx={2}>
                          Location
                        </MKTypography>
                        
                        {editInputComponent(
                          "jobseeker",
                          "country",
                          "City",
                           employmentJobVacancy.city,
                          "textField"
                        )} 
                       { (employmentJobVacancy.city!=null)&&<>
                      {(employmentJobVacancy.city.toLowerCase()=="addis ababa")&&
                       <>
                        {editInputComponent(
                          "jobseeker",
                          "subCity",
                          "Specific Area",
                          employmentJobVacancy.subCity+" / "+ employmentJobVacancy.specificArea,
                          "textField"
                        )} </>}
                        {editInputComponent(
                          "jobseeker",
                          "jobLocationTypeId",
                          "Location Type", 
                          getDataFromArray(
                            employmentJobLocationTypes,
                            "id",
                            employmentJobVacancy.jobLocationTypeId,
                            "single",
                            "title"
                          ) ,
                          "textField", 
                        )}
                        </>}
                      </MKBox>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}


{employmentJobVacancy && (
              <Grid container item xs={12} py={2} justifyContent="center">
                <Card sx={{ width: "100%" }}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={12}>
                      <MKBox py={3} px={2}>
                        {/* <MKTypography variant="h3" mb={1}>
                          Personal Informations
                        </MKTypography> */}

                      <MKTypography sx={{ color: "text.secondary" }} variant="h3" color="text" fontWeight="regular" pl={1}>  
                       <strong> Job Description</strong>
                      </MKTypography>
                      </MKBox>
                      

                      <MKBox  px={4}>
                        
                      {(employmentJobVacancy.description )&&
                      <MKBox  mb={4}>
                      <MKTypography fontWeight="bold" mt={2}  >
                          Overview :
                        </MKTypography>
                        <MKBox  pl={2}> 
                        {   parse(employmentJobVacancy.description)  } 
                        </MKBox>
                        </MKBox>}

                        <Divider />
                        {(employmentJobVacancy.employeeResponsiblity )&&
                        <MKBox  mb={4}>
                        <MKTypography fontWeight="bold" mt={2}   >
                         Responsiblity :
                          </MKTypography>
                        <MKBox  pl={2}> 
                          {   parse(employmentJobVacancy.employeeResponsiblity ) }  
                        </MKBox>
                        </MKBox>}

                        {(employmentJobVacancy.requiredSkills )&&
                        <MKBox  mb={4}> 
                          <MKTypography fontWeight="bold" mt={2} >
                          Required  Skills :
                          </MKTypography>
                        <MKBox  pl={2}> 
                          {   parse(employmentJobVacancy.requiredSkills)  }  
                        </MKBox>
                        </MKBox>}

                        {(employmentJobVacancy.requiredExperiances )&&
                        <MKBox  mb={4}> 
                          <MKTypography fontWeight="bold" mt={2}   >
                          Required Experiances :
                          </MKTypography>
                        <MKBox  pl={2}> 
                          {   parse(employmentJobVacancy.requiredExperiances ) } 
                        </MKBox> 
                        </MKBox>}

                        <Divider />
                        {(employmentJobVacancy.allowances )&&
                        <MKBox  mb={4}> 
                          <MKTypography fontWeight="bold" mt={2}  >
                          Allowances:
                          </MKTypography>
                        <MKBox  pl={2}> 
                          {   parse(employmentJobVacancy.allowances )}  
                        </MKBox>
                        </MKBox>}

                        <Divider />
                        {(employmentJobVacancy.description )&&
                        <MKBox  mb={4}> 
                          <MKTypography fontWeight="bold" mt={2}   >
                          How To Apply:
                          </MKTypography>
                        <MKBox  pl={2}> 
                          {  parse( employmentJobVacancy.howToApply)  }  
                        </MKBox>
                        </MKBox>}
 
  
  



                      </MKBox>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}
             
             
            <Grid container item xs={12} pb={2}   justifyContent="center">
              {applyComponent()}
            </Grid>

            <Grid container item xs={12} pb={2}  justifyContent="center">
            <Card sx={{ width: "100%" }}>
                <Grid container alignItems="center">
                  
                  <Grid item xs={12} lg={12}>
                  <MKBox mt={2} textAlign="center">
                   {/* {enableShareButtons?  */}
                      <ShareSocial 
                        title={"Share this job vacancy"}
                        url ={window.location.href}
                        // socialTypes={['facebook','twitter','reddit','linkedin']}
                        onSocialButtonClicked={ () => {incrementField(employmentJobVacancy.id,"numberOfShares")}} 
                        socialTypes={['facebook','telegram','twitter','linkedin','whatsapp','instapaper']}
                      />   
                      {/* // : 
                      // <MKButton   endIcon={<Share/>}  disabled={employmentJobVacancy.isJobClosed} 
                      // onClick={()=>setEnableShareButtons(!enableShareButtons)} 
                      // variant="gradient" color="primary" size="large" sx={{ my: 2 }}>
                      //   Share
                      // </MKButton>   } */}
                    </MKBox> 
                  </Grid>
                </Grid>
                 
                </Card>
            </Grid>
           
          </Grid>
        </Container>
      )}
    </MKBox>

    <Dialog open={openedDialog=="moreText"} onClose={()=>{setOpenedDialog("")}}>
        {moreText&&<>
       {moreText.title&& <DialogTitle>{parse(moreText.title)}</DialogTitle>}
       {moreText.content&& <DialogContent>
          <DialogContentText>
           {parse(moreText.content)}
          </DialogContentText> 
        </DialogContent>}</>}
        <DialogActions>
          <Button onClick={()=>{setOpenedDialog("")}}>close</Button> 
        </DialogActions>
      </Dialog>
    
    <Dialog open={openedDialog=="sendRequest"} onClose={()=>{setOpenedDialog("")}}>
        <DialogTitle>  Job Application</DialogTitle>
        <DialogContent>


        <MKBox mt={2}> 

          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
 

          {employmentJobVacancy&&<List dense> 
                  <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <Avatar><Work/></Avatar>
                        </ListItemIcon>
                        <ListItemText
                         primary={truncateString(employmentJobVacancy.title)} 
                         secondary="Job title"
                         />
                      </ListItemButton>
                    </ListItem>  
                  {(myJobSeekerInformation)&&
                  <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <Avatar  src={
                        myJobSeekerInformation.profileImageUrl
                          ? myJobSeekerInformation.profileImageUrl
                          : profilePicture
                      }/>
                        </ListItemIcon>
                        <ListItemText primary={myJobSeekerInformation.firstName
                        ? myJobSeekerInformation.firstName + " " + myJobSeekerInformation.lastName
                        : "Job seeker"} 
                        secondary="Job seeker"/>
                      </ListItemButton>
                    </ListItem> }
                    {(agencyData&& isAgentWebsite )&&
                    <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <Avatar  src={
                          agencyData.logo
                            ? agencyData.logo
                            : profilePicture
                        }/>
                          </ListItemIcon>
                          <ListItemText primary={agencyData.fullName
                          ? agencyData.fullName  : "Employee Agency"} 
                          secondary="Agency"/>
                        </ListItemButton>
                    </ListItem> }
           </List>}

           <Divider />

          </MKBox>
           
         


       <MKBox mt={2}>
        <Grid container  >

        {/* <Grid item lg={12} md={12}>
            <MKBox mb={2}  lineHeight={0} >
              <MKTypography component="label" variant="button" fontWeight="regular" color="text">
               Your Full Name&nbsp;&nbsp;
                <MKTypography variant="caption" color="text">
                   required 
                </MKTypography> 
              </MKTypography> 
                
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label=" Full Name"
            type="text"
            fullWidth
            defaultValue={sendRequestData.requesterFullName}
            variant="outlined"
            onChange={(e)=>{  
              handleSendRequestData("requesterFullName",e.target.value)
            }}
          />
            </MKBox>
            </Grid> */}
        {/* <Grid item lg={12}  >
            <MKBox mb={2} ml={0.5} lineHeight={0}  >
              <MKTypography component="label" variant="button" fontWeight="regular" color="text">
              Your Phone&nbsp;&nbsp;
                <MKTypography variant="caption" color="text">
                   required 
                </MKTypography> 
              </MKTypography>
               
          <TextField
            autoFocus
            margin="dense"
            id="phone"
            label="  Phone"
            type="text"
            fullWidth
            variant="outlined"
            defaultValue={sendRequestData.requesterPhone}
            onChange={(e)=>{ 
              handleSendRequestData("requesterPhone",e.target.value)
            }}
          />
            </MKBox>
            </Grid> */}
       {/* { <Grid item lg={12}  >
            <MKBox mb={2} ml={0.5} lineHeight={0} >
              <MKTypography component="label" variant="button" fontWeight="regular" color="text">
              Your Email&nbsp;&nbsp;
                <MKTypography variant="caption" color="text">
                   required 
                </MKTypography> 
              </MKTypography>
               
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="  Email"
            type="email"
            fullWidth
            variant="outlined"
            defaultValue={sendRequestData.requesterEmail}
            onChange={(e)=>{ 
              handleSendRequestData("requesterEmail",e.target.value)
            }}
          /> 
            </MKBox>
            </Grid>} */}
          <Grid item lg={12} >
            <MKBox mb={2} ml={0.5} lineHeight={0}  >
              <MKTypography component="label" variant="button" fontWeight="regular" color="text">
                Short description about you&nbsp;&nbsp;
                <MKTypography variant="caption" color="text">
                   required 
                </MKTypography> 
              </MKTypography>
              <MDEditor value={sendRequestData.description}  
                //  onChange={setDescription}  
                  onChange={(e)=>{
                  if(e){
                    handleSendRequestData("description", e )
                  }
                }}
                />
            </MKBox>
            </Grid>
            </Grid>
            </MKBox>
         
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setOpenedDialog("")}}>Cancel</Button>
          {/* <Button  variant="contained" >Send</Button> */}
          <MKButton  onClick={()=>{sendRequest()}} variant="gradient" color="success" size="small" sx={{ my: 2 }}>
            Send Request
          </MKButton>
        </DialogActions>
      </Dialog>


  </>);
}

export default JobDetails;
