/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

import queryString from "query-string";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections 
import Posts from "pages/Blogs/Author/sections/Posts";
import Contact from "pages/Blogs/Author/sections/Contact";
import Footer from "pages/Blogs/Author/sections/Footer";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/city-profile.jpg";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { useEffect, useState } from "react";
import { HttpService } from "Api-Services/httpService";
import Profile from "./sections/Profile";
import { Container, Grid, Tab, Tabs } from "@mui/material";
import { ContactMailRounded, FeedRounded, PeopleOutlineRounded, WorkOutlineRounded } from "@mui/icons-material";
import JobVacanciesPreview from "pages/Search/SearchJobs/components/JobVacanciesPreview";
import { DataManagementService } from "Api-Services/dataManagementService";

function AgencyProfile() {
  
  const [currentData] = useCurrentUser();
  const { currentUser, educationLevels,agencyData,isAgentWebsite , jobs } = currentData;
  const [agencyProfileData, setAgencyProfileData] =  useState({}); 
  const [jobVacancies, setJobVacancies] =  useState([]); 
  const [forceRerender, setForceRerender] =  useState(true); 
  const [profileSectionIndex, setProfileSectionIndex] =  useState(0); 


  useEffect(() => {
    // updateContext("myJobApplications")
    // console.log("employmentAgencyJobVacancies", employmentAgencyJobVacancies);
    // console.log(" location.search", location.search);
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch) {
        if (locationSearch.id) { 
                  
        let agencyDetail= DataManagementService.getLocalstorageDataSecured("agencyDetail");
        if(agencyDetail){
          if(agencyDetail.id){
            if(agencyDetail.id==locationSearch.id){
              setAgencyProfileData(agencyDetail)
            }
          }
        }
          getAgencyProfileData(locationSearch.id);
           
        }
      }
    }
  }, [location.pathname, location.search]);

  
  const getAgencyProfileData = async (agentId) => {
       console.log( agentId, "agentId")

    let mydetails = null; 
    await getDocumentsAll(
      "myjobseekerinformation",
      "/api/employmentagency/getagentdataforwebsite/" + agentId
    ).then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setAgencyProfileData(mydetails); 
        getJobVacancies(mydetails.id)
      } else {
        setAgencyProfileData({}); 
      }
      //  console.log( mydetails, "getAgentDataForWebsite")
      // console.log( agencyWebsiteContentsData, "agency Website ContentsData   ")
    });
    return mydetails;
  };

  
  const getJobVacancies = (id) => {
    let mydetails;
    let agentId = id;
    if (!id) {
      if (agencyData) {
        if (agencyData.id) {
          agentId = agencyData.id;
        }
      }
    }
    let relativePath = "/api/employmentjobvacancy/getbyfield";
    // agentId

    try {
      // if (agentId) {
      let data = { employmentAgencyId: agentId };
      postDocuments("contactus", relativePath, data, {}).then((mydetailsRes) => {
        if (mydetailsRes.success) {
          mydetails = mydetailsRes.record;
          if (mydetails.length) {
            mydetails.reverse();
            setJobVacancies(mydetails);
          }
        } else {
          setJobVacancies([]);
        }
        // console.log(mydetails , "setJobVacancies  ")
      });
      // }
    } catch (error) {
      // console.log(error , "error  ")
    }
  };


  const getDocumentsAll = async (documentName, relativePath, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true; 
    }
    setForceRerender(!forceRerender);
    return responseD; 
  };

   

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false }; 
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true; 
    } 
    setForceRerender(!forceRerender);
    return responseD; 
  };


  return (
    <> 
     { agencyProfileData&&<MKBox >
        <MKBox
          minHeight="13rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.3),
                rgba(gradients.dark.state, 0.8)
              )}, url(${agencyProfileData.bannerImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Container sx={{px:1}}>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -5,
            mb: 2,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Profile agencyProfileData={agencyProfileData}/>



          {/* <Posts /> */}
        </Card>
        <Grid
              // sx={{ marginBottom: "-39px" }}
                 justifyContent="center" 
                 spacing={3}
            >
           <Grid item sm={12} py={2}>
                 
            <Tabs
              value={profileSectionIndex}
              onChange={(e,val)=>{ setProfileSectionIndex(val)}}
              aria-label="icon position tabs example"
            >
              <Tab icon={<WorkOutlineRounded />} iconPosition="start"  label="Jobs" />
              <Tab icon={<FeedRounded />} iconPosition="start" label="Blogs" />
              {/* <Tab icon={<PeopleOutlineRounded />} iconPosition="start" label="Members" /> */}
              <Tab icon={<ContactMailRounded />} iconPosition="start" label="Contact" />

            </Tabs>     
        </Grid>
        <Grid item  sm={12}>
      { (profileSectionIndex==0)&&<JobVacanciesPreview jobVacancies={jobVacancies} />  }
      { (profileSectionIndex==3)&&<Contact />  }
        </Grid>
        </Grid>
       </Container>
      </MKBox>}
      {forceRerender}
    </>
  );
}

export default AgencyProfile;
