import React, { useState, useEffect } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import parse from "html-react-parser";

import queryString from "query-string";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { HttpService } from "Api-Services/httpService";
import { Checkbox, CircularProgress, FormControlLabel } from "@mui/material";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { useNavigate } from "react-router-dom";
import { DataManagementService } from "Api-Services/dataManagementService";

function JoinAgency() {
  const [forceRerender, setForceRerender] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [invalidResponse, setInvalidResponse] = useState("");
  const [loginResponse, setLoginResponse] = useState(null);

  const [acceptInstruction, setAcceptInstruction] = useState(true);
  const [currentData, updateContext] = useCurrentUser();
  const { currentUser, myJobSeekerAndAgencies, myJobSeekerInformation } = currentData;
  const [joinUsPage, setJoinUsPage] = React.useState(null);
  const [currentAgency, setCurrentAgency] = React.useState({});
  const [myCurrentAgencyJobSeekerInformation, setMyCurrentAgencyJobSeekerInformation] =
    React.useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    // updateContext("myJobApplications")
    // console.log(" location.search", location.search);
    let agencyDetail = DataManagementService.getLocalstorageDataSecured("agencyDetail");
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch) {
        if (locationSearch.id) {
          if (agencyDetail) {
            if (agencyDetail.id) {
              if (agencyDetail.id == locationSearch.id) {
                // setAgencyProfileData(agencyDetail);
                setCurrentAgency(agencyDetail);
              }
            }
          }
          setWebsitePagesContentData(agencyDetail);
          getAgencyProfileData(locationSearch.id);
        }
      }
    }
  }, [location.pathname, location.search, myJobSeekerAndAgencies]);

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    setLoading(false);

    if (documentsResponse.success) {
      responseD.success = true;
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
      let record = responseD.record;

      //write codtions down here//

      if (documentName == "login") {
        localStorage.clear();
        setLoginResponse(documentsResponse);
        setLoading(true);
        // await getDocumentsAll("allOffices","/api/officeUserRole/myoffices",{})//init user offices and data
        setLoading(false);
        let path = "/";
        window.location.assign(path);
      }
    } else {
      responseD.message = documentsResponse.message;
      if (documentName == "login") {
        setLoginResponse(responseD);
      }
    }

    // console.log(documentName, " responseD: ", responseD   );
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const getDocumentsAll = async (documentName, relativePath, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    }
    setForceRerender(!forceRerender);
    return responseD;
  };

  const setWebsitePagesContentData = (agencyDetail) => {
    let myCurrentAgencyJobSeekerInformationD = null;
    let joinUsPageD = null;
    if (agencyDetail) {
      if (myJobSeekerAndAgencies) {
        if (myJobSeekerAndAgencies.length) {
          let myJobSeekerAndAgenciesD = myJobSeekerAndAgencies.filter(
            (level) => level.employmentAgencyId == agencyDetail.id
          );
          if (myJobSeekerAndAgenciesD.length) {
            myCurrentAgencyJobSeekerInformationD = myJobSeekerAndAgenciesD[0];
          }
        }
      }

      if (agencyDetail.agencyWebsiteContents) {
        let websitePagesContent = agencyDetail.agencyWebsiteContents;
        websitePagesContent = JSON.parse(websitePagesContent);
        if (websitePagesContent) {
          if (websitePagesContent.pages) {
            if (websitePagesContent.pages.joinUsPage) {
              if (websitePagesContent.pages.joinUsPage.sections) {
                joinUsPageD = websitePagesContent.pages.joinUsPage.sections;
              }
            }
          }
        }
      }
      // console.log("myCurrentAgencyJobSeekerInformationD", myCurrentAgencyJobSeekerInformationD);
      setMyCurrentAgencyJobSeekerInformation(myCurrentAgencyJobSeekerInformationD);
      setJoinUsPage(joinUsPageD);
      setForceRerender(!forceRerender);
    }
  };

  const getAgencyProfileData = async (agentId) => {
    // console.log(agentId, "agentId");

    let mydetails = null;
    await getDocumentsAll(
      "myjobseekerinformation",
      "/api/employmentagency/getagentdataforwebsite/" + agentId
    ).then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setCurrentAgency(mydetails);
      } else {
        setCurrentAgency({});
      }
      setWebsitePagesContentData(mydetails);
      setForceRerender(!forceRerender);
      //  console.log( mydetails, "getAgentDataForWebsite")
      // console.log( agencyWebsiteContentsData, "agency Website ContentsData   ")
    });
    return mydetails;
  };

  const addUserToWebsite = async () => {
    // console.log("currentUser",currentUser)
    if (!(currentUser ? (currentUser.user ? true : false) : false)) {
      return;
    }
    let data;
    let userId = currentUser.user.id || null;
    try {
      if (userId) {
        let relativePath = "/api/employmentagencyjobSeeker/addusertoemploymentagency";
        data = {
          userId: userId,
          // "userId" :myJobSeekerInformation.id ,
          jobSeekerId: myJobSeekerInformation.id,
          employmentAgencyId: currentAgency.id,
          isUserManaged: true,
          // jobsId: "1,23",
          isActive: false,
          // "currentEducationLevelId":  newSeekerCurrentEducationLevelId,
          // "workExperianceInMonth": newSeekerWorkExperianceInMonth,
          // "websiteUserTypeId":userTypeId,
          command: "new",
        };

        let toastId = toast.loading("updating website user...", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setLoading(true);
        let addUserToWebsiteRes = await postDocuments("addUserToWebsite", relativePath, data);
        setLoading(false);

        toast.update(toastId, {
          render: addUserToWebsiteRes.message,
          type: addUserToWebsiteRes.success ? "success" : "warn",
          isLoading: false,
          autoClose: 5000,
        });
        if (addUserToWebsiteRes.success) {
          // setAddNewUserButton(false)
          // setUserSearchResult(null)
          updateContext("myCurrentAgencyJobSeekerInformation");
          updateContext("myAgencies");
          updateContext("myJobSeekerAndAgencies");
          updateContext("employmentAgencyJobSeekers");
          updateContext("newJobSeekers");
          // "/profile"
          // navigate("/profile?tab=2");
          DataManagementService.setLocalstorageDataSecured(currentAgency, "agencyDetail");
          navigate("/pages/agencies/Agency-Registration-Fee-Payment?id=" + currentAgency.id);
        }
      }
    } catch (error) {
      // console.log("error",error)
    }
  };

  return (
    <Container sx={{ my: 9 }}>
      <MKBox>
        <MKTypography variant="h5" textAlign="center" mb={1}>
          {currentAgency ? currentAgency.brandName || currentAgency.fullName : ""}
          {" Instruction "}
        </MKTypography>
        <MKTypography variant="body2" color="danger" textAlign="center" mb={1}>
          {"Read all carefully by scrolling! "}
        </MKTypography>
        <MKBox component="form">
          <MKBox py={1} px={{ md: 4, lg: 4, xl: 6 }}>
            <Grid container spacing={1}>
              {joinUsPage && (
                <>
                  {joinUsPage.instruction && (
                    <Grid item xs={12} lg={12} justifyContent="center" flexDirection="column">
                      <MKBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        shadow="lg"
                        borderRadius="xl"
                        sx={{
                          backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                            rgba(white.main, 0.8),
                          backdropFilter: "saturate(200%) blur(30px)",
                          px: { xs: 3, sm: 6 },
                          py: { xs: 3, sm: 2 },
                          // mb: { xs: 0, lg: 8 },
                          mt: { xs: 0, lg: 1 },
                        }}
                      >
                        {joinUsPage.instruction.title && (
                          <MKTypography variant="h6" textAlign="center" mb={1}>
                            {joinUsPage.instruction.title}
                          </MKTypography>
                        )}
                        {joinUsPage.instruction.body && (
                          <MKBox style={{ overflow: "auto", maxHeight: "250px" }}>
                            {/* <MKBox  px={{ xs: 3, md: 12, lg: 6, xl: 12 }}> */}
                            <MKTypography variant="subtitle" color="text">
                              <small>{parse(joinUsPage.instruction.body)}</small>
                            </MKTypography>
                            {/* </MKBox> */}
                          </MKBox>
                        )}
                      </MKBox>
                    </Grid>
                  )}
                </>
              )}

              {!myCurrentAgencyJobSeekerInformation && (
                <Grid item xs={12} justifyContent="center" flexDirection="column">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={acceptInstruction}
                        onChange={(e) => {
                          setAcceptInstruction(e.target.checked);
                          console.log(e);
                        }}
                        defaultChecked
                      />
                    }
                    label="Accept the above instruction"
                  />
                </Grid>
              )}
              {(loginResponse || !isFormValid || loading) && (
                <Grid item xs={12}>
                  {(loginResponse || !isFormValid) && (
                    <MKBox mt={3} mb={1} textAlign="center">
                      {loginResponse && <small>{loginResponse.message}</small>}
                      {!isFormValid && <small>{invalidResponse}</small>}
                    </MKBox>
                  )}
                  {loading && (
                    <MKBox mt={3} mb={1} textAlign="center">
                      <CircularProgress size={24} />{" "}
                    </MKBox>
                  )}
                </Grid>
              )}
              {currentAgency && (
                <Grid item xs={12}>
                  {!myCurrentAgencyJobSeekerInformation ? (
                    <MKButton
                      variant="gradient"
                      color="success"
                      fullWidth
                      // disable={loading||(newSeekerJobs.length<1)}
                      disabled={loading || !acceptInstruction}
                      onClick={() => {
                        addUserToWebsite();
                      }}
                    >
                      Join
                    </MKButton>
                  ) : (
                    <>
                      {!myCurrentAgencyJobSeekerInformation.registrationFeePaid && (
                        <MKButton
                          variant="gradient"
                          color="success"
                          fullWidth
                          // disable={loading||(newSeekerJobs.length<1)}
                          disabled={loading || !acceptInstruction}
                          onClick={() => {
                            DataManagementService.setLocalstorageDataSecured(
                              currentAgency,
                              "agencyDetail"
                            );
                            navigate(
                              "/pages/agencies/Agency-Registration-Fee-Payment?id=" +
                                currentAgency.id
                            );
                          }}
                        >
                          Continue To Payment
                        </MKButton>
                      )}
                    </>
                  )}
                </Grid>
              )}
            </Grid>
          </MKBox>
        </MKBox>
      </MKBox>
      {forceRerender}
    </Container>
  );
}

export default JoinAgency;
