import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Avatar, Button, Card, Dialog, Divider, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Paper, Skeleton, SwipeableDrawer } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from 'Api-contexts/currentUserContext';
import { UseAuth } from 'Api-Services/useAuth';
import { ArrowBack, ArrowDropDownCircleOutlined, ArrowForwardIosRounded, CheckCircleRounded, ContactMail, ContactPhone, Delete, Details, Directions, Drafts, GroupWorkSharp, History, Inbox, Login, Logout, Mail, MenuRounded, Person, QuestionAnswer, RefreshRounded, Rule, SettingsApplications, WomanOutlined, Work, WorkOutlineOutlined } from '@mui/icons-material';
import { CommonServices } from 'Api-Services/commonServices';
 
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import { DataManagementService } from 'Api-Services/dataManagementService';
import { green, grey } from '@mui/material/colors';
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
import MKButton from 'components/MKButton';
  
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

 

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
 

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));



export default function MobileNavBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpened, setDrawerOpened] = React.useState(false);
  const [drawer2Opened, setDrawer2Opened] = React.useState(false);
  const [forceRerender, setForceRerender] = React.useState(false);
  const [jobSearchHistories, setJobSearchHistories] = React.useState(["mesfin","tutor"]);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const [searchKeyword, setSearchKeyword] = React.useState("");

  const [currentData] = useCurrentUser();
  const { currentUser,isUserLoggedIn,myAgencies,myJobSeekerInformation,agencyData,isAgentWebsite } = currentData; 

  const { window } = props;
  // This is used only for the example

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
 

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
 
  

  const truncateStringDefualt=(str, num, lableName) =>{
    let res=str
    try {
      if (str) {
        if (str.length > num) { 
        let txt=str.slice(0, num) + "..."
        res= <>{txt} </>;
        }  
    } 
  }catch (error) {
      // console.log("errrr",error)
    }
    return res
  }

  React.useEffect(() => {
    let historiesD=[]
    
   let jobSearchHistoriesLocalD= DataManagementService.getLocalstorageDataSecured("jobSearchHistories")

    if (jobSearchHistoriesLocalD) {
      if (jobSearchHistoriesLocalD.length) {
      historiesD=  jobSearchHistoriesLocalD
      }
    }
    
    setJobSearchHistories(historiesD)
  }, [currentUser]);

  
  const searchByKeyword = async (searchKeywordData) => {
    console.log("searchByKeyword" ,searchKeywordData )
    let searchKeywordDataDD=searchKeywordData||searchKeyword
    try { 
      let pathSearch; 
       if(searchKeywordDataDD){
        // jobSearchData = jobSearchFieldsandValue;
        pathSearch = { keyword:searchKeywordDataDD };
        pathSearch = CommonServices.encryptLocalData(pathSearch);
        let path = "/pages/jobs?search=" + pathSearch; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
        updateHistory(searchKeywordDataDD,"add")
        setDrawerOpened(false)
        setSearchKeyword(searchKeywordDataDD)
        setForceRerender(!forceRerender)
        goToPage(path); 
        }  
        } catch (error) {  
          console.log("errr",error)
      }
  }; 

  const didIJoinedCurrentAgency = () => {
    let res=false
    try {
      if(agencyData&&myAgencies){
        // console.log("agencyData&&myAgencies",agencyData,myAgencies)
          if(myAgencies.length){
            myAgencies.forEach(element => {
              if(agencyData.id==element.id){
                res=true
              }
            });
          }
      }
    } catch (error) {
      
    }

    return res 
  };

  const goToPage = (page) => {
    setAnchorEl(null); 
    handleMobileMenuClose();
    navigate(page);
  };

  
  const updateHistory = (historyKeyWord,action) => { 
    let jobSearchHistoriesD=[]
    if(jobSearchHistories.length){
     jobSearchHistoriesD=jobSearchHistories
    } 
    let removElemIdx=jobSearchHistoriesD.indexOf(historyKeyWord)
    if(removElemIdx>-1){
       jobSearchHistoriesD.splice(removElemIdx, 1); // 2nd parameter means remove one item only
    }

    if(action=="add"){
      jobSearchHistoriesD.unshift(historyKeyWord)
    }

    //update
    setJobSearchHistories(jobSearchHistoriesD)
    setForceRerender(!forceRerender)
    DataManagementService.setLocalstorageDataSecured(
      jobSearchHistoriesD,
      "jobSearchHistories"
    );
  };


  const navigate = useNavigate();
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
    { (currentUser?(currentUser.user?true:false):false)? <>
      <MenuItem onClick={() => goToPage("/profile?tab=0")}>
                  
              <IconButton
                      size="large"
                      aria-label="show 17 new notifications"
                      color="inherit"  >
                      
                     <Person/>
                     </IconButton>{" "}
                  Profile Info
                </MenuItem>
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> 

                
                {/* <Divider /> */}
                {(myJobSeekerInformation==null)&&
                <MenuItem onClick={() =>{   goToPage("/profile")}}>
                  <Avatar
                    src={currentUser.user ? currentUser.user.userFrontImage : "/"}
                  />{" "}
                  Register as a job seeker
                </MenuItem>  }
              {(myJobSeekerInformation)&&
                 <>
                 {(isAgentWebsite&&agencyData)&& <>
                  { (!(didIJoinedCurrentAgency()))&& 
                  <MenuItem onClick={() =>{ goToPage("/pages/agencies/join-agency")}}>
                      <Avatar 
                        src={agencyData.logo?agencyData.logo : "/"}
                      />{" "}
                      {"Join "}{agencyData.brandName||agencyData.fullName}
                    </MenuItem>
                    }  
                  </>}
                </>}
                
                {(myJobSeekerInformation)&&
                <MenuItem onClick={() => goToPage("/profile/my-applications")}>
                  
                    <IconButton
                      size="large"
                      aria-label="show 17 new notifications"
                      color="inherit"  >
                     <SettingsApplications/>
                     </IconButton>
                  
                    {" "}
                  My Applications
                </MenuItem> }
                {(myJobSeekerInformation)&&
                <MenuItem onClick={() => goToPage("/profile?tab=1")}>
                   
                    <IconButton
                      size="large"
                      aria-label="show 17 new notifications"
                      color="inherit"  >
                      <Work/></IconButton>
                    {" "}
                  My Careers
                </MenuItem> }
                <MenuItem onClick={() => goToPage("/profile?tab=2")}>
                
                    <IconButton
                      size="large"
                      aria-label="show 17 new notifications"
                      color="inherit"  >
                      
                     <GroupWorkSharp/></IconButton>{" "}
                        Joined Agencies
                      </MenuItem>
                <Divider />
                <MenuItem onClick={() => UseAuth.logout() }>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
                </>:<>
                <Divider />
                <MenuItem onClick={() =>{UseAuth.goToAuthLoginPage()}}>
                  <ListItemIcon>
                    <Login fontSize="small" />
                  </ListItemIcon>
                  Login / Register
                </MenuItem>
                </>}



    </Menu>
  );
 
  const drawer = (
    <div>
     <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                  <Toolbar>
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2 }}
                      onClick={()=>{setDrawerOpened(false)}}
                    >
                      <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Menu
                    </Typography>
                    {/* <Button color="inherit">Login</Button> */}
                  </Toolbar>
                </AppBar>
              </Box>

 

<Grid  item xs={12}  mx="auto">
  
   <Grid >
   <Grid container item xs={12} p={1} justifyContent="center">
     <Card sx={{ width: "100%" }}>
       <Grid container alignItems="center">
         <Grid  p={1} item xs={12} lg={12}> 
           <MKBox pb={{sm: 0, md:1} } px={{sm: 2, md:3} } > 
               <List > 
             
                 {/* <Divider/> */} 
                <ListItem
                   secondaryAction={  <IconButton onClick={()=>{setDrawer2Opened("switchAgency")}} edge="end" aria-label="delete">
                   <ArrowDropDownCircleOutlined />
                 </IconButton>
                   }
                 > <ListItemAvatar sx={{mr:2}}>
                 <Avatar alt="Remy Sharp"  sx={{ width:70, height: 70 }} src={agencyData?agencyData.logo:""}/>
               </ListItemAvatar>
                   <ListItemText
                    secondary={ "see your agencies" }
                     primary =  {
                      <MKTypography variant="h6" mb={1}> {agencyData?(truncateStringDefualt((agencyData.brandName||agencyData.fullName||""),25)):"Baally Jobs"} 
                  </MKTypography>}
                   />
                 </ListItem>
                  
             </List>
           </MKBox>
         </Grid>
       </Grid>
     </Card>
     <Divider/> 
   </Grid> 
 
 <Divider />
      <List> 
          <ListItem  disablePadding  onClick={() =>{setDrawerOpened(false); goToPage("/pages/support/contact-us")}}>
            <ListItemButton>
              <ListItemIcon>
              <ContactMail />  
              </ListItemIcon>
              <ListItemText primary={"Contact Us"} />
              {/* /pages/support/contact-us */}
            </ListItemButton>
          </ListItem> 
          
          <ListItem disablePadding  onClick={() =>{setDrawerOpened(false); goToPage("/pages/company/about-us")}}>
            <ListItemButton>
              <ListItemIcon>
              <Work />  
              </ListItemIcon>
              <ListItemText primary={"About Us"} />
              {/* /pages/company/about-us */}
            </ListItemButton>
          </ListItem> 
          
          <ListItem disablePadding  onClick={() =>{setDrawerOpened(false); goToPage("/pages/AskQuestion")}}>
            <ListItemButton>
              <ListItemIcon>
              <QuestionAnswer />  
              </ListItemIcon>
              <ListItemText primary={"FAQ"} />
              {/* /pages/AskQuestion */}
            </ListItemButton>
          </ListItem> 
          <ListItem disablePadding  onClick={() =>{setDrawerOpened(false); goToPage("/pages/privacy-policy")}}>
            <ListItemButton>
              <ListItemIcon>
              <Rule />  
              </ListItemIcon>
              <ListItemText primary={"Privacy Policy"} />
              {/* /pages/AskQuestion */}
            </ListItemButton>
          </ListItem> 
      </List>

{(currentUser?(currentUser.user?true:false):false)&&<>
   <Grid container item xs={12} pt={3} justifyContent="center">
<MKButton  fullWidth  color='secondary'  onClick={() => UseAuth.logout() } startIcon={<Logout/>}>Log Out</MKButton>
</Grid>
</>}
   </Grid> 
 

</Grid>
 
 
    </div>
  );

  return (
    <Box sx={{ flexGrow: 1,mb:7 }}>


<HideOnScroll {...props}>

      <AppBar 
            color="white"
      //  position="fixed"
        >
        <Toolbar  variant="dense">
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton 
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              // onClick={handleMobileMenuOpen}
              onClick={()=>{setDrawerOpened("mobileMenu")}}
              color="inherit"
            >
            {/* <Avatar   sx={{ width: 40, height: 40 }} src={agencyData?agencyData.logo:""}/> */}
            <Badge
  overlap="circular"
  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
  badgeContent={
    //
    <Avatar  color="primary"  
    sx={{ width: 24, height: 24,    marginTop: "-13px",  }} >
       <MenuRounded alt="Remy Sharp"  color="primary"  />
      </Avatar>
  }
>
  <Avatar  sx={{ width: 40, height: 40 }} alt="Travis Howard"  
  src={agencyData?agencyData.logo:""}
   />
</Badge>



            </IconButton>
          </Box> 

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: {  md: 'flex' } }}>
            
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              // onClick={handleProfileMenuOpen}
              onClick={()=>{setDrawerOpened("search")}}
              color="inherit"
            >
              <SearchIcon />
            </IconButton>
          <IconButton 
              onClick={()=>{location.reload()}}size="large" aria-label="show 4 new mails" color="inherit">
                <RefreshRounded />
            </IconButton>
          
          </Box>  
        </Toolbar>
      </AppBar>
      </HideOnScroll>
      {renderMobileMenu}
      {renderMenu}


      <SwipeableDrawer  sx={{    zIndex: 1111110,
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "100%" ,backgroundColor: "#f0f2f5"},
        }}
          // swipeAreaWidth={100}
            anchor={"left"}
            open={drawerOpened=="mobileMenu"}
            onClose={()=>{setDrawerOpened(false)}}
            onOpen={()=>{setDrawerOpened("mobileMenu")}}
          >
            {drawer}
          </SwipeableDrawer>
          
      <Dialog sx={{    zIndex: 11111}}
        fullScreen
        open={drawerOpened=="search"}
        onClose={()=>{setDrawerOpened(false)}}
        TransitionComponent={Transition}
      >



<Box >
 

      <AppBar 
            color="white"  sx={{mt:3 , position: 'relative' }}
      //  position="fixed"
        >
        <Toolbar  variant="dense"  sx={{ p:0}}>
        <Box sx={{ display: { xs: 'flex'} }}>
            <IconButton 
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              // onClick={handleMobileMenuOpen}
              onClick={()=>{setDrawerOpened(false)}}
              color="inherit"
            >
            <ArrowBack  />
            </IconButton>
          </Box>
        
          <form>
<Paper  
          // component="form"
          sx={{  display: 'flex', alignItems: 'center', width:"100%" }}
        > 
          <InputBase fullWidth
          startAdornment={<SearchIcon sx={{mr:1}}/>}
          autoFocus={true}
            sx={{ ml: 1, pr: 1, flex: 1, backgroundColor: "aliceblue",  
             }}
            placeholder="Search job "
            value={searchKeyword} 
            
            onChange={(e)=>{
              setSearchKeyword(e.target.value) 
            }}
            onKeyDown={(event)=>{
              if (event.keyCode) {
                if (event.keyCode === 13) {
                  searchByKeyword()
                }
                }
             }}
            type='search'  
            inputProps={{ 'aria-label': 'search google maps', 
             }}
          />
          <Button  sx={{ p: '10px' }} 
            disabled={!searchKeyword}
            onClick={()=>{searchByKeyword()}} 
            // endIcon={<SearchIcon/>}
            >
              Search 
              
          </Button> 
             </Paper> 
       </form>
        </Toolbar>
      </AppBar> 

   <List  dense={true} sx={{pr:2,mt:3}}>
         {jobSearchHistories.map((jobSearchHistory)=> <ListItem disablePadding
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={()=>{updateHistory(jobSearchHistory,"remove")}}>
                      <Delete />
                    </IconButton>
                  }>
            <ListItemButton onClick={()=>{searchByKeyword(jobSearchHistory)}}>
              <ListItemIcon>
                <History />
              </ListItemIcon>
              <ListItemText primary={jobSearchHistory}/>
            </ListItemButton>
          </ListItem> )}
        </List> 
    </Box> 



      </Dialog>

      <SwipeableDrawer sx={{    zIndex: 1111111}}
        // container={container}
        anchor="bottom"
        open={drawer2Opened=="switchAgency"}
        onClose={()=>setDrawer2Opened(false)}
        // onOpen={()=>setDrawer2Opened("switchAgency")}
        swipeAreaWidth={150}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: true,
        }}
      >  
        <StyledBox
          sx={{
            position: 'absolute',
            top: -150,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Typography sx={{ p: 2, color: 'text.secondary' }}>51 results</Typography>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Skeleton variant="rectangular" height="100%" />
          



   <Grid container item xs={12} p={1} justifyContent="center">
     <Card sx={{ width: "100%" }}>
       <Grid container alignItems="center">
         <Grid  p={1} item xs={12} lg={12}>
           <MKBox py={1} px={4}>
             <MKTypography variant="h6" mb={1}>
              {"Switch Agencies"}
             </MKTypography> 

             </MKBox>
           <MKBox pb={{sm: 0, md:1} } px={{sm: 2, md:3} } > 
               <List > 
             
                 {/* <Divider/> */}
        
              {agencyData&&<><ListItem onClick={()=>{setDrawer2Opened(false)}} 
                   secondaryAction={ <IconButton edge="end" aria-label="delete">
                   <CheckCircleRounded />
                 </IconButton> 
                   }
                 > <ListItemAvatar>
                 <Avatar alt="Remy Sharp"  sx={{ width: 30, height: 30 }}    src={agencyData?agencyData.logo:""}/>
               </ListItemAvatar>
                   <ListItemText
                    // secondary={ "see your agencies" }
                     primary =  {agencyData.fullName} 
                   />
                 </ListItem><Divider/></> }
                 <ListItem onClick={()=>{
                       if(agencyData){
                            setDrawer2Opened(false)
                            DataManagementService.removeLocalstorageDataSecured("currentAgencyDomain")
                            location.reload()}
                          }
                        } 
                   secondaryAction={ agencyData?null:<IconButton edge="end" aria-label="delete">
                   <CheckCircleRounded />
                 </IconButton> 
                   }
                 > <ListItemAvatar>
                 <WorkOutlineOutlined   sx={{ width: 30, height: 30 }} />
               </ListItemAvatar>
                   <ListItemText
                    // secondary={ "see your agencies" }
                     primary =  {"Job.et"} 
                   />
                 </ListItem>
                 <Divider/> 
                 
       {(myAgencies?(myAgencies.length?true:false):false) && (<>
              { myAgencies.map((agency,i)=><>{(agencyData?((agency.id!=agencyData.id)?true:false):true)&& <>
                
                <ListItem  onClick={()=>{ 
                         UseAuth.switchAgency(agency.websiteDomain,"domain");
                }}
                 > <ListItemAvatar>
                 <Avatar alt="Remy Sharp"   sx={{ width: 30, height: 30 }}  src={agency?agency.logo:""}/>
               </ListItemAvatar>
                   <ListItemText
                    // secondary={ "see your agencies" }
                     primary =  {agency.fullName} 
                   />
                 </ListItem> <Divider/> </> }
                 
                 </>)}
                 </>)} 
                 
                <ListItem
                   secondaryAction={ <IconButton onClick={()=>{setDrawer2Opened(false)}} edge="end" aria-label="delete">
                   <ArrowForwardIosRounded />
                 </IconButton> 
                   }
                 > <ListItemAvatar>
                 <Avatar alt="Remy Sharp" >  <Work/></Avatar>
               </ListItemAvatar>
                   <ListItemText
                    // secondary={ "see your agencies" }
                     primary =  {"Join another agency"} 
                   />
                 </ListItem>
                  
             </List>
           </MKBox>
         </Grid>
       </Grid>
     </Card>

                 <Divider/> 
   </Grid> 






        </StyledBox>
      </SwipeableDrawer>


      {forceRerender}
    </Box>
  );
} 

MobileNavBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
