// @mui material components
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { cities, specificAreas, subCities, citiesOnly } from "Api-master-datas/masterDatas.js";
import queryString from "query-string";
import { toast } from "react-toastify";

import PropTypes from "prop-types";
import split from "just-split";
import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
// import MKDatePicker from "components/MKDatePicker";
import MKButton from "components/MKButton";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect, useRef } from "react";
import { HttpService } from "Api-Services/httpService";

import ReactGA from "react-ga4";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Avatar,
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Input,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  // Pagination,
  Paper,
  Select,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Card, TextField } from "@mui/material";
import { element } from "prop-types";
import {
  AddShoppingCart,
  Alarm,
  ArrowBack,
  ArrowForwardIosSharp,
  DateRangeOutlined,
  Delete,
  Directions,
  ExpandMore,
  ExpandMoreOutlined,
  Favorite,
  FavoriteBorder,
  Filter,
  LockClockOutlined,
  Map,
  MoreVert,
  PeopleOutlineRounded,
  Phone,
  PlaceRounded,
  Receipt,
  RecentActors,
  Reply,
  Search,
  Send,
  Share,
  TimeToLeaveOutlined,
  Update,
  Upload,
  Visibility,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices";
import { isMobile } from "react-device-detect";
import { red } from "@mui/material/colors";

import { makeStyles } from "@mui/styles";
import { Navigation, Pagination } from "swiper";
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

// import "./swiperStyles.css";

// import 'swiper/css/pagination';
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { DataManagementService } from "Api-Services/dataManagementService";
import AgencyShortInfoBox from "./agencyShortInfoBox";

const useStylesSwiper = makeStyles((theme) => ({
  swiperStyle: {
    "& .swiper": {
      // width: "100%",
      paddingTop: "5px !important",
      paddingBottom: "50px",
    },
    "& .swiper-slide": {
      backgroundPosition: "center",
      backgroundSize: "cover",
      // width: "300px",
      // maxHeight: "300px",
    },
    "& .swiper-slide img": {
      display: "block",
      width: "50%",
    },
  }, 
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0.5px solid ${theme.palette.divider}`,
  borderBottom: "0.5px darkgreen solid",
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, px: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function HomePopularAgenciesView() {
  // console.log("cities", cities);

  const swiperStyles = useStylesSwiper();
  // const [searchResult, setSearchResult] = React.useState([]);
  const [notSearchedYet, setNotSearchedYet] = React.useState(true);
  // const [forceRerender, setForceRerender] = React.useState(false);

  const navigateComp = useNavigate();
  // console.log("cities", cities);

  const [currentData, updateContext] = useCurrentUser();

  const { 
    popularEmploymentAgencies,
    jobs,
    agencyData, 
  } = currentData;
   
  const [forceRenderer, setForceRenderer] = React.useState(false);
  const [searchExpanded, setSearchExpanded] = React.useState(true); 
  const [loading, setLoading] = React.useState(false);
  const [searchResult, setSearchResult] = React.useState(null);
  const [expanded, setExpanded] = React.useState("");

  const [forceRerender, setForceRerender] = React.useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [searchedKeyword, setSearchedKeyword] = React.useState("");
  const [isFirstTimeSearch, setIsFirstTimeSearch] = React.useState(true);
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [jobOverview, setJobOverview] = React.useState({});
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const viewDetails = (id,data) => { 
    DataManagementService.setLocalstorageDataSecured(data, "agencyDetail");
    let path = "/pages/agencies/agency?id="+id //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
    navigateComp(path);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    // updateContext("myJobApplications")
    // console.log("employmentAgencyJobVacancies", employmentAgencyJobVacancies);
    // console.log(" myJobSeekerInformation", myJobSeekerInformation);
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch) {
        if (locationSearch.search) {
          searchByKeyword(locationSearch.search);
        }
      }
    }
  }, [location.pathname, location.search]);

  useEffect(() => { 
    if (location.pathname.indexOf("job") > -1) {
      ReactGA.send({ hitType: "pageview", page: location.pathname, title: "job search" });
    }
  }, []);

   
  
  const searchByKeyword = async (searchD) => {
    let pathSearch;
    let searchKeywordD = "";
    try {
      if (searchD) {
        let pathSearchData = CommonServices.decryptLocalData(searchD);
        // console.log("pathSearchData", pathSearchData);
        searchKeywordD = pathSearchData.keyword;
        setSearchKeyword(searchKeywordD);
        // jobSeekerSearchData = pathSearchData.jobSeekerSearch;
        // jobSearchData = pathSearchData.jobSearch;
      } else {
        searchKeywordD = searchKeyword;
        if (searchKeyword) {
          // jobSearchData = jobSearchFieldsandValue;
          pathSearch = { keyword: searchKeywordD };
          pathSearch = CommonServices.encryptLocalData(pathSearch);
          let path = "/pages/jobs?search=" + pathSearch; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
          navigateComp(path);
        }
      }

      let data = {
        keyword: searchKeywordD,
      };

      if (agencyData.id) {
        data.employmentAgencyId = agencyData.id;
      }

      try {
        setIsFirstTimeSearch(false);
        // let relativePath = "/api/employmentagencyjobseeker/search";
        let relativePath = "/api/employmentjobvacancy/getmyagencyjobvacanciesbykeyword";

        setLoading(true);

        // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
        let contactUsRes = await postDocuments("contactus", relativePath, data, {});
        setSearchedKeyword(searchKeywordD);
        setLoading(false);
        // console.log("contactUsRes",contactUsRes)
        // toast.update(toastId, { render: contactUsRes.message, type: contactUsRes.success?"success":"warn", isLoading: false , autoClose: 5000});
        // contactUsRes.professionTitle = professionTitle || "";
        // contactUsRes.employees = null;
        let searchResultD = [];
        if (contactUsRes.success) {
          if (contactUsRes.record) {
            if (contactUsRes.record.length) {
              searchResultD = contactUsRes.record;
              setSearchExpanded(false);
            }
          }
        }

        if (contactUsRes.success) {
          ReactGA.event({
            category: "job search",
            action: "job search found",
            label: searchKeywordD, // optional
            value: contactUsRes.record ? contactUsRes.record.length : 0, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        } else {
          ReactGA.event({
            category: "job search",
            action: "job search not found",
            label: searchKeywordD, // optional
            value: 0, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        }
        setSearchResult(searchResultD);
        // handleSearchResult(contactUsRes);
        if (contactUsRes.success) {
          // toast.success(contactUsRes.message, {
          //   position: toast.POSITION.TOP_CENTER,
          // });
        } else {
          // toast.error(contactUsRes.message, {
          //   position: toast.POSITION.TOP_LEFT,
          // });
        }
      } catch (error) {
        // setLoading(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
 
  
  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    }

    // console.log(documentName, " responseD: ", responseD);
    // setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const truncateString = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          let btn = lableName ? (
            <Button
              variant="text"
              onClick={() => {
                setMoreText({ content: str, title: lableName });
                setOpenedDialog("moreText");
                setForceRerender(!forceRerender);
              }}
            >
              {"View more"}
            </Button>
          ) : null;
          let txt = str.slice(0, num) + "...";
          res = (
            <>
              {parse(txt)} {btn}
            </>
          );
        } else {
          res = <>{parse(res)}</>;
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };

  function JobList(JobListProp) {
    const [jobsData, setJobsData] = React.useState([]); 
    const ref = useRef(null);

    useEffect(() => {
      try {
        // console.log("JobListProp",JobListProp)
        if (JobListProp) {
          if (JobListProp.agencies) {
            if (JobListProp.agencies.length) { 
              setJobsData(JobListProp.agencies);
            }
          }
        }
      } catch (error) {}
    }, [JobListProp.agencies]);

    try {
      return (
        <div ref={ref}>
          {(jobsData ? (jobsData.length > 0 ? true : false) : false) && (
            <Container>
              <Grid
                // sx={{ marginBottom: "-39px" }}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item>
                  <MKTypography variant={"h4"}>Popular Agencies</MKTypography>
                </Grid>
                <Grid item>
                  <Button component={Link} variant="text" to={"/pages/agencies"}>
                    See all
                  </Button>
                </Grid>
              </Grid>

              <div className={swiperStyles.swiperPaginationNavigationStyle}>
                <Swiper
                  slidesPerView={isMobile ? 1 : 3}
                  spaceBetween={10}
                  // loop={true}
                  // pagination={{
                  //   clickable: true,
                  // }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 15,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                  }}
                >
                  {jobsData.map((jobVacancy) => {
                    var numberOfLikes = jobVacancy.numberOfLikes || 0;
                    if (true) {
                      return (
                        <SwiperSlide style={{ width: "auto" }}>
                          {/* <Grid item xs={12} md={6} lg={4}> */}

                          <MKBox display="flex" flexDirection="column">
                          <AgencyShortInfoBox agency={jobVacancy} componentViewType={1}/>
                          </MKBox>

                          {/* </Grid> */}
                        </SwiperSlide>
                      );
                    }
                  })}
                </Swiper>
              </div>
            </Container>
          )}
        </div>
      );
    } catch (error) {
      // console.log(error, "error vjobsDataDD. ");
      return <></>;
    }
  }

  const jobsLoading = (num) => {
    try {
      return (
        <>
          {/* {<Stack spacing={1}> 
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> 
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={60} />
            <Skeleton variant="rounded" width={210} height={60} />
          </Stack>
} */}

          <div style={{ marginBottom: "10px" }}>
            {[...Array(num ? num : 5)].map(() => (
              <div style={{ marginBottom: "10px" }}>
                <Accordion expanded={false}>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    {/* <MKBox component="section"> */}
                    {/* <Container> */}
                    <Grid container>
                      <Grid item xs={12} lg={10} sx={{ mt: 2 }}>
                        <Grid
                          container
                          spacing={{ xs: 0, lg: 0 }}
                          // sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}
                        >
                          <Grid item xs={12} lg={4} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="h6"
                              fontWeight="bold"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="rectangular" width={210} height={30} />
                            </MKTypography>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={250} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                          </Grid>

                          <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={150} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                            {/* <Autocomplete
                              // defaultValue="High School"
                              onChange={(e, val) => { 
                                  // setCurrentEducationLevelId(val.id);
                                handleSearchFieldsandValue("currentEducationLevelId",val?val.id:"Any","jobseeker")}
                              }  
                              options={[...[{title:"Any",id:"Any"}], ...educationLevels]}
                              defaultValue={getEducationLevelById(jobSeekerSearchFieldsandValue.currentEducationLevelId, "single")}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => <MKInput {...params} variant="standard" />}
                            /> */}

                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                          </Grid>

                          <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={150} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                            {/* <Autocomplete
                            onChange={(e, val) => {
                              // setSex(val);
                              handleSearchFieldsandValue("sex",val?val:"Any","jobseeker")

                            }}
                            value={jobSeekerSearchFieldsandValue.sex}
                            options={["Any", "male", "female"]}
                            getOptionLabel={(option) =>  option }
                            renderInput={(params) => <MKInput {...params} variant="standard" placeholder="select gender"/>}
                          /> */}
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                          </Grid>
                          <Grid item xs={12} lg={2} sx={{ mt: 2 }}>
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={150} sx={{ fontSize: "1rem" }} />
                            </MKTypography>
                            {/* <Autocomplete
                            onChange={(e, val) => {
                              // setSex(val);
                              handleSearchFieldsandValue("sex",val?val:"Any","jobseeker")

                            }}
                            value={jobSeekerSearchFieldsandValue.sex}
                            options={["Any", "male", "female"]}
                            getOptionLabel={(option) =>  option }
                            renderInput={(params) => <MKInput {...params} variant="standard" placeholder="select gender"/>}
                          /> */}
                            <MKTypography
                              display="block"
                              variant="button"
                              fontWeight="regular"
                              // color="text"
                              mb={1}
                            >
                              <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />{" "}
                            </MKTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* </Container> */}
                    {/* </MKBox> */}
                  </AccordionSummary>
                  <AccordionDetails></AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </>
      );
    } catch (error) {
      return <></>;
    }
  };
 
  return (
    <> 
              <JobList agencies={popularEmploymentAgencies} />
           

            {/* </Card> */}

            {forceRenderer}
 
            <Dialog
              open={openedDialog == "moreText"}
              onClose={() => {
                setOpenedDialog("");
              }}
            >
              {moreText && (
                <>
                  {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
                  {moreText.content && (
                    <DialogContent>
                      <DialogContentText>{parse(moreText.content)}</DialogContentText>
                    </DialogContent>
                  )}
                </>
              )}
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenedDialog("");
                  }}
                >
                  close
                </Button>
              </DialogActions>
            </Dialog>
 
      {forceRerender}
    </>
  );
}

export default HomePopularAgenciesView;
